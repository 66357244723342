
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {


    profiles: [],

    profile: {
      username: '',
      password_view: '',
    },

  },

  getters,
  mutations,
  actions,
  modules: {},

}


