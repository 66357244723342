
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {

    feedbacks: [],
    
    feedback: {
      message: '',
      img: '',
      contact: '',
    },

    feedback_pop_up: false,

  },

  getters,
  mutations,
  actions,
  modules: {},

}


