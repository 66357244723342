<template>

    <div v-for="object in active" :key="object.id" class="bottom">
        <div class="name">{{ get_lang_name(lang, object.name) }}</div>
        <div class="result">
            <div v-for="result in object.results" :key="result.id" class="name">{{ get_lang_name(lang, result.alt_name) }}: <strong>{{ get_params(result.name) }}</strong></div>
        </div>
    </div>

</template>

<script>

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { format_number, get_lang_name } from "@/api/funcs";

export default {

    setup(){

        const store     = useStore()
        const game      = computed(() => store.getters.game)
        const lang      = computed(() => store.getters.lang)
        const active    = computed(() => game.value.tact ? objects.value.filter((o) => o.type == game.value.tact.type) : [])

        const objects       = ref([
            {   
                id: 1,
                type: 'Производство',
                name: 44,
                results: [
                    {id: 1, name: "Куплено сырья",      alt_name: 45,},
                    {id: 2, name: "Доступно сырья",     alt_name: 46,},
                    {id: 3, name: "Доступно средств",   alt_name: 47,},
                ],
                
            },

            {   
                id: 2,
                type: 'Продажи',
                name: 48,
                results: [
                    {id: 1, name: "Произведено",        alt_name: 49,}, //??? - game.value.team.product_ordinary_and_auto
                    {id: 2, name: "Доступно продукции", alt_name: 50,},
                    {id: 3, name: "Осталось средств",   alt_name: 51,},
                ],
                
            },

            {   
                id: 3,
                type: 'Кредит',
                name: 52,
                results: [
                    {id: 1, name: "Лимит кредитования", alt_name: 53,},
                    {id: 2, name: "Процентная ставка",  alt_name: 327,},
                    {id: 3, name: "Доступно",           alt_name: 55,},
                ],
                
            },
        ])



        function get_params(name){

            switch(name){

                //Производство
                case "Куплено сырья":
                    return format_number(game.value.team.program_materials_computed)

                case "Доступно сырья":
                    return format_number(game.value.team.materials)

                case "Доступно средств":
                    return format_number(game.value.team.money)

                //Продажи
                case "Произведено":
                    return format_number(game.value.team.prod_ordinary + game.value.team.prod_auto)
                
                case "Доступно продукции":
                    return format_number(game.value.team.products)

                case "Осталось средств":
                    return format_number(game.value.team.money)

                //Кредит
                case "Лимит кредитования":
                    return format_number(game.value.game.loan_limit)

                case "Процентная ставка":
                    return format_number(game.value.game.loan_interest) + "%"

                case "Доступно":
                    return format_number(game.value.game.loan_limit - game.value.team.credit)

                default:
                    return 0

            }

        }

        return {
            lang,
            active,
            get_lang_name,
            get_params,
        }
    }
}
</script>
