


export function date_format(str){

    const date = new Date(str)

    /*
    const months = {
        0: '01',
        1: '02',
        2: '03',
        3: '04',
        4: '05',
        5: '06',
        6: '07',
        7: '08',
        8: '09',
        9: '10',
        10: '11',
        11: '12',
    }
    */

    const times = {
        0: '00', 1: '01', 2: '02', 3: '03', 4: '04', 5: '05', 6: '06', 7: '07', 8: '08', 9: '09', 
        10: '10', 11: '11', 12: '12', 13: '13', 14: '14', 15: '15', 16: '16', 17: '17', 18: '18', 
        19: '19', 20: '20', 21: '21', 22: '22', 23: '23', 24: '24', 25: '25', 26: '26', 27: '27', 
        28: '28', 29: '29', 30: '30', 31: '31', 32: '32', 33: '33', 34: '34', 35: '35', 36: '36', 
        37: '37', 38: '38', 39: '39', 40: '40', 41: '41', 42: '42', 43: '43', 44: '44', 45: '45', 
        46: '46', 47: '47', 48: '48', 49: '49', 50: '50', 51: '51', 52: '52', 53: '53', 54: '54', 
        55: '55', 56: '56', 57: '57', 58: '58', 59: '59',
    }

    return times[date.getHours()] + ":" + times[date.getMinutes()]
    //return date.getDate() + "/" + months[date.getMonth()] + " " + times[date.getHours()] + ":" + times[date.getMinutes()]
}






//return [ err - bool, code - int, body - object ]
export async function requests(name, method, token = '', data = {}){

    let response = null
    
    if(method == `GET` || method == `DELETE`){

        if(token != ''){

            response = await fetch(name, {
                method: method,
                headers: { 'Authorization': token, },
            })

        }else{

            response = await fetch(name, {
                method: method,
            })

        }

    }

    if(method == `POST` || method == `PUT`){

        response = await fetch(name, {
            method: method,
            headers: {
                'Content-Type':'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(data)
        })

    }

    const body = await response.json()

    let status = false
    if(response.status > 300){
        status = true
    }

    return [ status, body.code == undefined ? body.error : body.code, body ]
}

export function IsJson(str) {

    try {

        const obj = JSON.parse(str)

        // More strict checking     
        if (obj && typeof obj !== "object") {
            return false
        }

    } catch (e) {
        return false
    }

    return true
}


export function trun(s, l) {
    if(s === undefined) return "";
    return s.length > l ? s.substring(0, l) + "..." : s.substring(0, l);
}


export function get_lang_name(lang, code){
    
    for (const [key, val] of Object.entries(lang)) {

        if(key.includes(code)){
            return val
        }
    }

    return ""
}

export function format_number(n) {

    const sign = n < 0 ? "-" : "";
    const formattedNumber = Math.abs(n)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return sign + formattedNumber;

}