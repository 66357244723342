<template>
    <div class="results">

        <div class="top">
            <div v-for="(tab, i) in tabs" :key="tab.id" :class="['tab', {'first': i == 0}, {'last': i == (tabs.length-1)}]"> 
                <div :class="['wrap', {'active': tab.active},]" @click="select_tab(tab)">
                    <div v-dompurify-html="tab.icon" class="icon"></div>
                    <div class="name">
                        {{get_lang_name(alang, tab.alt_name) }}
                    </div>
                </div>
            </div>
        </div>

        <div class="active-tab">
            {{ wrap_tab(active_tab) }}
        </div>

        <div class="bottom">

            <div class="left">
                <div class="wrap">

                    <div class="name"  @click="sort('Компания')">
                        {{ alang.trans_17 }}
                    </div>

                    <div>
                        <div v-for="history in historys" :key="history.id" :class="['desc', {'red': history.bankruptcy },]">
                            {{ history.name }}
                        </div>
                    </div>

                </div>
            </div>

            <div v-touch:swipe.left="left_cols" v-touch:swipe.right="right_cols" class="historys">

                <div v-for="(col, i) in cols_filter" :key="col.id" ref="listItem" :class="['wrap', `column_${i}`]">

                    <div class="wrap">
                        
                        <arrow-left v-if="i == 0 && pagination.start > 0" @click="left_cols" />

                        <div class="name" @click="sort(col.name)">
                            {{ get_lang_name(alang, col.alt_name) }}
                        </div>

                        <question v-if="isCompanyRating(col.name)" @click="toggle_pop_up" />

                        <div v-if="isCompanyRating(col.name) && col_pop_up" class="pop-up" @click="toggle_pop_up">
                            {{ alang.trans_373 }}<br> {{ alang.trans_374 }}
                        </div>

                        <arrow-right v-if="cols.length != pagination.stop && pagination.stop < cols.length && i == 0" @click="right_cols" />
      
                    </div>

                    <div>
                        <div v-for="history in historys" :key="history.id" :class="['desc', {'red': history.bankruptcy },]">
                            
                            {{ get_params(col.name, history) }}

                        </div>
                    </div>

                </div>

            </div>

        </div>

        <div class="wrap">
            <div class="pagination">
                <div v-for="col in cols" :key="col.id" :class="[`name`, { active: col.active }]"></div>
            </div>
        </div>

    </div>
</template>

<script>
//import { computed, onMounted, watch, ref } from 'vue'

import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex';

import { get_lang_name } from "@/api/funcs"

// пользовательская функция для сортировки записей в истории
import { sort_historys } from "./sortHistoryAnalitics"

// пользовательская функция для получения параметров из истории
import { get_params } from "./getHistoryParams"

// пользовательская функция для настройки пагинации.
import { usePagination } from './usePagination'


import ArrowLeft from './ArrowLeft.vue'
import ArrowRight from './ArrowRight.vue'
import Question from './Question.vue'




export default {

    components: {
        ArrowLeft,
        ArrowRight,
        Question,
    },

    props: {
        event: {
            type: Function,
            default: () => { }
        },
        w: {
            type: Object,
            default: () => { }
        },
    },


    setup(props) {

        const store = useStore();
        const alang = computed(() => store.getters.lang)

        const {
            cols,
            cols_filter,
            pagination,
            updateActiveCols,
            left_cols,
            right_cols,
            calculateVisibleColumns,
        } = usePagination();

        // массив для хранения объектов вкладок. 
        const tabs = ref([])

        // объект, представляющий активную вкладку. 
        const activeTab = ref({})

        // массив для хранения объектов данных для компонента истории. 
        const historys = ref([])

        // булево значение, указывающее, видно ли всплывающее окно столбца или нет. 
        const col_pop_up = ref(false)

        function toggle_pop_up() {
            col_pop_up.value = !col_pop_up.value;
        }

        async function update () {

            // Загружаем табы
            tabs.value = await props.event()

            if(tabs.value.length){

                // Обновляем историю вкладки
                const activeTab = tabs.value.find(tab => tab.active)
                if (activeTab) {
                    historys.value = activeTab.histories;
                    activeTab.value = activeTab;
                }


                // Устанавливаем колонки активной таблицы
                cols.value = activeTab.cols

                
                calculateVisibleColumns(); updateActiveCols()
            }
            

        }

        onMounted(async () => {
            await update();
        })

        // watch(
        //     () => props.w, 
        //     async () => {
        //         try {
        //             await update();
        //         } catch (error) {
        //             console.error(error);
        //         }
        //     }, 
        //     { immediate: true }
        // )

        function select_tab(tab) {
            
            tabs.value = tabs.value.map(t => ({
                ...t,
                active: t.name === tab.name
            }));


            activeTab.value = tab

            const { cols: activeCols, histories: activeHistories } = activeTab.value || {};

            // Устанавливаем колонки активной таблицы
            cols.value = activeCols || [];

            updateActiveCols(cols.value);

            historys.value = activeHistories || [];
        }

        // Сортировка
        function sort(name) {
            historys.value = sort_historys(name, historys.value);
        }

        function wrap_tab(tab){
            return get_lang_name(alang.value, tab.alt_name)
        }

        return {
            active_tab: computed(() => activeTab.value),
            isCompanyRating: (col_name) => col_name == 'Рейтинг компании',

            get_lang_name,
            alang,

            tabs,
            cols, cols_filter,

            historys,
            pagination,

            select_tab,
            sort,
            toggle_pop_up,
            
            left_cols, right_cols,
            col_pop_up,
            get_params,
            wrap_tab
        }
    }
}
</script>


<style lang="sass" scoped>
@import "@/assets/sass/results.sass"
</style>

