<template>
    <div class="block">

        <div class="top">

            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.01857 13.0906H1.45498C0.651577 13.0906 0 13.7422 0 14.5456V30.5455C0 31.3483 0.651577 31.9999 1.45498 31.9999H5.01857C5.82197 31.9999 6.47287 31.3483 6.47287 30.5455V14.5456C6.47287 13.7422 5.82197 13.0906 5.01857 13.0906Z" fill="currentColor"/>
                <path d="M13.5278 17.4536H9.96425C9.16016 17.4536 8.50928 18.1053 8.50928 18.9086V30.5444C8.50928 31.3486 9.16016 31.9994 9.96425 31.9994H13.5278C14.3312 31.9994 14.9821 31.3478 14.9821 30.5444V18.9086C14.9821 18.1053 14.3312 17.4536 13.5278 17.4536Z" fill="currentColor"/>
                <path d="M22.0358 17.4536H18.4722C17.6688 17.4536 17.0178 18.1053 17.0178 18.9086V30.5444C17.0178 31.3486 17.6688 31.9994 18.4722 31.9994H22.0358C22.8399 31.9994 23.4908 31.3478 23.4908 30.5444V18.9086C23.4908 18.1053 22.8399 17.4536 22.0358 17.4536Z" fill="currentColor"/>
                <path d="M30.545 13.0906H26.9815C26.178 13.0906 25.5271 13.7422 25.5271 14.5456V30.5455C25.5271 31.3491 26.178 31.9999 26.9815 31.9999H30.545C31.3484 31.9999 32.0001 31.3477 32.0001 30.5455V14.5456C32.0001 13.7422 31.3484 13.0906 30.545 13.0906Z" fill="currentColor"/>
                <path d="M16.4242 8.76221V11.3808C17.2167 11.3283 18.0521 10.9566 18.0521 10.0844C18.0521 9.18433 17.1364 8.92085 16.4242 8.76221Z" fill="currentColor"/>
                <path d="M14.1467 5.62863C14.1467 6.29041 14.639 6.67304 15.6309 6.87186V4.50317C14.7295 4.52973 14.1467 5.05943 14.1467 5.62863Z" fill="currentColor"/>
                <path d="M16 0C11.5888 0 8 3.58946 8 8.00001C8 12.4092 11.5888 15.9987 16 15.9987C20.4113 15.9987 24 12.4092 24 8.00001C24 3.58946 20.4113 0 16 0ZM16.4241 12.7313V13.5503C16.4241 13.7757 16.2513 14.0004 16.0251 14.0004C15.8018 14.0004 15.631 13.7757 15.631 13.5503V12.7313C13.3958 12.6768 12.2839 11.341 12.2839 10.2958C12.2839 9.76819 12.6032 9.46316 13.103 9.46316C14.5845 9.46316 13.4325 11.2885 15.631 11.3804V8.61618C13.6701 8.26009 12.4827 7.40019 12.4827 5.93227C12.4827 4.13413 13.9779 3.20681 15.631 3.15506V2.4497C15.631 2.22434 15.8018 1.99967 16.0251 1.99967C16.2513 1.99967 16.4241 2.22434 16.4241 2.4497V3.15506C17.455 3.1823 19.5718 3.82912 19.5718 5.12613C19.5718 5.64154 19.1865 5.9452 18.7364 5.9452C17.8757 5.9452 17.888 4.53108 16.4241 4.50384V7.01686C18.1698 7.38792 19.716 7.90333 19.716 9.94045C19.716 11.712 18.3939 12.6107 16.4241 12.7313Z" fill="currentColor"/>
            </svg>

            <div class="name">
                {{ lang.trans_258 }}
            </div>

        </div>

        <div class="center">

            <img :src="require(`@/assets/images/rules/${template.alt_name}/investment.svg`)">

            <div class="wrap">

                <div class="name">
                    {{ lang.trans_484 }}
                </div>

                <div class="wrap_v2">

                    <div class="name">
                        {{ lang.trans_485 }}
                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_486 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.constr_cost_ord) }}₽
                        </div>

                        <div class="name">
                            {{ lang.trans_487 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.constr_cost_auto) }}₽
                        </div>

                    </div>

                </div>

                <div class="wrap_v2">

                    <div class="name">
                        {{ lang.trans_488 }}
                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_482 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.money_liq_ord) }}₽
                        </div>

                        <div class="name">
                            {{ lang.trans_28 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.money_liq_auto) }}₽
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div class="bottom">
            {{ lang.trans_489 }}
        </div>

    </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'
import { format_number } from "@/api/funcs";

export default {

    setup(){

        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)
        const template  = computed(() => store.getters.template)

        return {
            close,
            lang,
            template,
            game,
            format_number,
        }
    }
}
</script>