<template>
    <div class="block">

        <div class="top">

            <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.6289 12.7866L22.9688 10.6655V4.30811C22.9688 3.4292 22.4238 2.64404 21.5977 2.3335L15.7383 0.13623C15.2637 -0.0454102 14.7363 -0.0454102 14.2559 0.13623L8.39648 2.3335C7.57031 2.64404 7.02539 3.4292 7.02539 4.30811V10.6655L1.36523 12.7866C0.544922 13.0972 0 13.8823 0 14.7612V21.2124C0 22.0093 0.451172 22.7417 1.16602 23.0991L7.02539 26.0288C7.61719 26.3276 8.32031 26.3276 8.91211 26.0288L15 22.9819L21.0879 26.0288C21.6797 26.3276 22.3828 26.3276 22.9746 26.0288L28.834 23.0991C29.5488 22.7417 30 22.0093 30 21.2124V14.7612C30 13.8823 29.4551 13.0972 28.6289 12.7866ZM20.9766 10.7124L15.9961 12.5815V8.58545L20.9766 6.41748V10.7124ZM9.02344 4.22607L15 1.98779L20.9766 4.22607V4.26123L15 6.68701L9.02344 4.26123V4.22607ZM13.9453 21.2827L8.96484 23.7729V19.1382L13.9453 16.8647V21.2827ZM13.9453 14.7202L7.96875 17.146L1.99219 14.7202V14.6851L7.96875 12.4468L13.9453 14.6851V14.7202ZM28.0078 21.2827L23.0273 23.7729V19.1382L28.0078 16.8647V21.2827ZM28.0078 14.7202L22.0312 17.146L16.0547 14.7202V14.6851L22.0312 12.4468L28.0078 14.6851V14.7202Z" fill="currentColor"/>
            </svg>

            <div class="name">
                {{ lang.trans_96 }}
            </div>

        </div>

        <div class="center">

            <img :src="require(`@/assets/images/rules/${template.alt_name}/procurement.svg`)">

            <ul>
                <li>
                    {{ lang.trans_462 }}
                </li>

                <li>
                    {{ lang.trans_463 }}
                </li>

                <li>
                    {{ lang.trans_464 }}
                </li>

                <li>
                    {{ lang.trans_465 }}
                </li>

                <li>
                    {{ lang.trans_466 }}
                </li>
            </ul>

        </div>

        <div class="bottom">
            {{ lang.trans_467 }}
        </div>

    </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

    setup(){

        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const template  = computed(() => store.getters.template)
        const game      = computed(() => store.getters.game)

        return {
            close,
            lang,
            template,
            game,
        }
    }
}
</script>