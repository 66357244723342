
import { requests } from "@/api/funcs";




//устанавливает выбраный шаг
export const set_selected_step = async ({ commit }, step) => {
    commit('set_selected_step', step); 
}

//устанавливает активный шаг
export const set_step = async ({ commit }, step) => {
    commit('set_step', step); 
}





export const step_finish = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/steps/games/${id}/finish`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}


export const update_steps = async ({ getters, dispatch }, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/steps/${object.id}`, 'PUT', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const add_steps = async ({ getters, dispatch }, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/steps`, 'POST', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const delete_steps = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/steps/${id}`, 'DELETE', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }
}

export const get_steps = async ({ commit, getters, dispatch }) => {
    
    const [ err, code, body ] =  await requests(`${getters.domain}/api/steps`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_steps', body)

}