<template>
    <div class="operator teams">

        <div class="top">

            <div class="name">
                {{ lang.trans_408 }}: {{ teams.length }}
            </div>

            <div class="name">
                {{ lang.trans_436 }}: {{ users_count() }}
            </div>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="flag ? close_all() : open_all()">
                <path d="M15 3H21V9" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 21H3V15" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21 3L14 10" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 21L10 14" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        </div>

        <teams></teams>

        <div :class="['btn', {active: !game_is_active()}]" @click="add_command">
            {{ lang.trans_437 }}
        </div>

        <teams-add v-if="team_add_pop_up" :close="team_add_pop_up_close"></teams-add>

    </div>
</template>

<script>

import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Teams from "@/components/operator/teams/Teams.vue";
import TeamsAdd from "@/components/operator/teams/TeamsAdd.vue";


export default {

    components: {
        'teams': Teams,
        'teams-add': TeamsAdd,
    },

    setup(){

        const route     = useRoute()
        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)
        const teams     = computed(() => store.getters.teams)

        onMounted(async () => {
            await store.dispatch('get_operator_teams', route.params.id)
        })


        function users_count(){

            let temp = 0


            for(let team of teams.value){

                if(team.users != undefined){
                    temp += team.users.length
                }
                
            }

            return temp

        }

        function add_command(){

            if(game.value.status == "Активна"){
                store.dispatch('update_err_by_code', 339); return
            }

            team_add_pop_up.value = true

        }

        function game_is_active(){

            if(game.value.status == 'Не активна'){
                return true
            }

            return false
        }


        const team_add_pop_up = ref(false)
        function team_add_pop_up_close(){
            team_add_pop_up.value = false
        }


        let flag = ref(false)
        function open_all(){
            for(let team of teams.value){
                team.is_open = true
            }

            flag.value = true
        }

        function close_all(){
            for(let team of teams.value){
                team.is_open = false
            }

            flag.value = false
        }



        return {
            teams,
            lang,
            users_count,
            team_add_pop_up,
            team_add_pop_up_close,
            game_is_active,
            add_command,
            flag,
            open_all,
            close_all,
        }

    }
}

</script>
