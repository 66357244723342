<template>
    <svg width="276" height="196" viewBox="0 0 276 196" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.1" d="M183.382 8.66683C115.136 -19.4808 33.2831 28.911 14.3374 68.8268C-31.1559 164.674 48.3455 208.907 83.3194 192.728C97.9199 185.973 109.846 174.439 121.373 163.216C138.679 146.51 161.61 136.883 185.656 136.227C204.175 135.801 222.605 140.77 241.071 139.311C259.537 137.852 275.849 112.385 275.348 93.8679C274.584 65.6056 256.565 38.8494 183.382 8.66683Z" fill="#367CFF"/>
        <path d="M230.374 6.15515H45.6288C39.5025 6.15515 34.5361 11.1215 34.5361 17.2478V162.632C34.5361 168.758 39.5025 173.724 45.6288 173.724H230.374C236.501 173.724 241.467 168.758 241.467 162.632V17.2478C241.467 11.1215 236.501 6.15515 230.374 6.15515Z" fill="white"/>
        <path d="M230.374 174.073H45.628C42.5948 174.069 39.6868 172.863 37.542 170.718C35.3973 168.573 34.1909 165.665 34.1875 162.632V17.2478C34.1909 14.2146 35.3973 11.3065 37.542 9.16169C39.6868 7.01684 42.5948 5.81035 45.628 5.80688H230.374C233.407 5.81035 236.315 7.01684 238.46 9.16169C240.605 11.3065 241.811 14.2146 241.814 17.2478V162.632C241.811 165.665 240.605 168.573 238.46 170.718C236.315 172.863 233.407 174.069 230.374 174.073ZM45.628 6.503C42.7794 6.50626 40.0483 7.63936 38.034 9.6537C36.0198 11.668 34.8868 14.3991 34.8836 17.2478V162.632C34.8868 165.48 36.0198 168.211 38.034 170.226C40.0483 172.24 42.7794 173.373 45.628 173.376H230.374C233.223 173.373 235.954 172.24 237.968 170.226C239.982 168.211 241.115 165.48 241.118 162.632V17.2478C241.115 14.3991 239.982 11.668 237.968 9.6537C235.954 7.63936 233.223 6.50626 230.374 6.503H45.628Z" fill="#292A2E"/>
        <path d="M241.466 17.2488V38.2832H34.5361V17.2488C34.5357 15.7918 34.8224 14.349 35.3798 13.0028C35.9371 11.6566 36.7543 10.4334 37.7845 9.40307C38.8147 8.37275 40.0378 7.5555 41.3839 6.998C42.7301 6.4405 44.1729 6.15369 45.6299 6.15393H230.374C233.316 6.15443 236.138 7.3236 238.218 9.40427C240.298 11.4849 241.467 14.3067 241.466 17.2488Z" fill="#0944AA"/>
        <path d="M241.84 38.6569H34.1631V17.2489C34.166 14.2084 35.375 11.2932 37.5248 9.14309C39.6746 6.99298 42.5896 5.7836 45.6301 5.78033H230.375C233.415 5.78409 236.329 6.99368 238.479 9.14372C240.628 11.2938 241.837 14.2087 241.84 17.2489V38.6569ZM34.9111 37.9096H241.093V17.2489C241.09 14.4068 239.96 11.682 237.951 9.67204C235.941 7.66212 233.217 6.5313 230.375 6.52764H45.6301C42.7878 6.53081 40.0628 7.66143 38.0531 9.6714C36.0434 11.6814 34.9132 14.4065 34.9104 17.2489L34.9111 37.9096Z" fill="#367CFF"/>
        <g opacity="0.08">
        <path d="M74.4384 53.1674H44.6475V82.4487H74.4384V53.1674Z" fill="#367CFF"/>
        <path d="M74.4384 90.5909H44.6475V119.872H74.4384V90.5909Z" fill="#367CFF"/>
        <path d="M74.4384 128.015H44.6475V157.296H74.4384V128.015Z" fill="#367CFF"/>
        <path d="M113.665 53.1674H83.874V82.4487H113.665V53.1674Z" fill="#367CFF"/>
        <path d="M113.665 90.5909H83.874V119.872H113.665V90.5909Z" fill="#367CFF"/>
        <path d="M152.497 53.1674H122.706V82.4487H152.497V53.1674Z" fill="#367CFF"/>
        <path d="M152.497 90.5909H122.706V119.872H152.497V90.5909Z" fill="#367CFF"/>
        <path d="M192.428 53.1674H162.637V82.4487H192.428V53.1674Z" fill="#367CFF"/>
        <path d="M192.428 90.5909H162.637V119.872H192.428V90.5909Z" fill="#367CFF"/>
        <path d="M113.665 128.015H83.874V157.296H113.665V128.015Z" fill="#367CFF"/>
        <path d="M152.497 128.015H122.706V157.296H152.497V128.015Z" fill="#367CFF"/>
        <path d="M231.355 53.1674H201.564V82.4487H231.355V53.1674Z" fill="#367CFF"/>
        <path d="M231.355 90.5909H201.564V119.872H231.355V90.5909Z" fill="#367CFF"/>
        </g>
        <path d="M118.801 95.9608C121.893 95.9608 124.401 93.4534 124.401 90.3604C124.401 87.2675 121.893 84.7601 118.801 84.7601C115.708 84.7601 113.2 87.2675 113.2 90.3604C113.2 93.4534 115.708 95.9608 118.801 95.9608Z" fill="#292A2E"/>
        <path d="M157.202 95.9608C160.295 95.9608 162.802 93.4534 162.802 90.3604C162.802 87.2675 160.295 84.7601 157.202 84.7601C154.109 84.7601 151.602 87.2675 151.602 90.3604C151.602 93.4534 154.109 95.9608 157.202 95.9608Z" fill="#292A2E"/>
        <path d="M145.51 105.849H130.493C130.394 105.849 130.299 105.809 130.229 105.739C130.159 105.669 130.119 105.574 130.119 105.475C130.119 105.376 130.159 105.281 130.229 105.211C130.299 105.141 130.394 105.101 130.493 105.101H145.51C145.609 105.101 145.704 105.141 145.774 105.211C145.844 105.281 145.884 105.376 145.884 105.475C145.884 105.574 145.844 105.669 145.774 105.739C145.704 105.809 145.609 105.849 145.51 105.849Z" fill="#262626"/>
        <path d="M113.023 106.38C115.782 106.38 118.019 105.203 118.019 103.751C118.019 102.299 115.782 101.122 113.023 101.122C110.264 101.122 108.027 102.299 108.027 103.751C108.027 105.203 110.264 106.38 113.023 106.38Z" fill="white"/>
        <path d="M162.978 106.38C165.737 106.38 167.974 105.203 167.974 103.751C167.974 102.299 165.737 101.122 162.978 101.122C160.219 101.122 157.982 102.299 157.982 103.751C157.982 105.203 160.219 106.38 162.978 106.38Z" fill="white"/>
        <g opacity="0.4">
        <path d="M113.023 106.38C115.782 106.38 118.019 105.203 118.019 103.751C118.019 102.299 115.782 101.122 113.023 101.122C110.264 101.122 108.027 102.299 108.027 103.751C108.027 105.203 110.264 106.38 113.023 106.38Z" fill="#367CFF"/>
        <path d="M162.978 106.38C165.737 106.38 167.974 105.203 167.974 103.751C167.974 102.299 165.737 101.122 162.978 101.122C160.219 101.122 157.982 102.299 157.982 103.751C157.982 105.203 160.219 106.38 162.978 106.38Z" fill="#367CFF"/>
        </g>
    </svg>
</template>