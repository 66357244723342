<template>

    <div v-if="game" class="form">

        <!-- Основное -->
        <div>

            <div class="name">{{ lang.trans_189 }} - {{ get_lang_status(game.status) }}</div>

            <input-text v-model:model="game.name" :name="lang.trans_190" :placeholder="lang.trans_190" :max="40" :event="update" :readonly="game_is_active()"></input-text>
            <input-text v-model:model="game.info" :name="lang.trans_191" :placeholder="lang.trans_191" :max="100" :event="update" :readonly="game_is_active()"></input-text>
            <input-bool v-model:model="game.is_chart" :name="lang.trans_451" :event="update"></input-bool>

        </div>

        <!-- Прочее -->
        <div>

            <div class="center">

                <div class="name">{{ lang.trans_432 }}</div>

                <div class="wrap commands count">

                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_779_10433)">
                        <path d="M17.5 21V19C17.5 17.9391 17.0786 16.9217 16.3284 16.1716C15.5783 15.4214 14.5609 15 13.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21" fill="#0944AA"/>
                        <path d="M17.5 21V19C17.5 17.9391 17.0786 16.9217 16.3284 16.1716C15.5783 15.4214 14.5609 15 13.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21H17.5Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.5 11C11.7091 11 13.5 9.20914 13.5 7C13.5 4.79086 11.7091 3 9.5 3C7.29086 3 5.5 4.79086 5.5 7C5.5 9.20914 7.29086 11 9.5 11Z" fill="#0944AA" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M23.5 20.9999V18.9999C23.4993 18.1136 23.2044 17.2527 22.6614 16.5522C22.1184 15.8517 21.3581 15.3515 20.5 15.1299" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5 3.12988C17.3604 3.35018 18.123 3.85058 18.6676 4.55219C19.2122 5.2538 19.5078 6.11671 19.5078 7.00488C19.5078 7.89305 19.2122 8.75596 18.6676 9.45757C18.123 10.1592 17.3604 10.6596 16.5 10.8799" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_779_10433">
                        <rect x="0.5" width="24" height="24" rx="4" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>

                    <div class="name">
                        {{ game.teams.length }}
                    </div>

                </div>

            </div>

            <div class="name">

                {{ lang.trans_321 }}

                <input-bool v-model:model="game.variant" name="" :event="update"></input-bool>

            </div>


            <div class="name">

                {{ lang.trans_150 }}

                <input-bool v-model:model="game.is_fast_credit" name="" :event="update"></input-bool>

            </div>

            <div class="name">

                {{ lang.trans_605001 }}

                <input-bool v-model:model="game.hidden_reiting" name="" :event="update"></input-bool>

            </div>


        </div>

        <!-- Стартовые условия -->
        <div>

            <div class="name">{{ lang.trans_192 }}</div>
            
            <input-int v-model:model="game.default_finance"     :name="lang.trans_21"    :placeholder="lang.trans_21" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.default_loan"     :name="lang.trans_22"       :placeholder="lang.trans_22" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.default_raw"     :name="lang.trans_24"        :placeholder="lang.trans_24" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.default_prod"    :name="lang.trans_196"       :placeholder="lang.trans_196" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.default_ordinary"     :name="lang.trans_76"   :placeholder="lang.trans_76" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.default_auto"     :name="lang.trans_77"       :placeholder="lang.trans_77" :max="100" :event="update" :readonly="game_is_active()"></input-int>

        </div>

        <!-- Параметры производства -->
        <div>

            <div class="name">{{ lang.trans_199 }}</div>

            <input-int v-model:model="game.capacity_ord"     :name="lang.trans_200"          :placeholder="lang.trans_200" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.capacity_auto"     :name="lang.trans_201"         :placeholder="lang.trans_201" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.variable_costs_ord"     :name="lang.trans_202"    :placeholder="lang.trans_202" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.variable_costs_auto"     :name="lang.trans_203"   :placeholder="lang.trans_203" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.raw_for_standart"     :name="lang.trans_268"      :placeholder="lang.trans_268" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.raw_for_premium"     :name="lang.trans_269"       :placeholder="lang.trans_269" :max="100" :event="update" :readonly="game_is_active()"></input-int>

        </div>

        <!-- Финансовые и иные параметры -->
        <div>

            <div class="name">{{ lang.trans_214 }}</div>

            <float-range v-model:model="game.loan_interest"  :name="lang.trans_215"      :placeholder="lang.trans_215" :max="100" :event="update" :readonly="game_is_active()"></float-range>

            <input-int v-model:model="game.loan_limit"    :name="lang.trans_159"         :placeholder="lang.trans_159" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.forecast_price"    :name="lang.trans_217"     :placeholder="lang.trans_217" :max="100" :event="update" :readonly="game_is_active()"></input-int>

        </div>

        <!-- Дополнительный продукт -->
        <div :class="[`opacity`, {active: game.variant}]">

            <div class="name">

                {{ lang.trans_321 }}

                <input-bool v-model:model="game.variant" name="" :event="update"></input-bool>

            </div>

            <input-int v-model:model="game.default_count_premium_products"    :name="lang.trans_219" :placeholder="lang.trans_219" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.premium_variable_costs_ord"    :name="lang.trans_220"     :placeholder="lang.trans_220" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.premium_variable_costs_auto"    :name="lang.trans_221"    :placeholder="lang.trans_221" :max="100" :event="update" :readonly="game_is_active()"></input-int>

        </div>

        <!-- Инвестиции и продажа фабрик -->
        <div>

            <div class="name">{{ lang.trans_204 }}</div>

            <input-int v-model:model="game.constr_cost_ord"     :name="lang.trans_205"   :placeholder="lang.trans_205" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.constr_cost_auto"     :name="lang.trans_206"  :placeholder="lang.trans_206" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.money_liq_ord"     :name="lang.trans_207"     :placeholder="lang.trans_207" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.money_liq_auto"     :name="lang.trans_208"    :placeholder="lang.trans_208" :max="100" :event="update" :readonly="game_is_active()"></input-int>

        </div>

        <!-- Экстренный кредит -->
        <div :class="[`opacity`, {active: game.is_fast_credit}]">
            <div class="name">

                {{ lang.trans_150 }}

                <input-bool v-model:model="game.is_fast_credit" name="" :event="update"></input-bool>

            </div>

            
            <float-range v-model:model="game.fast_credit_interest"  :name="lang.trans_54"    :placeholder="lang.trans_54" :max="100" :event="update" :readonly="game_is_active()"></float-range>
            <input-int v-model:model="game.fast_credit"    :name="lang.trans_223"            :placeholder="lang.trans_223" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.fast_credit_limit"    :name="lang.trans_225"      :placeholder="lang.trans_225" :max="100" :event="update" :readonly="game_is_active()"></input-int>

            <!--

                <div class="wrap">
                    <div class="name">Начисленный процент</div>
                    <input v-model.trim="game.fast_credit_accrued_interest" type='text' />
                </div>

            -->

        </div>

        <!-- Издержки -->
        <div>

            <div class="name">{{ lang.trans_209 }}</div>

            <input-int v-model:model="game.fixed_costs_ord"     :name="lang.trans_210"       :placeholder="lang.trans_210" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.fixed_costs_auto"     :name="lang.trans_211"      :placeholder="lang.trans_211" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.warehouse_costs_raw"     :name="lang.trans_212"   :placeholder="lang.trans_212" :max="100" :event="update" :readonly="game_is_active()"></input-int>
            <input-int v-model:model="game.warehouse_costs_prod"     :name="lang.trans_213"  :placeholder="lang.trans_213" :max="100" :event="update" :readonly="game_is_active()"></input-int>

        </div>

    </div>

</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

import InputText  from '@/components/form/InputText.vue'
import InputBool  from '@/components/form/InputBool.vue'
import FloatProcentRange  from '@/components/form/FloatProcentRange.vue'
import InputInt  from '@/components/form/InputInt.vue'

import { get_lang_name }  from '@/api/funcs'

export default {

    components: {
        "input-text": InputText,
        "input-bool": InputBool,
        "float-range": FloatProcentRange,
        "input-int": InputInt,
    },

    setup(){

        const store = useStore()
        const lang = computed(() => store.getters.lang)
        const game = computed(() => store.getters.game)

        async function update(){
            await store.dispatch('update_games', game.value)
        }

        function game_is_active(){
            if(game.value.status == 'Активна'){
                return true
            }
            return false
        }

        function get_lang_status(status) {
            switch(status){
                case 'Не активна':  return get_lang_name(lang.value, 412)
                case 'Активна':     return get_lang_name(lang.value, 413)
                case 'Завершена':   return get_lang_name(lang.value, 414)
            }
        }

        return { lang, game, update, game_is_active, get_lang_status, }
    }
}

</script>
