<template>
    <div v-if="history" class='history_detail'>

        <div class="top">

            <router-link :to="{ name: `games`, params: { id: history.game.id, },}" class="name">{{ lang.trans_362 }}</router-link>

            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" @click="update()">
                <path d="M6.5997 9.79405C10.903 3.64821 19.3738 2.15459 25.5196 6.45794C28.2432 8.365 30.0526 11.0913 30.8496 14.0699L26.4757 15.242C25.9446 13.2558 24.7382 11.4384 22.9224 10.1671C18.8251 7.29804 13.1779 8.29381 10.3088 12.3913L12.908 14.2111L4.88432 16.1904L4 7.97375L6.5997 9.79405Z" fill="url(#paint0_linear_1834_5432)"/>
                <path d="M12.5326 25.0048C16.63 27.8738 22.2772 26.878 25.1462 22.7806L22.547 20.9607L30.5707 18.9814L31.455 27.1981L28.8554 25.3778C24.552 31.5236 16.0812 33.0172 9.93537 28.7139C7.21187 26.8068 5.40235 24.0806 4.60547 21.1019L8.97925 19.9298C9.51039 21.9161 10.7168 23.7334 12.5326 25.0048Z" fill="url(#paint1_linear_1834_5432)"/>
                <defs>
                <linearGradient id="paint0_linear_1834_5432" x1="35.1731" y1="1.24245" x2="18.548" y2="32.2576" gradientUnits="userSpaceOnUse">
                <stop stop-color="#003DA7"/>
                <stop offset="0.0001" stop-color="#003DA7"/>
                <stop offset="1" stop-color="#50C7FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1834_5432" x1="35.7786" y1="16.2238" x2="19.1534" y2="47.239" gradientUnits="userSpaceOnUse">
                <stop stop-color="#003DA7"/>
                <stop offset="0.0001" stop-color="#003DA7"/>
                <stop offset="1" stop-color="#50C7FF"/>
                </linearGradient>
                </defs>
            </svg>

        </div>

        <div class="steps">

            <div class="name">{{ lang.trans_16 }}</div>

           <div class="top">

                <div
v-for="object in history.steps" :key="object.id" 
                    :class="[`step`, get_status(object.status), { selected: object.id == history.step.id }]"
                    @click="handleEvent(object)"
                >
                    {{ object.step_number }}
                </div>

            </div>

           <div class="name">{{ lang.trans_56 }}</div>

           <div class="bottom">

               <div class="wrap">
                   <div class="name">{{ lang.trans_341 }}</div>
                   <div class="desc">{{ history.step.raw_market }}</div>
               </div>

               <div class="wrap">
                   <div class="name">{{ lang.trans_240 }}</div>
                   <div class="desc">{{ history.step.raw_price }}</div>
               </div>

               <div class="wrap">
                   <div class="name">{{ lang.trans_342 }}</div>
                   <div class="desc">{{ history.step.production_market }}</div>
               </div>

               <div class="wrap">
                   <div class="name">{{ lang.trans_242 }}</div>
                   <div class="desc">{{ history.step.production_price }}</div>
               </div>

               <div v-if="history.game.variant" class="wrap">
                   <div class="name">{{ lang.trans_343 }}</div>
                   <div class="desc">{{ history.step.count_premium_products_market }}</div>
               </div>

               <div v-if="history.game.variant" class="wrap">
                   <div class="name">{{ lang.trans_244 }}</div>
                   <div class="desc">{{ history.step.max_premium_products_price }}</div>
               </div>

           </div>

        </div>

        <chart ></chart>

        <history></history>

    </div>
</template>

<script>

import { computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'

import History from "@/components/History.vue"
import ChartV2 from "@/components/game/ChartV2.vue"


export default {

    components: {
        "history": History,
        "chart": ChartV2,
    },

    setup(){

        const router    = useRouter()
        const route     = useRoute()
        const store     = useStore()

        const lang      = computed(() => store.getters.lang)
        const template  = computed(() => store.getters.template)
        const is_auth   = computed(() => store.getters.is_auth)
        const history   = computed(() => store.getters.history)

        onUnmounted(async () => {
            store.dispatch('update_where_is', '')
        })


        async function update(){

            if(!is_auth.value){
                router.push({ name: `login`, params: { template: template.value.alt_name } }); return
            }

            store.dispatch('global_block_window', true)

            store.dispatch('update_where_is', 'history')

            await store.dispatch('get_history_game', {id: route.params.id, sid: route.params.s_id})

            store.dispatch('global_block_window', false)
        } 

        onMounted(async () => {
            await update()
        })

        function get_status(status){
            switch(status){
                case 'Не начат':    return 'not-start'
                case 'Активен':     return 'active'
                case 'Завершен':    return 'finished'
            }
        }

        watch(router.currentRoute, async () => {

            store.dispatch('global_block_window', true)

            await store.dispatch('get_history_game', {id: route.params.id, sid: route.params.s_id})

            store.dispatch('global_block_window', false)
        })

        function handleEvent(object) {

            //если не начат то пропускаем
            if(object.status == 'Не начат') return

            router.push({name: `history`, params: {id: history.value.game.id, s_id: object.id}})

        }

        return { lang, history, get_status, update, handleEvent }
    }
}

</script>

<style scoped>

.history_detail {
    width: 100%;
    margin-top: 1em;
}

.history_detail > .top {
    background: white;
    padding: 0.5em 1em;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.history_detail > .top > .name {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    letter-spacing: 0.02em;
    text-decoration: underline;

    background: linear-gradient(229.73deg, #003DA7 -19.09%, #003DA7 -19.08%, #50C7FF 131.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.history_detail > .top > svg {
    cursor: pointer;
}

.history_detail > .steps {
    margin-top: 1em;
    background: white;
    padding: 1em;
    border-radius: 20px;
}

.history_detail > .steps > .name {
    margin-bottom: 1em;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 0.25em;
    text-transform: uppercase;
    font-weight: 500;
}

.history_detail > .steps > .top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2em;
}

.history_detail > .steps > .top > .step {
    padding: 0.5em 1em;
    margin: 0 0.5em;
    border-radius: 10px;
}

.history_detail > .steps > .top > .step.finished {
    color: #0d6efd;
    cursor: pointer;
}

.history_detail > .steps > .top > .step.active {
    color: #0d6efd;
    cursor: pointer;
    text-decoration: underline;
}

.history_detail > .steps > .top > .step.not-start {
    color: grey;
}

.history_detail > .steps > .top > .step.selected {
    color: #fff;
    background-color: #0d6efd;
    cursor: pointer;
}

.history_detail > .steps > .bottom > .wrap {
    display: grid;
    margin-bottom: 1em;
    grid-template-columns: 1fr 75px;
    grid-gap: 1em;
}

.history_detail > .steps > .bottom > .wrap > .name {
    flex-grow: 1;
}

.history_detail > .steps > .bottom > .wrap > .desc {
    justify-self: flex-end;
    align-self: center;
}

</style>