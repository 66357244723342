<template>
    <div class="bottom">

        <left></left>

        <historys></historys>

    </div>
</template>

<script>

import Left from "@/components/game/history/bottom/Left.vue";
import Historys from "@/components/game/history/bottom/historys/Historys.vue";

export default {

    components: {
        "left": Left,
        "historys": Historys,
    },

	setup(){
		return { }

	}
}
</script>