<template>
    <div class="pop_up">

        <div class="back" @click="close"></div>

        <div class="wrap">

            <div class="name">{{ lang.trans_442 }}</div>

            <div class="form">

                <input-text v-model:model="form.name" :name="lang.trans_6" :placeholder="lang.trans_6" :max="40" :event="event" :is-call="false"></input-text>

                <input-text v-model:model="form.passw" :name="lang.trans_7" :placeholder="lang.trans_7" :max="40" :event="event" :is-call="false"></input-text>

                <input-select v-model:model="form.team_id" name="Команда" :list="teams" :event="event"></input-select>

            </div>

            <div class="wrap">
                <div class="btn ok" @click="event" @keyup.enter="event">{{ lang.trans_235 }}</div>
                <div class="btn close" @click="close">{{ lang.trans_368 }}</div>
            </div>

        </div>

    </div>
</template>

<script>

import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import InputText from '@/components/form/InputText.vue'
import InputSelect from '@/components/form/InputSelect.vue'

import { requests } from "@/api/funcs"

export default {

    components: {
        "input-text": InputText,
        "input-select": InputSelect,
    },

    props: {
        close: {
            type: Function,
            default: () => { /* Действие по умолчанию, если другое не указано */ }
        },
        user: {
            type: Object,
            default: () => ({ /* Объект по умолчанию */ })
        },
    },

    setup(props) {

        const route = useRoute()
        const store = useStore()
        const domain = computed(() => store.getters.domain)
        const cookie = computed(() => store.getters.cookie)
        const lang = computed(() => store.getters.lang)
        const teams = computed(() => store.getters.teams)

        const form = ref({
            name: "",
            passw: "",
            team_id: 0,
        })

        onMounted(async () => {
            form.value.name = props.user.name
            form.value.passw = props.user.passw
            form.value.team_id = props.user.team_id
        })

        async function event() {

            store.dispatch('global_block_window', true)

            const [err, code] = await requests(`${domain.value}/api/users/operator/${props.user.id}`, 'PUT', cookie.value, form.value)

            if (err) {
                await store.dispatch('update_err_by_code', code); store.dispatch('global_block_window', false); return
            }

            await store.dispatch('get_operator_teams', route.params.id)

            props.close()

            store.dispatch('global_block_window', false)

        }

        return { lang, event, form, teams }

    }
}

</script>

<style scoped>
.pop_up {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop_up>.back {
    background: black;
    opacity: .5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.pop_up>.wrap {
    background: white;
    z-index: 1;
    padding: 1.5em;
    border-radius: 15px;
    max-width: 650px;
    width: 100%;
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
}

@media (min-width: 500px) {
    .pop_up>.wrap {
        font-size: 18px;
    }
}

.pop_up>.wrap>.name {
    font-weight: 500;
    font-size: 1.925em;
    letter-spacing: 0.02em;
    color: #0944AA;
    margin-bottom: 0.5em;
}

@media (min-width: 500px) {
    .pop_up>.wrap>.wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        margin-top: 1em;
    }
}

.pop_up>.wrap>.wrap>.btn {
    border-radius: 4px;
    color: white;
    padding: .9em;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: .9em;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 500px) {
    .pop_up>.wrap>.wrap>.btn {
        width: 100%;
        margin: 0 auto;
    }
}

.pop_up>.wrap>.wrap>.btn.ok {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

.pop_up>.wrap>.wrap>.btn.close {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
}
</style>