<template>
    <div class="operator games">

        <div class="top">
            <div class="wrap">
                <img src="@/assets/images/operator/templates.svg">
                <div class="name games">Список шаблонов дизайна</div>
            </div>
        </div>

        <div v-if="templates && templates.length" class="center">

            <div class="wrap center top">

                <div class="wrap start">
                    <div class="name bold">Наименование</div>
                </div>
                
                <div class="wrap start">
                    <div class="name bold">URL</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">Дизайн</div>
                </div>
                
                <div class="wrap start">
                    <div class="name bold">Логотип</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">Ссылка на логотип</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">По умолчанию</div>
                </div>

                <div class="wrap start">
                    <div class="name bold"></div>
                </div>

            </div>

            <div v-for="object in templates" :key="object.id" class="wrap center">

                <div class="wrap start">
                    <div class="name">
                        <InputText v-model:model="object.human_name" placeholder="lang.trans_191" :event="() => update(object)" :is-call="true"/>
                    </div>
                </div>

                <div class="wrap start">
                    <div class="name">
                        <InputText v-model:model="object.alt_name" placeholder="lang.trans_191" :event="() => update(object)" :is-call="true"/>
                    </div>
                </div>

                <div class="wrap start">
                    <div class="name">
                        <InputSelect_v3 v-model:model="object.name" :list="default_templates" :event="() => update(object)"/>
                    </div>
                </div>
                
                <div class="wrap start">
                    <div class="name">
                        <img :src="object.poster">
                    </div>
                </div>

                <div class="wrap start">
                    <div class="name">
                        <InputText v-model:model="object.poster" placeholder="lang.trans_191" :event="() => update(object)" :is-call="true"/>
                    </div>
                </div>

                <div class="wrap start">
                    <div class="name">
                        <InputBool v-model:model="object.default" :event="() => update(object)"/>
                    </div>
                </div>

                <div class="wrap end">
                    <Delete name="Удалить шаблон?" :desc="`Шаблон ${object.human_name} будет удален навсегда.`" :event="async () => await remove(object.id)"/>
                </div>

            </div>

        </div>

        <div class="btm">

            <img src="@/assets/images/operator/icon_add.svg">

            <div class="name">
                Создать шаблон
            </div>

        </div>

    </div>
</template>

<script setup>



    import { ref, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import InputBool from '@/components/form/InputBool.vue'
    import InputText from '@/components/form/InputText.vue'
    import InputSelect_v3 from '@/components/form/InputSelect_v3.vue'
    import Delete from '@/components/button/Delete.vue'

    const store = useStore()
    const domain = computed(() => store.getters.domain)
    const cookie = computed(() => store.getters.cookie)
    const templates = ref([])


    const default_templates = [
        "sentimental",
        "bluesky",
        "greenflow",
        "mangold",
        "mangold-new-year",
        "mangold-new-year-v2",
        "vvsu",
        "rosneft",
    ]

    async function remove(id) {

        let response = await fetch(`${domain.value}/api/templates/${id}`, {
            method: 'DELETE',
            headers: {
                "Authorization": cookie.value,
            },
        })

        if(!response.ok){
            store.dispatch('update_err_by_code', response.errr); return
        }

        templates.value = await get_users()
    }

    async function update(data){

        let response = await fetch(`${domain.value}/api/templates`, {
            method: 'PUT',
            headers: {
                "Authorization": cookie.value,
                'Content-Type':'application/json',
            },
            body: JSON.stringify(data)
        })

        if(!response.ok){
            store.dispatch('update_err_by_code', response.errr); return
        }

    }

    const get_users = async () => {

        let response = await fetch(`${domain.value}/api/templates`, {
            method: 'GET',
            headers: {
                "Authorization": cookie.value,
            },
        })

        let body = await response.json()

        return body
    }

    onMounted(async () => {
        templates.value = await get_users()
    })

</script>