


export const update_cookie = (state, cookie) => {
    state.cookie = cookie 
}


export const update_is_auth = (state, bool) => {
    state.is_auth = bool
}

export const clear_user = (state) => {
    state.user = {

        username: '',
        password: '',
  
        profile: {
          lang: {},
        },

        groups: [],
    }
}

export const get_user = (state, user) => { 
    state.user = user
}
