
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {
    
    langs: [],
    lang: {},

  },

  getters,
  mutations,
  actions,
  modules: {},

}


