

export function set_cookie(key, value){

    let date = new Date()

    date = date.setDate(date.getDate() + 1)

    document.cookie = key + "=" + value + "; expires=" + new Date(date) + "; path=/; SameSite=Lax";
}

export function get_cookie(n) {
    let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
    return a ? a[1] : false;
}
