<template>
    <div class="pop_up">

        <div class="back"></div>

        <div class="wrap">

            <div class="name">{{ lang.trans_437 }}</div>

            <div class="form">
                <input-text v-model:model="team.name" :name="lang.trans_234" :placeholder="lang.trans_234" :max="40" :event="add" :is-call="false"></input-text>
            </div>

            <div class="wrap">
                <div class="btn ok" @click="add" @keyup.enter="add">{{ lang.trans_226 }}</div>
                <div class="btn close" @click="close">{{ lang.trans_368 }}</div>
            </div>
        </div>

    </div>
</template>

<script>

import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import InputText  from '@/components/form/InputText.vue'

export default {

    components: {
        "input-text": InputText,
    },

    props: {
        close: {
            type: Function,
            default: () => { /* Действие по умолчанию, если другое не указано */ }
        },
    },

    setup(props){


        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)
        const team      = computed(() => store.getters.team)
        const teams      = computed(() => store.getters.teams)

        onMounted(() => {
            team.value.name = `Команда ${teams.value.length + 1}`
        })

        async function add(){

            store.dispatch('global_block_window', true)

            await store.dispatch('create_team', {id: game.value.id, team: team.value})

            await store.dispatch('get_operator_teams', game.value.id)

            props.close()
    
            store.dispatch('global_block_window', false)

        }


        return { lang, team, add, }

    }
}

</script>

<style scoped>
.pop_up {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop_up > .back {
    background: black;
    opacity: .5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.pop_up > .wrap {
    background: white;
    z-index: 1;
    padding: 1.5em;
    border-radius: 15px;
    max-width: 650px;
    width: 100%;
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
}

@media (min-width: 500px){
    .pop_up > .wrap {
        font-size: 18px;
    }
}

.pop_up > .wrap > .name {
    font-weight: 500;
    font-size: 1.925em;
    letter-spacing: 0.02em;
    color: #0944AA;
    margin-bottom: 0.5em;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        margin-top: 1em;
    }
}

.pop_up > .wrap > .wrap > .btn {
    border-radius: 4px;
    color: white;
    padding: .9em;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: .9em;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap > .btn {
        width: 100%;
        margin: 0 auto;
    }
}

.pop_up > .wrap > .wrap > .btn.ok {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

.pop_up > .wrap > .wrap > .btn.close {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
}

</style>