
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {

    col_pop_up: false,

    //история таба
    tab_historys: [],

    //пагинация
    pagination: {
        start: 0, 
        stop: 0,
    },

    cols: [],

    tabs: null,

  },

  getters,
  mutations,
  actions,
  modules: {},

}


