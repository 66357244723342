<template>
    <div class="results">

        <div class="top">
            <div class="tab first"> 
                <div class="wrap active">
                    <div class="icon">
                        <svg width="20" height="39" viewBox="0 0 20 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 29L0 27.5L7.5 20L11.5 24L18.6 16L20 17.4L11.5 27L7.5 23L1.5 29ZM1.5 23L0 21.5L7.5 14L11.5 18L18.6 10L20 11.4L11.5 21L7.5 17L1.5 23Z" fill="#0944AA"/>
                        </svg>
                    </div>
                    <div class="name">
                        {{ lang.trans_707001 }}
                    </div>
                </div>
            </div>
        </div>

        <div class="active-tab">
            {{ lang.trans_707001 }}
        </div>

        <div class="bottom">

            <div class="left">
                <div class="wrap">

                    <div class="name">
                        {{ lang.trans_17 }}
                    </div>

                    <div v-if="cols_filter[0]">
                        <div v-for="history in filter_historys(cols_filter[0].id)" :key="history.id" :class="['desc', {'red': history.bankruptcy },]">
                            {{ history.name }}
                        </div>
                    </div>

                </div>
            </div>

            <div v-touch:swipe.left="left_cols" v-touch:swipe.right="right_cols" class="historys">

                <div v-for="(col, i) in cols_filter" :key="col.id" ref="listItem" :class="['wrap', `column_${i}`]">

                    <div class="wrap">
                        
                        <arrow-left v-if="i == 0 && pagination.start > 0" @click="left_cols" />

                        <div class="name">
                            {{ col.name }}
                        </div>

                        <arrow-right v-if="cols.length != pagination.stop && pagination.stop < cols.length && i == 0" @click="right_cols" />
      
                    </div>

                    <div>
                        <div v-for="history in filter_historys(col.id)" :key="history.id" :class="['desc', {'red': history.bankruptcy },]">
                            {{ format_number(history.company_value) }}
                        </div>
                    </div>

                </div>

            </div>

        </div>

        <div class="wrap">
            <div class="pagination">
                <div v-for="col in cols" :key="col.id" :class="[`name`, { active: col.active }]"></div>
            </div>
        </div>

    </div>
</template>

<script>

import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'

import { domain } from '@/global'
import { requests } from "@/api/funcs"
import { format_number } from "@/api/funcs";

// пользовательская функция для настройки пагинации.
import { usePagination } from './usePagination'

import ArrowLeft from './ArrowLeft.vue'
import ArrowRight from './ArrowRight.vue'

export default {

    components: {
        ArrowLeft,
        ArrowRight,
    },

    setup() {

        const store = useStore()
        const route = useRoute()
        const cookie = computed(() => store.getters.cookie)
        const lang  = computed(() => store.getters.lang)

        const {
            cols,
            cols_filter,
            pagination,
            updateActiveCols,
            left_cols,
            right_cols,
            calculateVisibleColumns,
        } = usePagination();

        // массив для хранения объектов вкладок. 
        const tabs = ref([])

        // объект, представляющий активную вкладку. 
        const activeTab = ref({})

        // массив для хранения объектов данных для компонента истории. 
        const historys = ref([])

        function filter_historys(step_id) {
            return historys.value.filter((o) => o.step_id == step_id)
        }

        async function update () {

            const [err, code, body] = await requests(`${domain}/api/operator/analytics/games/${route.params.id}/rating`, 'GET', cookie.value)
            if (err) {
                store.dispatch('update_err_by_code', code); return;
            }

            // Загружаем табы
            tabs.value = body

            if(tabs.value.length){

                // Обновляем историю вкладки
                const activeTab = tabs.value.find(tab => tab.active)
                if (activeTab) {
                    historys.value = activeTab.histories;
                    activeTab.value = activeTab;
                }

                // Устанавливаем колонки активной таблицы
                cols.value = activeTab.cols

                calculateVisibleColumns(); updateActiveCols()
            }
            
        }

        onMounted(async () => {
            await update();
        })


        return {
            active_tab: computed(() => activeTab.value),

            tabs,
            cols, cols_filter,

            historys,
            filter_historys,
            pagination,

            left_cols, right_cols,
            format_number,
            lang

        }
    }
}
</script>


<style lang="sass" scoped>
@import "@/assets/sass/results.sass"
</style>

