<template>
    <div class="block">

        <div class="top">

            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.0624 19.4375H28.9926C28.6862 20.5981 28.2246 21.7085 27.6145 22.7532L29.0816 24.2202C29.2574 24.396 29.3562 24.6344 29.3562 24.883C29.3562 25.1318 29.2574 25.3702 29.0816 25.546L25.546 29.0814C25.18 29.4474 24.5862 29.4476 24.2202 29.0814L22.7531 27.6144C21.7085 28.2246 20.5981 28.6862 19.4376 28.9926V31.0626C19.4376 31.5802 19.0178 32 18.5 32H13.5C12.9823 32 12.5625 31.5802 12.5625 31.0626V28.9926C11.4019 28.6862 10.2916 28.2246 9.24694 27.6144L7.77987 29.0814C7.41381 29.4474 6.82019 29.4476 6.45407 29.0814L2.91857 25.546C2.74275 25.3702 2.64393 25.1316 2.64393 24.883C2.64393 24.6345 2.74275 24.396 2.91857 24.2202L4.38563 22.7532C3.77538 21.7085 3.31387 20.5981 3.00738 19.4375H0.937502C0.419744 19.4375 0 19.0177 0 18.5V13.5C0 12.9823 0.419744 12.5625 0.937502 12.5625H3.00738C3.31381 11.4019 3.77538 10.2916 4.38557 9.24694L2.91851 7.77994C2.74269 7.60412 2.64387 7.36569 2.64387 7.117C2.64387 6.86838 2.74263 6.62993 2.91851 6.45412L6.45401 2.91857C6.62981 2.74275 6.86826 2.64393 7.11688 2.64393C7.3655 2.64393 7.60393 2.74275 7.77975 2.91857L9.24682 4.38563C10.2914 3.77544 11.4018 3.31393 12.5624 3.00743V0.937503C12.5624 0.419749 12.9821 0 13.4999 0H18.4998C19.0176 0 19.4374 0.419749 19.4374 0.937503V3.00737C20.598 3.31381 21.7084 3.77538 22.7529 4.38556L24.22 2.91851C24.3958 2.74269 24.6342 2.64387 24.8829 2.64387C25.1315 2.64387 25.3699 2.74269 25.5457 2.91851L29.0813 6.45407C29.2571 6.62987 29.3558 6.86832 29.3558 7.11694C29.3558 7.36562 29.2571 7.604 29.0813 7.77988L27.6141 9.24688C28.2243 10.2915 28.6859 11.4018 28.9924 12.5624H31.0624C31.5802 12.5624 32 12.9822 32 13.4999V18.5C32 19.0177 31.5802 19.4375 31.0624 19.4375ZM16 7.25C11.1752 7.25 7.25 11.1753 7.25 15.9999C7.25 20.8248 11.1753 24.7501 16 24.7501C20.8247 24.7501 24.7499 20.8248 24.7499 15.9999C24.7499 11.1753 20.8249 7.25 16 7.25Z" fill="currentColor"/>
                <path d="M19.75 20.6875H16.9375V18.8124C16.9375 18.2948 16.5177 17.875 16 17.875C15.4822 17.875 15.0626 18.2948 15.0626 18.8124V20.6875H12.25C11.7322 20.6875 11.3125 20.2678 11.3125 19.75V12.25C11.3125 11.7322 11.7322 11.3125 12.25 11.3125H19.75C20.2678 11.3125 20.6876 11.7322 20.6876 12.25V19.75C20.6876 20.2678 20.2678 20.6875 19.75 20.6875Z" fill="currentColor"/>
            </svg>

            <div class="name">
                {{ lang.trans_97 }}
            </div>

        </div>

        <div class="center">

            <img :src="require(`@/assets/images/rules/${template.alt_name}/production.svg`)">

            <div class="wrap">

                <div class="name">
                    {{ lang.trans_468 }} - {{ format_number(game.game.capacity_ord) }}
                </div>

                <div class="wrap">

                    <div class="name bottom">
                        {{ lang.trans_469 }}
                    </div>

                    <div class="name bottom left">
                        {{ lang.trans_470 }}
                    </div>

                    <div class="name">
                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.95396 5.02066L8.76064 4.5L5.46997 5.93799L6.6633 6.45999L9.95396 5.02066Z" fill="currentColor"/>
                            <path d="M19.6699 4.27258L10.6667 0.333252L1.66663 4.27258L3.80929 5.2099L8.49328 3.16124C8.57739 3.12454 8.66817 3.10559 8.75994 3.10559C8.85171 3.10559 8.9425 3.12454 9.02661 3.16124L11.8866 4.40591C12.0053 4.45789 12.1062 4.54333 12.1771 4.65178C12.248 4.76024 12.2858 4.887 12.2858 5.01658C12.2858 5.14615 12.248 5.27291 12.1771 5.38137C12.1062 5.48982 12.0053 5.57526 11.8866 5.62724L8.32661 7.1879L10.6667 8.2119L19.6699 4.27258Z" fill="currentColor"/>
                            <path d="M0.666626 5.29199V17.1972L9.99994 21.9452V9.37532L7.33328 8.20599V11.606C7.33333 11.7196 7.30433 11.8314 7.24903 11.9307C7.19373 12.03 7.11396 12.1134 7.0173 12.1733C6.92064 12.233 6.81031 12.2671 6.69678 12.2723C6.58324 12.2774 6.47027 12.2535 6.36861 12.2026L3.70195 10.8693C3.59113 10.8139 3.49793 10.7287 3.43282 10.6234C3.3677 10.518 3.33322 10.3965 3.33329 10.2727V6.45866L0.666626 5.29199Z" fill="currentColor"/>
                            <path d="M4.66663 9.86065L5.99996 10.5273V7.62532L4.66663 7.04199V9.86065Z" fill="currentColor"/>
                            <path d="M20.6666 5.29199L11.3333 9.37532V21.9513L20.6666 17.2613V5.29199ZM12.3672 15.7706L14.9999 14.4473C15.0782 14.4079 15.1634 14.3843 15.2507 14.3779C15.338 14.3713 15.4258 14.3822 15.509 14.4097C15.5921 14.4371 15.6691 14.4807 15.7355 14.5379C15.8017 14.595 15.8562 14.6648 15.8956 14.7429C15.935 14.8212 15.9585 14.9064 15.9651 14.9938C15.9715 15.0812 15.9607 15.1689 15.9332 15.252C15.9058 15.3352 15.8622 15.4121 15.805 15.4785C15.7479 15.5448 15.6781 15.5992 15.6 15.6386L12.9666 16.962C12.8884 17.0014 12.8031 17.0249 12.7158 17.0314C12.6284 17.0379 12.5407 17.0271 12.4576 16.9996C12.3743 16.9722 12.2974 16.9286 12.2311 16.8714C12.1647 16.8142 12.1104 16.7445 12.071 16.6663C12.0315 16.5881 12.0079 16.5028 12.0015 16.4155C11.995 16.3281 12.0059 16.2404 12.0332 16.1572C12.0607 16.0741 12.1043 15.9971 12.1615 15.9308C12.2187 15.8645 12.2891 15.81 12.3672 15.7706ZM15.6373 18.286L12.9659 19.6286C12.8877 19.668 12.8025 19.6917 12.7151 19.6981C12.6278 19.7046 12.54 19.6937 12.4568 19.6663C12.3737 19.6388 12.2967 19.5952 12.2305 19.5381C12.1641 19.4809 12.1096 19.4112 12.0702 19.333C12.0309 19.2547 12.0073 19.1696 12.0008 19.0822C11.9944 18.9949 12.0051 18.907 12.0326 18.8239C12.06 18.7407 12.1036 18.6638 12.1608 18.5974C12.2181 18.5311 12.2877 18.4767 12.366 18.4373L15.0372 17.0946C15.1155 17.0552 15.2007 17.0317 15.288 17.0251C15.3754 17.0187 15.4631 17.0295 15.5463 17.057C15.6295 17.0844 15.7064 17.128 15.7728 17.1852C15.8392 17.2424 15.8935 17.3121 15.9329 17.3903C15.9723 17.4685 15.996 17.5538 16.0024 17.6411C16.0089 17.7284 15.998 17.8162 15.9706 17.8994C15.9431 17.9825 15.8995 18.0595 15.8424 18.1258C15.7852 18.1921 15.7155 18.2466 15.6373 18.286Z" fill="currentColor"/>
                        </svg>
                    </div>

                    <div class="name left">

                        <div class="name">
                            {{ format_number(game.game.variable_costs_ord) }}₽
                        </div>

                        <div class="desc">

                            + {{ format_number(game.game.raw_for_standart) }}

                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9941 8.85767L16.2207 7.4436V3.20532C16.2207 2.61938 15.8574 2.09595 15.3066 1.88892L11.4004 0.424072C11.084 0.302979 10.7324 0.302979 10.4121 0.424072L6.50586 1.88892C5.95508 2.09595 5.5918 2.61938 5.5918 3.20532V7.4436L1.81836 8.85767C1.27148 9.0647 0.908203 9.58813 0.908203 10.1741V14.4749C0.908203 15.0061 1.20898 15.4944 1.68555 15.7327L5.5918 17.6858C5.98633 17.885 6.45508 17.885 6.84961 17.6858L10.9082 15.6545L14.9668 17.6858C15.3613 17.885 15.8301 17.885 16.2246 17.6858L20.1309 15.7327C20.6074 15.4944 20.9082 15.0061 20.9082 14.4749V10.1741C20.9082 9.58813 20.5449 9.0647 19.9941 8.85767ZM14.8926 7.47485L11.5723 8.72095V6.05688L14.8926 4.61157V7.47485ZM6.92383 3.15063L10.9082 1.65845L14.8926 3.15063V3.17407L10.9082 4.79126L6.92383 3.17407V3.15063ZM10.2051 14.5217L6.88477 16.1819V13.092L10.2051 11.5764V14.5217ZM10.2051 10.1467L6.2207 11.7639L2.23633 10.1467V10.1233L6.2207 8.6311L10.2051 10.1233V10.1467ZM19.5801 14.5217L16.2598 16.1819V13.092L19.5801 11.5764V14.5217ZM19.5801 10.1467L15.5957 11.7639L11.6113 10.1467V10.1233L15.5957 8.6311L19.5801 10.1233V10.1467Z" fill="currentColor"/>
                            </svg>

                        </div>

                    </div>

                    <div v-if="game.game.variant" class="name">
                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.036499 7.27344L9.59719 18.3131L6.41187 7.27344H0.036499Z" fill="currentColor"/>
                            <path d="M7.1217 7.27344L10.6619 19.5417L14.2023 7.27344H7.1217Z" fill="currentColor"/>
                            <path d="M2.95792 2.6272L0 6.59071H5.92023L2.95792 2.6272Z" fill="currentColor"/>
                            <path d="M21.3333 6.59071L18.3754 2.6272L15.4176 6.59071H21.3333Z" fill="currentColor"/>
                            <path d="M6.59833 6.35889L9.81112 2.05859H3.38586L6.59833 6.35889Z" fill="currentColor"/>
                            <path d="M17.9477 2.05859H11.5179L14.7352 6.36336L17.9477 2.05859Z" fill="currentColor"/>
                            <path d="M14.0524 6.59089L10.6668 2.05859L7.28113 6.59089H14.0524Z" fill="currentColor"/>
                            <path d="M11.7272 18.3178L21.2923 7.27368H14.917L11.7272 18.3178Z" fill="currentColor"/>
                            <path d="M2.60966 2.37114L3.02754 1.43364L3.79525 1.18557L3.02754 0.93417L2.60966 0L2.19178 0.93417L1.42407 1.18557L2.19178 1.43364L2.60966 2.37114Z" fill="currentColor"/>
                            <path d="M1.5326 3.80283L1.79654 3.21593L2.27886 3.06103L1.79654 2.90184L1.5326 2.3103L1.26406 2.90184L0.781738 3.06103L1.26406 3.21593L1.5326 3.80283Z" fill="currentColor"/>
                            <path d="M4.96631 1.78113L5.17565 1.31689L5.55323 1.19405L5.17565 1.07121L4.96631 0.602539L4.75697 1.07121L4.37463 1.19405L4.75697 1.31689L4.96631 1.78113Z" fill="currentColor"/>
                        </svg>
                    </div>

                    <div v-if="game.game.variant" class="name left">

                        <div class="name">
                            {{ format_number(game.game.premium_variable_costs_ord) }}₽
                        </div>

                        <div class="desc">

                            + {{ format_number(game.game.raw_for_premium) }}

                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9941 8.85767L16.2207 7.4436V3.20532C16.2207 2.61938 15.8574 2.09595 15.3066 1.88892L11.4004 0.424072C11.084 0.302979 10.7324 0.302979 10.4121 0.424072L6.50586 1.88892C5.95508 2.09595 5.5918 2.61938 5.5918 3.20532V7.4436L1.81836 8.85767C1.27148 9.0647 0.908203 9.58813 0.908203 10.1741V14.4749C0.908203 15.0061 1.20898 15.4944 1.68555 15.7327L5.5918 17.6858C5.98633 17.885 6.45508 17.885 6.84961 17.6858L10.9082 15.6545L14.9668 17.6858C15.3613 17.885 15.8301 17.885 16.2246 17.6858L20.1309 15.7327C20.6074 15.4944 20.9082 15.0061 20.9082 14.4749V10.1741C20.9082 9.58813 20.5449 9.0647 19.9941 8.85767ZM14.8926 7.47485L11.5723 8.72095V6.05688L14.8926 4.61157V7.47485ZM6.92383 3.15063L10.9082 1.65845L14.8926 3.15063V3.17407L10.9082 4.79126L6.92383 3.17407V3.15063ZM10.2051 14.5217L6.88477 16.1819V13.092L10.2051 11.5764V14.5217ZM10.2051 10.1467L6.2207 11.7639L2.23633 10.1467V10.1233L6.2207 8.6311L10.2051 10.1233V10.1467ZM19.5801 14.5217L16.2598 16.1819V13.092L19.5801 11.5764V14.5217ZM19.5801 10.1467L15.5957 11.7639L11.6113 10.1467V10.1233L15.5957 8.6311L19.5801 10.1233V10.1467Z" fill="currentColor"/>
                            </svg>

                        </div>

                    </div>

                </div>

                <div class="name">
                    {{ lang.trans_471 }} - {{ format_number(game.game.capacity_auto) }}
                </div>

                <div class="wrap">

                    <div class="name bottom">
                        {{ lang.trans_469 }}
                    </div>

                    <div class="name bottom left">
                        {{ lang.trans_470 }}
                    </div>

                    <div class="name">
                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.95396 5.02066L8.76064 4.5L5.46997 5.93799L6.6633 6.45999L9.95396 5.02066Z" fill="currentColor"/>
                            <path d="M19.6699 4.27258L10.6667 0.333252L1.66663 4.27258L3.80929 5.2099L8.49328 3.16124C8.57739 3.12454 8.66817 3.10559 8.75994 3.10559C8.85171 3.10559 8.9425 3.12454 9.02661 3.16124L11.8866 4.40591C12.0053 4.45789 12.1062 4.54333 12.1771 4.65178C12.248 4.76024 12.2858 4.887 12.2858 5.01658C12.2858 5.14615 12.248 5.27291 12.1771 5.38137C12.1062 5.48982 12.0053 5.57526 11.8866 5.62724L8.32661 7.1879L10.6667 8.2119L19.6699 4.27258Z" fill="currentColor"/>
                            <path d="M0.666626 5.29199V17.1972L9.99994 21.9452V9.37532L7.33328 8.20599V11.606C7.33333 11.7196 7.30433 11.8314 7.24903 11.9307C7.19373 12.03 7.11396 12.1134 7.0173 12.1733C6.92064 12.233 6.81031 12.2671 6.69678 12.2723C6.58324 12.2774 6.47027 12.2535 6.36861 12.2026L3.70195 10.8693C3.59113 10.8139 3.49793 10.7287 3.43282 10.6234C3.3677 10.518 3.33322 10.3965 3.33329 10.2727V6.45866L0.666626 5.29199Z" fill="currentColor"/>
                            <path d="M4.66663 9.86065L5.99996 10.5273V7.62532L4.66663 7.04199V9.86065Z" fill="currentColor"/>
                            <path d="M20.6666 5.29199L11.3333 9.37532V21.9513L20.6666 17.2613V5.29199ZM12.3672 15.7706L14.9999 14.4473C15.0782 14.4079 15.1634 14.3843 15.2507 14.3779C15.338 14.3713 15.4258 14.3822 15.509 14.4097C15.5921 14.4371 15.6691 14.4807 15.7355 14.5379C15.8017 14.595 15.8562 14.6648 15.8956 14.7429C15.935 14.8212 15.9585 14.9064 15.9651 14.9938C15.9715 15.0812 15.9607 15.1689 15.9332 15.252C15.9058 15.3352 15.8622 15.4121 15.805 15.4785C15.7479 15.5448 15.6781 15.5992 15.6 15.6386L12.9666 16.962C12.8884 17.0014 12.8031 17.0249 12.7158 17.0314C12.6284 17.0379 12.5407 17.0271 12.4576 16.9996C12.3743 16.9722 12.2974 16.9286 12.2311 16.8714C12.1647 16.8142 12.1104 16.7445 12.071 16.6663C12.0315 16.5881 12.0079 16.5028 12.0015 16.4155C11.995 16.3281 12.0059 16.2404 12.0332 16.1572C12.0607 16.0741 12.1043 15.9971 12.1615 15.9308C12.2187 15.8645 12.2891 15.81 12.3672 15.7706ZM15.6373 18.286L12.9659 19.6286C12.8877 19.668 12.8025 19.6917 12.7151 19.6981C12.6278 19.7046 12.54 19.6937 12.4568 19.6663C12.3737 19.6388 12.2967 19.5952 12.2305 19.5381C12.1641 19.4809 12.1096 19.4112 12.0702 19.333C12.0309 19.2547 12.0073 19.1696 12.0008 19.0822C11.9944 18.9949 12.0051 18.907 12.0326 18.8239C12.06 18.7407 12.1036 18.6638 12.1608 18.5974C12.2181 18.5311 12.2877 18.4767 12.366 18.4373L15.0372 17.0946C15.1155 17.0552 15.2007 17.0317 15.288 17.0251C15.3754 17.0187 15.4631 17.0295 15.5463 17.057C15.6295 17.0844 15.7064 17.128 15.7728 17.1852C15.8392 17.2424 15.8935 17.3121 15.9329 17.3903C15.9723 17.4685 15.996 17.5538 16.0024 17.6411C16.0089 17.7284 15.998 17.8162 15.9706 17.8994C15.9431 17.9825 15.8995 18.0595 15.8424 18.1258C15.7852 18.1921 15.7155 18.2466 15.6373 18.286Z" fill="currentColor"/>
                        </svg>
                    </div>

                    <div class="name left">

                        <div class="name">
                            {{ format_number(game.game.variable_costs_auto) }}₽
                        </div>

                        <div class="desc">
                            + {{ format_number(game.game.raw_for_standart) }}

                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9941 8.85767L16.2207 7.4436V3.20532C16.2207 2.61938 15.8574 2.09595 15.3066 1.88892L11.4004 0.424072C11.084 0.302979 10.7324 0.302979 10.4121 0.424072L6.50586 1.88892C5.95508 2.09595 5.5918 2.61938 5.5918 3.20532V7.4436L1.81836 8.85767C1.27148 9.0647 0.908203 9.58813 0.908203 10.1741V14.4749C0.908203 15.0061 1.20898 15.4944 1.68555 15.7327L5.5918 17.6858C5.98633 17.885 6.45508 17.885 6.84961 17.6858L10.9082 15.6545L14.9668 17.6858C15.3613 17.885 15.8301 17.885 16.2246 17.6858L20.1309 15.7327C20.6074 15.4944 20.9082 15.0061 20.9082 14.4749V10.1741C20.9082 9.58813 20.5449 9.0647 19.9941 8.85767ZM14.8926 7.47485L11.5723 8.72095V6.05688L14.8926 4.61157V7.47485ZM6.92383 3.15063L10.9082 1.65845L14.8926 3.15063V3.17407L10.9082 4.79126L6.92383 3.17407V3.15063ZM10.2051 14.5217L6.88477 16.1819V13.092L10.2051 11.5764V14.5217ZM10.2051 10.1467L6.2207 11.7639L2.23633 10.1467V10.1233L6.2207 8.6311L10.2051 10.1233V10.1467ZM19.5801 14.5217L16.2598 16.1819V13.092L19.5801 11.5764V14.5217ZM19.5801 10.1467L15.5957 11.7639L11.6113 10.1467V10.1233L15.5957 8.6311L19.5801 10.1233V10.1467Z" fill="currentColor"/>
                            </svg>

                        </div>

                    </div>

                    <div v-if="game.game.variant" class="name">
                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.036499 7.27344L9.59719 18.3131L6.41187 7.27344H0.036499Z" fill="currentColor"/>
                            <path d="M7.1217 7.27344L10.6619 19.5417L14.2023 7.27344H7.1217Z" fill="currentColor"/>
                            <path d="M2.95792 2.6272L0 6.59071H5.92023L2.95792 2.6272Z" fill="currentColor"/>
                            <path d="M21.3333 6.59071L18.3754 2.6272L15.4176 6.59071H21.3333Z" fill="currentColor"/>
                            <path d="M6.59833 6.35889L9.81112 2.05859H3.38586L6.59833 6.35889Z" fill="currentColor"/>
                            <path d="M17.9477 2.05859H11.5179L14.7352 6.36336L17.9477 2.05859Z" fill="currentColor"/>
                            <path d="M14.0524 6.59089L10.6668 2.05859L7.28113 6.59089H14.0524Z" fill="currentColor"/>
                            <path d="M11.7272 18.3178L21.2923 7.27368H14.917L11.7272 18.3178Z" fill="currentColor"/>
                            <path d="M2.60966 2.37114L3.02754 1.43364L3.79525 1.18557L3.02754 0.93417L2.60966 0L2.19178 0.93417L1.42407 1.18557L2.19178 1.43364L2.60966 2.37114Z" fill="currentColor"/>
                            <path d="M1.5326 3.80283L1.79654 3.21593L2.27886 3.06103L1.79654 2.90184L1.5326 2.3103L1.26406 2.90184L0.781738 3.06103L1.26406 3.21593L1.5326 3.80283Z" fill="currentColor"/>
                            <path d="M4.96631 1.78113L5.17565 1.31689L5.55323 1.19405L5.17565 1.07121L4.96631 0.602539L4.75697 1.07121L4.37463 1.19405L4.75697 1.31689L4.96631 1.78113Z" fill="currentColor"/>
                        </svg>
                    </div>

                    <div v-if="game.game.variant" class="name left">

                        <div class="name">
                            {{ format_number(game.game.premium_variable_costs_auto) }}₽
                        </div>

                    <div class="desc">

                            + {{ format_number(game.game.raw_for_premium) }}

                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9941 8.85767L16.2207 7.4436V3.20532C16.2207 2.61938 15.8574 2.09595 15.3066 1.88892L11.4004 0.424072C11.084 0.302979 10.7324 0.302979 10.4121 0.424072L6.50586 1.88892C5.95508 2.09595 5.5918 2.61938 5.5918 3.20532V7.4436L1.81836 8.85767C1.27148 9.0647 0.908203 9.58813 0.908203 10.1741V14.4749C0.908203 15.0061 1.20898 15.4944 1.68555 15.7327L5.5918 17.6858C5.98633 17.885 6.45508 17.885 6.84961 17.6858L10.9082 15.6545L14.9668 17.6858C15.3613 17.885 15.8301 17.885 16.2246 17.6858L20.1309 15.7327C20.6074 15.4944 20.9082 15.0061 20.9082 14.4749V10.1741C20.9082 9.58813 20.5449 9.0647 19.9941 8.85767ZM14.8926 7.47485L11.5723 8.72095V6.05688L14.8926 4.61157V7.47485ZM6.92383 3.15063L10.9082 1.65845L14.8926 3.15063V3.17407L10.9082 4.79126L6.92383 3.17407V3.15063ZM10.2051 14.5217L6.88477 16.1819V13.092L10.2051 11.5764V14.5217ZM10.2051 10.1467L6.2207 11.7639L2.23633 10.1467V10.1233L6.2207 8.6311L10.2051 10.1233V10.1467ZM19.5801 14.5217L16.2598 16.1819V13.092L19.5801 11.5764V14.5217ZM19.5801 10.1467L15.5957 11.7639L11.6113 10.1467V10.1233L15.5957 8.6311L19.5801 10.1233V10.1467Z" fill="currentColor"/>
                            </svg>

                        </div>

                    </div>

                </div>

            </div>
        </div>

        <div class="bottom">
            {{ lang.trans_472 }}
        </div>

        </div>

</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'
import { format_number } from "@/api/funcs";

export default {

    setup(){

        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const template  = computed(() => store.getters.template)
        const game      = computed(() => store.getters.game)

        return {
            close,
            lang,
            template,
            game,
            format_number,
        }
    }
}
</script>