<template>
    <div v-touch:swipe.left="left" v-touch:swipe.right="right" class="historys">
        <div v-for="(col, i) in cols_filter" :key="col.id" :class="['wrap', `column_${i}`]">

            <col-name :i="i" :col="col"></col-name>

            <tab-historys :col-name="col.name" @click="close_pop_up()"></tab-historys>
            
        </div>
    </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

import ColName from "@/components/game/history/bottom/historys/ColName.vue";
import TabHistorys from "@/components/game/history/bottom/historys/TabHistorys.vue";

export default {

    components: {
        "col-name": ColName,
        "tab-historys": TabHistorys,
    },

	setup(){

        const store             = useStore()
        const cols_filter       = computed(() => store.getters.cols_filter)

        function close_pop_up(){
            store.dispatch('update_pop_up', false)
        }

        function left(){
            store.dispatch('update_left_pagination')
        }

        function right(){
            store.dispatch('update_right_pagination')
        }

		return {
            close_pop_up,
            cols_filter,
            left, 
            right,
		}

	}
}
</script>