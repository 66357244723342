<template>


    <div class="wrap">

        <div class="new_style">

            <div class="wrap fixed">

                <div class="wrap">

                    <div class="name top">
                        {{ lang.trans_433 }}: {{ lang.trans_434 }}
                    </div>

                    <div class="name">
                        {{ lang.trans_341 }}
                    </div>
        
                    <div class="name">
                        {{ lang.trans_240 }}
                    </div>

                    <div class="name">
                        {{ lang.trans_342 }}
                    </div>

                    <div class="name">
                        {{ lang.trans_242 }}
                    </div>
        
                    <div class="name">
                        {{ lang.trans_343 }}
                    </div>
        
                    <div class="name">
                        {{ lang.trans_244 }}
                    </div>

                </div>
            </div>

            <div class="wrap scroll">

                <div v-for="object in game.steps" :key="object.id" class="wrap">

                    <!-- <router-link :to="{ name: 'steps-game-edit', params: { s_id: object.id } }" class="name top"> -->
                    <div class="name top">

                        <div class="name">
                            
                            {{ lang.trans_16 }} 

                            {{ object.name }}

                            {{ object.step_number }}

                        </div>

                    </div>
                    <!-- </router-link> -->

                    <div :class="['name', {finished: step_is_finished(object) || tact_purchacha_is_finished(object)}]">
                        <span v-if="(step_is_finished(object) || tact_purchacha_is_finished(object))">{{ object.raw_market }}</span>
                        <input v-else type="text" :value="object.raw_market" @input="test('raw_market', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <div :class="['name', {finished: step_is_finished(object) || tact_purchacha_is_finished(object)}]">
                        <span v-if="(step_is_finished(object) || tact_purchacha_is_finished(object))">{{ object.raw_price }}</span>
                        <input v-else type="text" :value="object.raw_price" @input="test('raw_price', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <div :class="['name', {finished: step_is_finished(object) || tact_sales_is_finished(object)}]">
                        <span v-if="(step_is_finished(object) || tact_sales_is_finished(object))">{{ object.production_market }}</span>
                        <input v-else type="text" :value="object.production_market" @input="test('production_market', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <div :class="['name', {finished: step_is_finished(object) || tact_sales_is_finished(object)}]">
                        <span v-if="(step_is_finished(object) || tact_sales_is_finished(object))">{{ object.production_price }}</span>
                        <input v-else type="text" :value="object.production_price" @input="test('production_price', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <div :class="['name', {finished: step_is_finished(object) || tact_sales_is_finished(object)}]">
                        <span v-if="(step_is_finished(object) || tact_sales_is_finished(object))">{{ object.count_premium_products_market }}</span>
                        <input v-else type="text" :value="object.count_premium_products_market" @input="test('count_premium_products_market', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <div :class="['name', {finished: step_is_finished(object) || tact_sales_is_finished(object)}]">
                        <span v-if="(step_is_finished(object) || tact_sales_is_finished(object))">{{ object.max_premium_products_price }}</span>
                        <input v-else type="text" :value="object.max_premium_products_price" @input="test('max_premium_products_price', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                </div>

            </div>

            <div class="icons">

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="open_pop_up_for_delete(get_last_step())">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="url(#paint0_linear_331_852)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 9L9 15" stroke="url(#paint1_linear_331_852)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 9L15 15" stroke="url(#paint2_linear_331_852)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <defs>
                    <linearGradient id="paint0_linear_331_852" x1="25.2206" y1="-2.52411" x2="-7.08562" y2="24.8397" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A70000"/>
                    <stop offset="1" stop-color="#FF5050"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_331_852" x1="15.9662" y1="7.64277" x2="6.27431" y2="15.8519" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A70000"/>
                    <stop offset="1" stop-color="#FF5050"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_331_852" x1="15.9662" y1="7.64277" x2="6.27431" y2="15.8519" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A70000"/>
                    <stop offset="1" stop-color="#FF5050"/>
                    </linearGradient>
                    </defs>
                </svg>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="add_steps()">
                    <rect x="2" y="2" width="20" height="20" rx="10" stroke="url(#paint0_linear_331_856)" stroke-width="2"/>
                    <path d="M12 7.75736V16.2426" stroke="url(#paint1_linear_331_856)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.75732 12H16.2426" stroke="url(#paint2_linear_331_856)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <defs>
                    <linearGradient id="paint0_linear_331_856" x1="25.2206" y1="-2.52411" x2="-7.08562" y2="24.8397" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#003DA7"/>
                    <stop offset="1" stop-color="#50C7FF"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_331_856" x1="11.7234" y1="6.11446" x2="10.675" y2="18.7724" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#003DA7"/>
                    <stop offset="1" stop-color="#50C7FF"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_331_856" x1="11.7234" y1="6.11446" x2="10.675" y2="18.7724" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#003DA7"/>
                    <stop offset="1" stop-color="#50C7FF"/>
                    </linearGradient>
                    </defs>
                </svg>
            </div>
            
        </div>


    </div>

    <pop-up v-if="pop_up.active" :name="pop_up.name" :desc="pop_up.desc" :ok="pop_up.ok" :close="pop_up.close"></pop-up>

</template>

<script>

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import PopUp  from '@/components/PopUp.vue'


export default {

    components: {
        'pop-up': PopUp,
    },

    setup(){

        const route     = useRoute()
        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)

        function clean_focus(target){
            target.blur()
        }

        async function test(type, object, target){
            
            let result = parseInt(target.value, 10)
            if(isNaN(result)){
                result = 0
            }

            target.value = result

            switch(type){

                case 'raw_market':
                    object.raw_market = result; break

                case 'raw_price':
                    object.raw_price = result; break

                case 'production_market':
                    object.production_market = result; break

                case 'production_price':
                    object.production_price = result; break

                case 'count_premium_products_market':
                    object.count_premium_products_market = result; break

                case 'max_premium_products_price':
                    object.max_premium_products_price = result; break
            }


            await store.dispatch('update_steps', object)

        }

        function tact_purchacha_is_finished(step){
            
            if(step.status == "Не начат"){
                return false
            }

            if(step.status == "Активен" && step.current_tact == 'Снабжение'){
                return false
            } 
            
            return true
        }

        function tact_sales_is_finished(step){

            if(step.status == "Не начат"){
                return false
            }

            if(step.status == "Активен" && step.current_tact == 'Снабжение'){
                return false
            } 

            if(step.status == "Активен" && step.current_tact == 'Производство'){
                return false
            } 

            if(step.status == "Активен" && step.current_tact == 'Продажи'){
                return false
            } 

            return true
        }

        function step_is_finished(step){
            if(step.status == 'Завершен'){
                return true
            }

            return false
        }

        function game_is_active(){

            if(game.value.status == 'Активна'){
                return true
            }

            return false
        }
        
        const max_step = computed(() => Math.max(...game.value.steps.map((o) => o.step_number)))
        function is_last_step(step_number){
            if(max_step.value == step_number){
                return true
            }
            return false
        }

        function get_last_step(){
            
            for(let step of game.value.steps){
                if(max_step.value == step.step_number){
                    return step
                }
            }

        }

        async function delete_step(object){
            await store.dispatch('delete_steps', object.id)
        }

        //обьект для компонента
        const pop_up  = ref({
            id: 0,
            name: '',
            desc: '',
            ok: null,
            close: () => pop_up.value.active = false,
            active: false,
        })

        //Функция для иницыализации и открытия компонента
        function open_pop_up_for_delete(object) {

            pop_up.value.id     = object.id
            pop_up.value.name   = lang.value.trans_435
            pop_up.value.desc   = `${object.step_number} ${lang.value.trans_444}`

            pop_up.value.ok     = async () => {
                await delete_step(object); await store.dispatch('get_game', route.params.id); pop_up.value.active = false
            }

            pop_up.value.active = true

        }


        async function add_steps(){

            store.dispatch('global_block_window', true)

            await store.dispatch('add_steps', {
                game_id: parseInt(route.params.id, 10),
                step_number: game.value.steps.length ? Math.max(...game.value.steps.map(o => o.step_number)) + 1 : 1,
            })

   
            await store.dispatch('get_game', route.params.id)

            store.dispatch('global_block_window', false)

        }


        return { 
            get_last_step,
            lang, 
            pop_up, 
            open_pop_up_for_delete,

            game,

            tact_sales_is_finished, 
            tact_purchacha_is_finished, 
            step_is_finished, 
            clean_focus, 
            test, 

            delete_step, is_last_step, 

            game_is_active,

            add_steps,
        }

    }
}

</script>
