
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {

    current_step_historys: [],

    game_historys: [],

  },

  getters,
  mutations,
  actions,
  modules: {},

}


