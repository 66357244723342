<template>
    <div class="wrap">
        <div class="pagination">
            <div v-for="col in cols" :key="col.id" :class="[`name`, { active: col.active }]"></div>
        </div>
    </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

export default {

	setup(){

        const store = useStore()
        const game  = computed(() => store.getters.game)
        const cols  = computed(() => {
            return game.value.tabs ? game.value.tabs.filter((o) => o.active)[0].cols : []
        })

		return {
            cols,
		}

	}
}
</script>
