<template>
    <div>
        <div v-for="object in histories" :key="object.id" :class="['desc', { active: game.team.id == object.id }, {'red': is_bankruptcy(object) }, get_class_name(colName, object)]">
            {{ get_params(colName, object) }}
        </div>
    </div>
</template>


<script>

import { computed } from 'vue'
import { useStore } from 'vuex'
import { format_number } from "@/api/funcs";

export default {

    props: {
        colName: {
            type: String,
            default: () => ''
        }
    },

	setup(){

        const store         = useStore()
        const game          = computed(() => store.getters.game)
        const histories     = computed(() => store.getters.tab_historys)

        function is_bankruptcy(object){
            return object.bankruptcy
        }


        function get_class_name(name, object){

            let class_name = ''

            switch(name){

                case "Заявка - количество сырья":

                    if(object.purchase_count != 0){
                        if(object.purchase_count == object.program_materials_computed){
                            class_name = 'green'
                        }else{
                            class_name = 'yellow'
                        }
                    }

                    return class_name

                case "Итого произведено стандартной продукции":

                    if((object.prod_ordinary + object.prod_auto) > 0){
                        class_name = 'green'
                    }

                    return class_name

                case "Итого произведено премиум продукции":

                    if((object.prod_premium_ordinary + object.prod_premium_auto) > 0){
                        class_name = 'green'
                    }

                    return class_name

                case "Продано стандартной продукции":

                    if(object.sales_count != 0){
                        if(object.sales_count == object.program_production_computed){
                            class_name = 'green'
                        }else{
                            class_name = 'yellow'
                        }
                    }

                    return class_name

                case "Продано премиум продукции":

                    if(object.sales_count_premium != 0){
                        if(object.sales_count_premium == object.program_production_premium_computed){
                            class_name = 'green'
                        }else{
                            class_name = 'yellow'
                        }
                    }

                    return class_name



                default:

                    return class_name

            }
        }

        function get_params(name, object){

            switch(name){

                // Начало хода 
                case "Счёт":
                    return format_number(object.money)
                    
                case "Кредит":
                    return format_number(object.credit + object.fast_credit)

                case "Запасы сырья":
                    return format_number(object.materials)
 
                case "Стандартная продукция":
                    return format_number(object.products)
                
                case "Премиум продукция":
                    return format_number(object.premium_products)

                case "Обычные фабрики":
                    return format_number(object.factorys)

                case "Автоматические фабрики":
                    return format_number(object.auto_factorys)

                case "Рейтинг компании":
                    return format_number(object.company_value) + ` (${object.rating})`

                //Снабжение
                case "Заявка - количество сырья":
                    return format_number(object.program_materials_computed) + ` (${format_number(object.purchase_count)})`

                case "Назначенная цена":
                    return format_number(object.purchase_price)

                case "Средств израсходовано":
                    return format_number(object.purchase_price * object.program_materials_computed)

                case "Сырья доступно на складе":
                    return format_number(object.materials)

                case "Счёт после снабжения":
                    return format_number(object.money)

                //производство
                case "Стандарт на обычных фабриках":
                    return format_number(object.prod_ordinary)

                case "Стандарт на автоматических":
                    return format_number(object.prod_auto)

                case "Премиум на обычных фабриках":
                    return format_number(object.prod_premium_ordinary)

                case "Премиум на автоматических":
                    return format_number(object.prod_premium_auto)

                case "Итого произведено стандартной продукции":
                    return format_number(object.prod_ordinary + object.prod_auto)

                case "Итого произведено премиум продукции":
                    return format_number(object.prod_premium_ordinary + object.prod_premium_auto)

                case "Счёт после производства":
                    return format_number(object.money)

                //Продажи
                case "Заявка - цена стандартной продукции":
                    return format_number(object.sales_price)

                case "Продано стандартной продукции":
                    return format_number(object.program_production_computed) + ` (${format_number(object.sales_count)})`

                case "Заявка - цена премиум продукции":
                    return format_number(object.sales_price_premium)

                case "Продано премиум продукции":
                    return format_number(object.program_production_premium_computed) + ` (${format_number(object.sales_count_premium)})`

                case "Общая выручка":
                    return format_number(object.program_production_premium_computed * object.sales_price_premium + object.sales_price * object.program_production_computed)

                case "Остатки станд. продукции на складе":
                    return format_number(object.products)

                case "Остатки премиум продукции на складе":
                    return format_number(object.premium_products)

                case "Счёт после продаж":
                    return format_number(object.money)

                //Инвестиции
                case "Построено обычных фабрик":
                    return format_number(object.build_ordinary)

                case "Модернизировано до автоматических":
                    return format_number(object.build_auto)

                case "Продано обычных фабрик":
                    return format_number(object.sell_ordinary)

                case "Продано автоматических фабрик":
                    return format_number(object.sell_auto)

                /*
                    Сальдо денежных потоков. Рассчитывается как:

                        - расходы на строительство обычных фабрик

                        - расходы на модернизацию

                        + полученные денежные средства от продажи обычных фабрик

                        + полученные денежные средства от продажи автоматических фабрик
                */ 

                case "Сальдо денежных потоков":

                    //+ money_liq_ord * liq_ord_fact + money_liq_auto * liq_auto_fact - construction_fact_ord * game.constr_cost_ord - constr_cost_auto * construction_fact_auto

                    return format_number(
                        game.value.game.money_liq_ord * object.liq_ord_fact + 
                        game.value.game.money_liq_auto * object.liq_auto_fact - 
                        object.construction_fact_ord * game.value.game.constr_cost_ord - 
                        game.value.game.constr_cost_auto * object.construction_fact_auto
                    )

                case "Остаток средств":
                    return format_number(object.money)


                //Кредит
                case "Привлеченно":
                    return format_number(object.loan_take)

                case "Возвращенно":
                    return format_number(object.loan_repay)

                case "Итого долгосрочный кредит":
                    return format_number(object.credit)

                case "Итого экстренный кредит":
                    return format_number(object.fast_credit)

                default:
                    return 0

            }


        }



		return {
            game,
            get_params,
            get_class_name,
            is_bankruptcy,
            histories,
		}

	}
}
</script>