


//если пользователь авторизован, то устанавливаем ему нужный язык
export const set_template = (state, id_or_name) => {

    let flag = true
    for(let template of state.templates){
        
        if(typeof id_or_name == 'number'){
            if(template.id == id_or_name){
                state.template = template; flag = false; break
            }
        }else{
           if(template.name == id_or_name){
                state.template = template; flag = false; break
            } 
        }
        
    }

    //если не нашлось нужного языка, то выставляем язык по дефолту
    if(flag){
        for(let template of state.templates){
            if(template.default){
                state.template = template
            }
        }
    }

}


export const get_template = (state, template) => {
    state.template = template
}

export const get_templates = (state, templates) => {
    state.templates = templates
}
