
export const tacts          = state => state.tacts
export const tact           = state => state.tact





export const tacts_finished = state => state.tacts.filter((o) => o.status == "Завершен")
export const last_tact      = getters => {

    if(getters.tacts_finished == undefined){
        return {}
    }

    return getters.tacts_finished.length > 0 ? getters.tacts_finished[getters.tacts_finished.length-1] : {}

}
