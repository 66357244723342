
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {

    steps: [],

    step: {
        game_id: 0,
        step_number: 1,
        raw_market: 0,
        raw_price: 0,
        production_market: 0,
        production_price: 0,
        count_premium_products_market: 0,
        max_premium_products_price: 0,
        tacts: [],
        
    },

    selected_step: {
      id: 0,
      tacts: [],
    },

  },

  getters,
  mutations,
  actions,
  modules: {},

}


