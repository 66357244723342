import { format_number } from "@/api/funcs";


export function get_params(name, o){

    const paramsMap = {

        "Сырья доступно на складе":                         o.materials,
        "Счёт после снабжения":                             o.money,
        "Счёт после продаж":                                o.money,
        "Остатки премиум продукции на складе":              o.premium_products,
        "Остатки станд. продукции на складе":               o.products,
        "Заявка - цена премиум продукции":                  o.sales_price_premium,
        "Заявка - цена стандартной продукции":              o.sales_price,
        "Счёт после производства":                          o.money,
        "Премиум на автоматических":                        o.prod_premium_auto,
        "Премиум на обычных фабриках":                      o.prod_premium_ordinary,
        "Стандарт на автоматических":                       o.prod_auto,
        "Стандарт на обычных фабриках":                     o.prod_ordinary,
        "Построено обычных фабрик":                         o.build_ordinary,
        "Модернизировано до автоматических":                o.build_auto,
        "Продано обычных фабрик":                           o.sell_ordinary,
        "Продано автоматических фабрик":                    o.sell_auto,
        "Остаток средств":                                  o.money,
        "Привлеченно":                                      o.loan_take,
        "Возвращенно":                                      o.loan_repay,
        "Итого долгосрочный кредит":                        o.credit,
        "Итого экстренный кредит":                          o.fast_credit,
        "Счёт":                                             o.money,
        "Кредит":                                           o.credit + o.fast_credit,
        "Запасы сырья":                                     o.materials,
        "Стандартная продукция":                            o.products,
        "Премиум продукция":                                o.premium_products,
        "Обычные фабрики":                                  o.factorys,
        "Автоматические фабрики":                           o.auto_factorys,
        "Стоимость НМА":                                    o.company_value_bonus,
        "Потрачено на сырьё, ₽":                            o.total_team_raw_expenses,
        "Потрачено на производство, ₽":                     o.total_team_prod_expenses,
        "Потрачено на содержание фабрик, ₽":                o.total_team_factory_expenses,
        "Логистические издержки, ₽":                        o.total_team_logistics_expenses,
        "Оплата кредита, ₽":                                o.total_team_loan_expenses,
        "Итого расходы, ₽":                                 o.total_team_expenses,
        "Итого выручка, ₽":                                 o.total_team_revenue,
        "Выручка по стандартной продукции, ₽ (доля от общей выручки, %)": o.total_team_revenue_standard,
        "Выручка по премиум-продукции, ₽ (доля от общей выручки, %)": o.total_team_revenue_premium,
        "Итого прибыль, ₽":                                 o.total_team_profit,
        "Рентабельность, %":                                o.total_team_profitability,
        "Всего закуплено сырья, ед.":                       o.total_team_raw_purchased,
        "Произведено стандартной продукции, ед.":           o.total_team_prod_standard,
        "Произведено премиум продукции, ед.":               o.total_team_prod_premium,
        "Продано стандартной продукции, ед.":               o.total_team_sold_standard,
        "Продано премиум продукции, ед.":                   o.total_team_sold_premium,
        
        "Средняя затоваренность, %":                        o.average_team_glut,
        "Средняя загрузка фабрик, %":                       o.average_team_workload,

        "Всего построено фабрик, ед.":                      o.total_team_factory_constructed,
        "Всего модернизировано фабрик, ед.":                o.total_team_factory_upgraded,
        "Всего продано фабрик, ед.":                        o.total_team_factory_sold,
        "Вложено средств в строительство, ₽":               o.total_team_constr_invested,
        "Вложено средств в модернизацию, ₽":                o.total_team_upgrade_invested,
        "Всего инвестировано, ₽":                           o.total_team_invested,
        "Доля всего рынка, %":                              o.total_team_market_share,
        "Доля рынка стандартной продукции, %":              o.total_team_market_share_standard,
        "Доля рынка премиум-продукции, %":                  o.total_team_market_share_premium,
        "Средняя цена стандартной продукции, ₽":            o.average_team_standard_price,
        "Средняя цена премиум-продукции, ₽":                o.average_team_premium_price,
        "Назначенная цена":                                 o.purchase_price,

    };

    // Обработка случая отдельно
    if (name === "Рейтинг компании") {
        return format_number(o.company_value) + ` (${o.rating})`;
    }

    if (name === "Заявка - количество сырья") {
        return format_number(o.program_materials_computed) + ` (${format_number(o.purchase_count)})`
    }

    if (name === "Сальдо денежных потоков") {
        return 0
    }

    if (name === "Продано стандартной продукции") {
        return format_number(o.program_production_computed) + ` (${format_number(o.sales_count)})`
    }

    if (name === "Продано премиум продукции") {
        return format_number(o.program_production_premium_computed) + ` (${format_number(o.sales_count_premium)})`
    }

    if (name === "Общая выручка") {
        return format_number(o.program_production_premium_computed * o.sales_price_premium + o.sales_price * o.program_production_computed)
    }

    if (name === "Итого произведено стандартной продукции") {
        return format_number(o.prod_ordinary + o.prod_auto)
    }

    if (name === "Итого произведено премиум продукции") {
        return format_number(o.prod_premium_ordinary + o.prod_premium_auto)
    }

    if (name === "Средств израсходовано") {
        return format_number(o.purchase_price * o.program_materials_computed)
    }



    // Если имя параметра существует в объекте, возвращаем отформатированное значение
    if (Object.prototype.hasOwnProperty.call(paramsMap, name)) {
        return format_number(paramsMap[name]);
    }

    // Если имя параметра отсутствует, возбуждаем исключение
    throw new Error(`Parameter ${name} not found`);
}
