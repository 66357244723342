<template>
    <div class="left">
        <div class="wrap">

            <div class="name"  @click="qsort({name: 'Компания'})">
                {{ alang.trans_17 }}
            </div>

            <div>
                <div v-for="history in histories" :key="history.id" :class="['desc', { active: team.id == history.team_id }, {'red': history.bankruptcy },]">
                    {{ history.name }}
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

export default {

	setup(){

        const store     = useStore()
        const alang     = computed(() => store.getters.lang)
        const team      = computed(() => store.getters.team)
        const histories = computed(() => store.getters.tab_historys)

        function qsort(param){
            store.dispatch('qsort_tab_historys', param.name)
        }

		return {
            alang,
            team,
            qsort,
            histories,
		}

	}
}
</script>