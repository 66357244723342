<template>
    <div class="pop_up">

        <div class="back" @click="close"></div>

        <div class="wrap">

            <div class="name">Создать пользователя</div>

            <div class="form">

                <input-text v-model:model="form.name" :name="lang.trans_6" :placeholder="lang.trans_6" :max="40" :event="add" :is-call="false"></input-text>

                <input-password v-model:model="form.passw" :name="lang.trans_7" :placeholder="lang.trans_7" :max="40" :event="add"></input-password>

                <!-- групы -->
                <InputSelectMultiple name="Доступные роли" :list="roles" :selected="form.roles" :append="append_roles" :remove="remove_roles" />

                <InputSelect v-if="games" v-model:model="form.game_id" name="Игра" :list="games" />

                <InputSelect v-if="teams" v-model:model="form.team_id" name="Команда" :list="teams" />

            </div>

            <div class="wrap">
                <div class="btn ok" @click="add" @keyup.enter="add">{{ lang.trans_226 }}</div>
                <div class="btn close" @click="close">{{ lang.trans_368 }}</div>
            </div>

        </div>

    </div>
</template>

<script setup>

import { computed, onMounted, ref, watch, toRefs } from 'vue'
import { useStore } from 'vuex'
import { requests } from "@/api/funcs";

import InputText from '@/components/form/InputText.vue'
import InputPassword from '@/components/form/InputPassword.vue'
import InputSelectMultiple from '@/components/form/InputSelectMultiple.vue'
import InputSelect from '@/components/form/InputSelect.vue'



const props = defineProps({
    close: {
        type: Function,
        default: () => { }
    },
})

const { close } = toRefs(props);



const store = useStore()
const domain = computed(() => store.getters.domain)
const cookie = computed(() => store.getters.cookie)
const lang = computed(() => store.getters.lang)

const roles = ref([])
const games = ref(null)
const teams = ref(null)


const form = ref({
    name: '',
    passw: '',
    game_id: 0,
    team_id: 0,
    roles: [],
})


onMounted(async () => {

    {
        //roles all
        const [err, code, body] = await requests(`${domain.value}/api/roles`, 'GET', cookie.value)
        if (err) {
            store.dispatch('update_err_by_code', code); return
        }

        roles.value = body

    }

    {
        const [err, code, body] = await requests(`${domain.value}/api/games/operator`, 'GET', cookie.value)
        if (err) {
            store.dispatch('update_err_by_code', code); return
        }

        games.value = body
    }

})

watch(() => form.value.game_id, async (id) => {

    const [err, code, body] = await requests(`${domain.value}/api/teams/games/${id}`, 'GET', cookie.value)

    if (err) {
        await store.dispatch('update_err_by_code', code); return
    }

    teams.value = body

})



function remove_roles(id) {
    form.value.roles = form.value.roles.filter(o => o.id != id)
}

function append_roles(id) {

    for (let selected_group of form.value.roles) {
        if (selected_group.id == id) {
            return
        }
    }

    for (let group of roles.value) {
        if (group.id == id) {
            form.value.roles.push(group)
        }
    }

}

async function add() {

    form.value.roles = form.value.roles.map(r => r.id)

    const response = await fetch(`${domain.value}/api/users/games/${form.value.game_id}/teams/${form.value.team_id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(form.value)
    })

    if (!response.ok) {
        const body = await response.json(); store.dispatch('update_err_by_code', body.code); return
    }

    await close.value()
}



</script>

<style scoped>
.pop_up {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop_up>.back {
    background: black;
    opacity: .5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.pop_up>.wrap {
    background: white;
    z-index: 1;
    padding: 1.5em;
    border-radius: 15px;
    max-width: 650px;
    width: 100%;
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
}

@media (min-width: 500px) {
    .pop_up>.wrap {
        font-size: 18px;
    }
}

.pop_up>.wrap>.name {
    font-weight: 500;
    font-size: 1.925em;
    letter-spacing: 0.02em;
    color: #0944AA;
    margin-bottom: 0.5em;
}

@media (min-width: 500px) {
    .pop_up>.wrap>.wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        margin-top: 1em;
    }
}

.pop_up>.wrap>.wrap>.btn {
    border-radius: 4px;
    color: white;
    padding: .9em;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: .9em;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 500px) {
    .pop_up>.wrap>.wrap>.btn {
        width: 100%;
        margin: 0 auto;
    }
}

.pop_up>.wrap>.wrap>.btn.ok {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

.pop_up>.wrap>.wrap>.btn.close {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
}
</style>