<template>
    <div class="results">

        <top></top>

        <div class="active-tab">
            {{ wrap_tab(active_tab) }}
        </div>

        <bottom></bottom>

        <pagination></pagination>

    </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

import Top from "@/components/history/Top.vue";
import Bottom from "@/components/history/bottom/Bottom.vue";
import Pagination from "@/components/history/Pagination.vue";
import { get_lang_name } from "@/api/funcs";

export default {
    components: {
        "top": Top,
        "bottom": Bottom,
        "pagination": Pagination,
    },

	setup() {

        const store         = useStore()

        const active_tab    = computed(() => store.getters.active_tab)

        // Создаем реактивное свойство для хранения ширины окна
        const windowWidth   = ref(window.innerWidth)

        // Функция для обновления ширины окна
        const handleResize = () => {

            windowWidth.value = window.innerWidth

            // Вы можете использовать значение windowWidth.value для обновления состояния в store
            store.dispatch('update_pagination_visible', windowWidth.value)
        }

        onMounted(() => {
            
            // Добавляем обработчик события при монтировании компонента
            window.addEventListener('resize', handleResize)

            // Обновляем визуальную часть колонок при монтировании
            store.dispatch('update_visible_cols')
        })

        onUnmounted(() => {
            // Не забываем удалить обработчик события при размонтировании компонента
            window.removeEventListener('resize', handleResize)
        })

        function wrap_tab(tab){
            return get_lang_name(active_tab.value, tab.alt_name)
        }

		return {
            active_tab,
            wrap_tab
		}
	}
}
</script>
