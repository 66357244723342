

import { requests } from "@/api/funcs";


export const clean_operations = async ({ commit }) => {
    commit('clean_operations')
}


export const append_operations = async ({ commit }, operation) => {
    commit('append_operations', operation)
}

export const update_operations = async ({ getters, dispatch }, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/operations`, 'PUT', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const add_operations = async ({ getters, dispatch, rootState }, object) => {
    
    const [ err, code, body ] =  await requests(`${getters.domain}/api/operations`, 'POST', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    dispatch('alerts_add', body)

    dispatch('append_operations', body)

    if(rootState.assistant.assistant){

        setTimeout(() => {
            document.getElementById("click-bottom").scrollIntoView({ behavior: 'smooth', block: 'end' })
        }, 150)
        
    }
    
}

export const delete_operations = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/operations/${id}`, 'DELETE', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const get_operations = async ({ commit, getters, dispatch }, page = 1) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/operations/page/${page}`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }
    
    commit('get_operations', body)
}