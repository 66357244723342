import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import Vue3TouchEvents from "vue3-touch-events"
import VueApexCharts from "vue3-apexcharts"
import VueDOMPurifyHTML from 'vue-dompurify-html'


import "@/assets/sass/cabinet.sass"



createApp(App)
    .use(router)
    .use(store)
    .use(VueApexCharts)
    .use(Vue3TouchEvents)
    .use(VueDOMPurifyHTML)
    .mount('#app')
