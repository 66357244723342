<template>
    <div class="block">

        <div class="top">

            <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.9201 6.02269H0.0800781C0.125741 5.83978 0.205844 5.65687 0.297168 5.47422L2.62853 1.24565C3.05149 0.468637 3.86269 0 4.74282 0H27.2571C28.1372 0 28.9484 0.468624 29.3714 1.24565L31.7028 5.47422C31.7943 5.65687 31.8742 5.83978 31.9198 6.02269H31.9201Z" fill="currentColor"/>
                <path d="M29.125 15.1572C28.7536 15.2304 28.372 15.2681 27.9937 15.2681C26.4383 15.2681 25.0439 14.6271 24.0199 13.6087C22.9868 14.6327 21.5673 15.2681 20.0005 15.2681C18.4428 15.2681 17.0462 14.6258 16.0212 13.6077C14.988 14.6326 13.5673 15.2681 12.0005 15.2681C10.4428 15.2681 9.04744 14.6271 8.0222 13.6087C6.9903 14.6327 5.57191 15.2681 4.00738 15.2681C3.62906 15.2681 3.24741 15.2304 2.87013 15.1572C2.73187 15.1309 2.60381 15.0771 2.46912 15.0406V27.8967C2.46912 29.234 3.54334 30.3082 4.88061 30.3082H7.21661V19.2212C7.21661 18.684 7.65207 18.2487 8.18908 18.2487H14.0679C14.6052 18.2487 15.0404 18.6842 15.0404 19.2212V30.3082H27.1207C28.458 30.3082 29.5322 29.234 29.5322 27.8967V15.0393C29.3963 15.077 29.2659 15.1296 29.1253 15.1569L29.125 15.1572ZM24.7845 22.6406C24.7845 23.1778 24.349 23.6131 23.812 23.6131H20.3913C19.8541 23.6131 19.4188 23.1776 19.4188 22.6406V19.2199C19.4188 18.6827 19.8543 18.2475 20.3913 18.2475H23.812C24.3492 18.2475 24.7845 18.6829 24.7845 19.2199V22.6406Z" fill="currentColor"/>
                <path d="M31.9201 7.73703H0.0801045C0.0525538 7.73703 0.0275533 7.72887 0 7.72785V9.40563C0 11.337 1.30279 13.1084 3.2 13.4743C5.75993 13.9771 8 12.0227 8 9.55413C8 11.7597 9.79442 13.5541 12 13.5541C14.2056 13.5541 16 11.7597 16 9.55413C16 11.7597 17.7944 13.5541 20 13.5541C22.2056 13.5541 24 11.7597 24 9.55413C24 12.0228 26.2401 13.9771 28.8 13.474C30.6972 13.1082 32 11.3367 32 9.40537V7.72778C31.9724 7.7288 31.9474 7.73697 31.9199 7.73697L31.9201 7.73703Z" fill="currentColor"/>
            </svg>

            <div class="name">
                {{ lang.trans_98 }}
            </div>

        </div>

        <div class="center">

            <img :src="require(`@/assets/images/rules/${template.alt_name}/sales.svg`)">

            <ul>
                <li>
                    {{ lang.trans_473 }}
                </li>

                <li>
                    {{ lang.trans_474 }}
                </li>

                <li>
                    {{ lang.trans_475 }}
                </li>

                <li>
                    {{ lang.trans_476 }}
                </li>

                <li>
                    {{ lang.trans_477 }}
                </li>
            </ul>

        </div>

        <div class="bottom">
            {{ lang.trans_478 }}
        </div>

    </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

    setup(){

        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const template  = computed(() => store.getters.template)

        return {
            close,
            lang,
            template,
        }
    }
}
</script>