

export const assistant_update = async ({ commit }, bool) => {
    commit('assistant_update', bool)
}

export const alerts_del = async ({ commit }) => {
    commit('alerts_del')
}

export const alerts_add = async ({ commit, dispatch }, alert) => {
    commit('alerts_add', alert); setTimeout(() => dispatch('alerts_del'), 3000)
}


