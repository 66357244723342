<template>
    <div class="feedback">
        <div class="back" @click="back"></div>
        <div class="wrap">

            <div class="top">
                <div class="name">{{ lang.trans_360 }}</div>
            </div>

            <div class="cnt">

                <div class="name">
                    {{ lang.trans_363 }}
                </div>

                <div class="wrap">
                    <label for="input-179">{{ lang.trans_364 }} *</label>
                    <textarea id="input-179" v-model="feedback.message" :placeholder="lang.trans_369" rows="5"></textarea>
                </div>

                <form-image></form-image>

                <div class="desc">
                    {{ lang.trans_365 }}
                </div>

                <div class="wrap">
                    <label for="input-180">{{ lang.trans_366 }}</label>
                    <input id="input-180" v-model="feedback.contact" :placeholder="lang.trans_370" />
                </div>

            </div>

            <div class="btm">
                <div :class="['send', {active: feedback.message.length > 0}]" @click="create">{{ lang.trans_367 }}</div>
                <div class="cancel" @click="back">{{ lang.trans_368 }}</div>
            </div>

        </div>

    </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

import Image from "@/components/form/Image.vue";

export default {

    components: {
        "form-image": Image,
    },

    setup(){


        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const url       = computed(() => store.getters.url)
        const feedback 	= computed(() => store.getters.feedback)

        async function back(){

            if(url.value != ''){
                
                await store.dispatch('delete_image_server', url.value)

                feedback.value.img = ``

            }

            await store.dispatch('clean_feedbacks')

            update_feedback(false)

        }

        async function create(){

            if(feedback.value.contact.length > 255){
                await store.dispatch('update_err_by_code', 355); return
            }

            feedback.value.img = url


            await store.dispatch('add_feedbacks')

            await store.dispatch('update_url', '')

            await store.dispatch('clean_feedbacks')

            update_feedback(false)

        }


        function update_feedback(bool){
            store.dispatch('update_feedback_pop_up', bool)
        }

        return {
            lang,
            back,
            create,
            feedback,
        }
    }
}

</script>