

export const update_tabs = (state, tabs) => {
    state.tabs = tabs
}

export const update_pop_up = (state, bool) => {
    state.col_pop_up = bool
}








//сортировка истории таба при клике
export const qsort_tab_historys = (state, name) => {

    const sortFunctions = {
        "Компания":                                 (a, b) => a.name.localeCompare(b.name),
        "Счёт":                                     (a, b) => b.money                               - a.money,
        "Кредит":                                   (a, b) => b.credit                              - a.credit,
        "Запасы сырья":                             (a, b) => b.materials                           - a.materials,
        "Стандартная продукция":                    (a, b) => b.products                            - a.products,
        "Премиум продукция":                        (a, b) => b.premium_products                    - a.premium_products,
        "Обычные фабрики":                          (a, b) => b.factorys                            - a.factorys,
        "Автоматические фабрики":                   (a, b) => b.auto_factorys                       - a.auto_factorys,
        "Рейтинг компании":                         (a, b) => b.company_value                       - a.company_value,
        "Потрачено на сырьё, ₽":                    (a, b) => b.total_team_raw_expenses             - a.total_team_raw_expenses,
        "Потрачено на производство, ₽":             (a, b) => b.total_team_prod_expenses            - a.total_team_prod_expenses,
        "Потрачено на содержание фабрик, ₽":        (a, b) => b.total_team_factory_expenses         - a.total_team_factory_expenses,
        "Логистические издержки, ₽":                (a, b) => b.total_team_logistics_expenses       - a.total_team_logistics_expenses,
        "Оплата кредита, ₽":                        (a, b) => b.total_team_loan_expenses            - a.total_team_loan_expenses,
        "Итого расходы, ₽":                         (a, b) => b.total_team_expenses                 - a.total_team_expenses,
        "Итого выручка, ₽":                         (a, b) => b.total_team_revenue                  - a.total_team_revenue,
        "Выручка по стандартной продукции, ₽":      (a, b) => b.total_team_revenue_standard         - a.total_team_revenue_standard,
        "Выручка по премиум-продукции, ₽":          (a, b) => b.total_team_revenue_premium          - a.total_team_revenue_premium,
        "Итого прибыль, ₽":                         (a, b) => b.total_team_profit                   - a.total_team_profit,
        "Рентабельность, %":                        (a, b) => b.total_team_profitability            - a.total_team_profitability,
        "Всего закуплено сырья, ед.":               (a, b) => b.total_team_raw_purchased            - a.total_team_raw_purchased,
        "Произведено стандартной продукции, ед.":   (a, b) => b.total_team_prod_standard            - a.total_team_prod_standard,
        "Произведено премиум продукции, ед.":       (a, b) => b.total_team_prod_premium             - a.total_team_prod_premium,
        "Продано стандартной продукции, ед.":       (a, b) => b.total_team_sold_standard            - a.total_team_sold_standard,
        "Продано премиум продукции, ед.":           (a, b) => b.total_team_sold_premium             - a.total_team_sold_premium,
        "Средняя затоваренность, %":                (a, b) => b.team_glut                           - a.team_glut,
        "Средняя загрузка фабрик, %":               (a, b) => b.total_team_workload                 - a.total_team_workload,
        "Всего построено фабрик, ед.":              (a, b) => b.total_team_factory_constructed      - a.total_team_factory_constructed,
        "Всего модернизировано фабрик, ед.":        (a, b) => b.total_team_factory_upgraded         - a.total_team_factory_upgraded,
        "Всего продано фабрик, ед.":                (a, b) => b.total_team_factory_sold             - a.total_team_factory_sold,
        "Вложено средств в строительство, ₽":       (a, b) => b.total_team_constr_invested          - a.total_team_constr_invested,
        "Вложено средств в модернизацию, ₽":        (a, b) => b.total_team_upgrade_invested         - a.total_team_upgrade_invested,
        "Всего инвестировано, ₽":                   (a, b) => b.total_team_invested                 - a.total_team_invested,
        "Доля всего рынка, %":                      (a, b) => b.total_team_market_share             - a.total_team_market_share,
        "Доля рынка стандартной продукции, %":      (a, b) => b.total_team_market_share_standard    - a.total_team_market_share_standard,
        "Доля рынка премиум-продукции, %":          (a, b) => b.total_team_market_share_premium     - a.total_team_market_share_premium,
        "Средняя цена стандартной продукции, ₽":    (a, b) => b.average_team_standard_price         - a.average_team_standard_price,
        "Средняя цена премиум-продукции, ₽":        (a, b) => b.total_team_premium_price            - a.total_team_premium_price,

        // Снабжение
        "Заявка - количество сырья":                (a, b) => b.program_materials_computed - a.program_materials_computed,
        "Назначенная цена":                         (a, b) => b.purchase_price - a.purchase_price,
        "Средств израсходовано":                    (a, b) => (b.purchase_price * b.program_materials_computed) - (a.purchase_price * a.program_materials_computed),
        "Сырья доступно на складе":                 (a, b) => b.materials - a.materials,
        "Счёт после снабжения":                     (a, b) => b.money - a.money,

        // Производство
        "Стандарт на обычных фабриках":             (a, b) => b.prod_ordinary - a.prod_ordinary,
        "Стандарт на автоматических":               (a, b) => b.prod_auto - a.prod_auto,
        "Премиум на обычных фабриках":              (a, b) => b.prod_premium_ordinary - a.prod_premium_ordinary,
        "Премиум на автоматических":                (a, b) => b.prod_premium_auto - a.prod_premium_auto,
        "Итого произведено стандартной продукции":  (a, b) => (b.prod_ordinary + b.prod_auto) - (a.prod_ordinary + a.prod_auto),
        "Итого произведено премиум продукции":      (a, b) => (b.prod_premium_ordinary + b.prod_premium_auto) - (a.prod_premium_ordinary + a.prod_premium_auto),
        "Счёт после производства":                  (a, b) => b.money - a.money,

        // Продажи
        "Заявка - цена стандартной продукции":      (a, b) => b.sales_price - a.sales_price,
        "Продано стандартной продукции":            (a, b) => b.program_production_computed - a.program_production_computed,
        "Заявка - цена премиум продукции":          (a, b) => b.sales_price_premium - a.sales_price_premium,
        "Продано премиум продукции":                (a, b) => b.program_production_premium_computed - a.program_production_premium_computed,
        "Общая выручка":                            (a, b) => (b.program_production_premium_computed * b.sales_price_premium + b.sales_price * b.program_production_computed) - (a.program_production_premium_computed * a.sales_price_premium + a.sales_price * a.program_production_computed),
        "Остатки станд. продукции на складе":       (a, b) => b.products - a.products,
        "Остатки премиум продукции на складе":      (a, b) => b.premium_products - a.premium_products,
        "Счёт после продаж":                        (a, b) => b.money - a.money,

        // Инвестиции
        "Построено обычных фабрик":                 (a, b) => b.build_ordinary - a.build_ordinary,
        "Модернизировано до автоматических":        (a, b) => b.build_auto - a.build_auto,
        "Продано обычных фабрик":                   (a, b) => b.sell_ordinary - a.sell_ordinary,
        "Продано автоматических фабрик":            (a, b) => b.sell_auto - a.sell_auto,
        "Сальдо денежных потоков":                  (a, b) => (b.money_liq_ord * b.liq_ord_fact + b.money_liq_auto * b.liq_auto_fact - b.construction_fact_ord * b.constr_cost_ord - b.constr_cost_auto * b.construction_fact_auto) - (a.money_liq_ord * a.liq_ord_fact + a.money_liq_auto * a.liq_auto_fact - a.construction_fact_ord * a.constr_cost_ord - a.constr_cost_auto * a.construction_fact_auto),
        "Остаток средств":                          (a, b) => b.money - a.money,

        // Кредит
        "Привлеченно":                              (a, b) => b.loan_take - a.loan_take,
        "Возвращенно":                              (a, b) => (b.loan_repay + b.loan_repay) - (a.loan_repay + a.loan_repay),
        "Итого долгосрочный кредит":                (a, b) => b.credit - a.credit,
        "Итого экстренный кредит":                  (a, b) => b.fast_credit - a.fast_credit,
    }


    const sortFunction = sortFunctions[name]

    if (!sortFunction) {
        console.error(`No sort function found for key: ${name}`)
        return
    }


    if (Object.prototype.hasOwnProperty.call(sortFunctions, name)) {
        state.tab_historys.sort(sortFunctions[name])
    }

}

//обновляем историю таба
export const update_tab_historys = (state, objects) => {
    state.tab_historys = objects
}

//обновляем визуальную часть колонок
export const update_visible_cols = (state) => {

    for(let col of state.cols){
        if(col.active){
            col.active = false
        }
    }

    for(let col of state.cols.slice(state.pagination.start, state.pagination.stop)){
        col.active = true
    }


}

//обновляем колонки
export const update_cols = (state) => {
    state.cols = state.tabs ? state.tabs.filter((o) => o.active)[0].cols : []
}

//обновляем конечное значение пагинации
export const update_stop_pagination = (state, int) => {
    state.pagination.stop = int
}

//обновляем стартовое значение пагинации
export const update_start_pagination = (state, int) => {
    state.pagination.start = int
}

//устанавливаем активный таб
export const select_tab = (state, name) => {
    for (let tab of state.tabs) {

        if(tab.name == name){
            tab.active = true
        }else{
            tab.active = false
        }

    }
}