<template>
    <div class="rules">

        <div class="back" @click="close()"></div>

        <div class="wrap">

            <div class="name" @click="close()">

                <div class="name">
                    {{ lang.trans_460 }}
                </div>

                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg>
            
            </div>

            <div class="desc">

                <div class="wrap">

                    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.6145 11.8102L20.5833 9.9248V4.27376C20.5833 3.49251 20.0989 2.7946 19.3645 2.51855L14.1562 0.56543C13.7343 0.403971 13.2656 0.403971 12.8385 0.56543L7.63017 2.51855C6.89579 2.7946 6.41142 3.49251 6.41142 4.27376V9.9248L1.38017 11.8102C0.651001 12.0863 0.166626 12.7842 0.166626 13.5654V19.2998C0.166626 20.0081 0.567668 20.6592 1.20308 20.9769L6.41142 23.5811C6.93746 23.8467 7.56246 23.8467 8.0885 23.5811L13.5 20.8727L18.9114 23.5811C19.4375 23.8467 20.0625 23.8467 20.5885 23.5811L25.7968 20.9769C26.4323 20.6592 26.8333 20.0081 26.8333 19.2998V13.5654C26.8333 12.7842 26.3489 12.0863 25.6145 11.8102ZM18.8125 9.96647L14.3854 11.6279V8.07585L18.8125 6.14876V9.96647ZM8.18746 4.20085L13.5 2.21126L18.8125 4.20085V4.2321L13.5 6.38835L8.18746 4.2321V4.20085ZM12.5625 19.3623L8.13538 21.5758V17.4561L12.5625 15.4352V19.3623ZM12.5625 13.529L7.24996 15.6852L1.93746 13.529V13.4977L7.24996 11.5081L12.5625 13.4977V13.529ZM25.0625 19.3623L20.6354 21.5758V17.4561L25.0625 15.4352V19.3623ZM25.0625 13.529L19.75 15.6852L14.4375 13.529V13.4977L19.75 11.5081L25.0625 13.4977V13.529Z" fill="currentColor"/>
                    </svg>

                    <div class="name">
                        {{ lang.trans_401001 }}
                    </div>

                </div>

                <div class="wrap center">

                    <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5497 7.02771L10.9586 6.3335L6.57104 8.25081L8.16216 8.94682L12.5497 7.02771Z" fill="currentColor"/>
                        <path d="M25.5043 6.03026L13.5 0.777832L1.5 6.03026L4.35688 7.28003L10.6022 4.54849C10.7143 4.49955 10.8354 4.47428 10.9578 4.47428C11.0801 4.47428 11.2012 4.49955 11.3133 4.54849L15.1266 6.20804C15.2849 6.27735 15.4195 6.39127 15.514 6.53587C15.6086 6.68049 15.6589 6.8495 15.6589 7.02227C15.6589 7.19503 15.6086 7.36405 15.514 7.50865C15.4195 7.65325 15.2849 7.76717 15.1266 7.83648L10.38 9.91737L13.5 11.2827L25.5043 6.03026Z" fill="currentColor"/>
                        <path d="M0.166626 7.38965V23.2633L12.611 29.594V12.8341L9.0555 11.275V15.8083C9.05557 15.9598 9.01689 16.1089 8.94316 16.2412C8.86943 16.3737 8.76307 16.4849 8.63419 16.5647C8.50532 16.6444 8.35821 16.6898 8.20683 16.6967C8.05544 16.7035 7.90482 16.6716 7.76927 16.6038L4.21372 14.8261C4.06597 14.7522 3.94169 14.6386 3.85488 14.4982C3.76805 14.3576 3.72209 14.1957 3.72217 14.0306V8.9452L0.166626 7.38965Z" fill="currentColor"/>
                        <path d="M5.49988 13.4809L7.27765 14.3697V10.5004L5.49988 9.72266V13.4809Z" fill="currentColor"/>
                        <path d="M26.8333 7.38965L14.3888 12.8341V29.6021L26.8333 23.3488V7.38965ZM15.7674 21.3611L19.2777 19.5967C19.382 19.5442 19.4956 19.5127 19.6121 19.5041C19.7285 19.4954 19.8455 19.5099 19.9565 19.5466C20.0673 19.5831 20.17 19.6413 20.2584 19.7175C20.3468 19.7937 20.4194 19.8867 20.4719 19.9909C20.5244 20.0952 20.5558 20.2088 20.5645 20.3254C20.5731 20.4419 20.5587 20.5589 20.5221 20.6697C20.4856 20.7806 20.4274 20.8832 20.3512 20.9716C20.275 21.0601 20.1819 21.1326 20.0778 21.1851L16.5666 22.9496C16.4623 23.0021 16.3486 23.0336 16.2321 23.0423C16.1157 23.0508 15.9987 23.0365 15.8879 22.9998C15.7769 22.9633 15.6744 22.9051 15.5859 22.8289C15.4974 22.7526 15.4249 22.6597 15.3724 22.5553C15.3198 22.4511 15.2883 22.3374 15.2798 22.221C15.2711 22.1045 15.2856 21.9875 15.3221 21.8766C15.3588 21.7658 15.4168 21.6631 15.4931 21.5747C15.5694 21.4863 15.6632 21.4136 15.7674 21.3611ZM20.1275 24.715L16.5657 26.5051C16.4613 26.5576 16.3478 26.5892 16.2313 26.5977C16.1149 26.6064 15.9978 26.592 15.8869 26.5554C15.7761 26.5188 15.6734 26.4606 15.5851 26.3844C15.4966 26.3082 15.424 26.2153 15.3715 26.111C15.3189 26.0066 15.2875 25.8931 15.2788 25.7766C15.2703 25.6602 15.2846 25.543 15.3213 25.4322C15.3578 25.3213 15.416 25.2187 15.4922 25.1302C15.5685 25.0418 15.6614 24.9693 15.7658 24.9168L19.3275 23.1265C19.4318 23.074 19.5453 23.0425 19.6618 23.0338C19.7783 23.0253 19.8953 23.0396 20.0062 23.0763C20.1172 23.1128 20.2197 23.171 20.3082 23.2472C20.3967 23.3235 20.4692 23.4164 20.5217 23.5208C20.5742 23.625 20.6057 23.7387 20.6143 23.8551C20.623 23.9716 20.6085 24.0886 20.572 24.1995C20.5353 24.3103 20.4772 24.413 20.4009 24.5014C20.3247 24.5898 20.2318 24.6625 20.1275 24.715Z" fill="currentColor"/>
                    </svg>

                    <div class="name">
                        {{ lang.trans_461 }}
                    </div>

                </div>

                <div v-if="game.game.variant" class="wrap last">

                    <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.326538 10.3645L13.0741 25.084L8.82703 10.3645H0.326538Z" fill="currentColor"/>
                        <path d="M9.77344 10.3645L14.4937 26.7222L19.2143 10.3645H9.77344Z" fill="currentColor"/>
                        <path d="M4.22172 4.16992L0.277832 9.45461H8.17147L4.22172 4.16992Z" fill="currentColor"/>
                        <path d="M28.7223 9.45437L24.7784 4.16968L20.8347 9.45437H28.7223Z" fill="currentColor"/>
                        <path d="M9.07565 9.14511L13.3594 3.41138H4.79236L9.07565 9.14511Z" fill="currentColor"/>
                        <path d="M24.208 3.41138H15.635L19.9247 9.15107L24.208 3.41138Z" fill="currentColor"/>
                        <path d="M19.0143 9.45444L14.5001 3.41138L9.98596 9.45444H19.0143Z" fill="currentColor"/>
                        <path d="M15.9141 25.0907L28.6676 10.3652H20.1672L15.9141 25.0907Z" fill="currentColor"/>
                        <path d="M3.75742 3.82827L4.3146 2.57826L5.33821 2.24751L4.3146 1.91231L3.75742 0.666748L3.20025 1.91231L2.17664 2.24751L3.20025 2.57826L3.75742 3.82827Z" fill="currentColor"/>
                        <path d="M2.32134 5.73711L2.67326 4.95457L3.31635 4.74804L2.67326 4.53578L2.32134 3.74707L1.96328 4.53578L1.32019 4.74804L1.96328 4.95457L2.32134 5.73711Z" fill="currentColor"/>
                        <path d="M6.8995 3.04167L7.17862 2.42268L7.68205 2.2589L7.17862 2.09511L6.8995 1.47021L6.62038 2.09511L6.1106 2.2589L6.62038 2.42268L6.8995 3.04167Z" fill="currentColor"/>
                    </svg>

                    <div class="name">
                        {{ lang.trans_450 }}
                    </div>

                </div>

            </div>

            <div class="wrap">

                <Procurement/>

                <Production/>

                <Sales/>

                <Costs/>

                <Investment/>

                <Loan/>

            </div>

            <div class="wrap_1">

                <div class="wrap">
                    <div class="top">
                        <div class="left one">
                            S
                        </div>
                        <div class="right">
                            {{ lang.trans_408001 }}
                        </div>
                    </div>

                    <div class="center">

                        <div class="name">
                            {{ lang.trans_408002 }}
                        </div>

                        <div class="list">

                            <div class="line grid">

                                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.97928 8.28134H15.1873C15.4355 8.28134 15.6675 8.16234 15.8164 7.96363C16.9544 6.44441 17.5313 4.76394 17.5313 2.96884V2.448C17.5313 2.01654 17.1814 1.66675 16.7499 1.66675C15.3031 1.66675 13.4159 2.60383 12.5833 3.05977C11.7507 2.60383 9.8635 1.66675 8.41662 1.66675C7.98516 1.66675 7.63538 2.01654 7.63538 2.448V2.96884C7.63538 4.76394 8.2123 6.44441 9.35022 7.96363C9.49902 8.16234 9.7311 8.28134 9.97928 8.28134Z" fill="#484D59"/>
                                    <path d="M13.1881 19.7642V21.9761C13.5872 21.7606 13.8243 21.363 13.8877 20.9859C13.933 20.7166 13.9515 20.0702 13.1881 19.7642Z" fill="#484D59"/>
                                    <path d="M11.4933 16.9442C11.4385 17.2348 11.509 17.5056 11.6727 17.6341C11.7368 17.6844 11.8088 17.7335 11.886 17.7817V16.3113C11.6585 16.4704 11.5347 16.725 11.4933 16.9442Z" fill="#484D59"/>
                                    <path d="M16.8542 21.3715V17.4955C16.8542 16.4127 17.6329 15.501 18.7058 15.3277C19.2608 15.238 19.7435 14.8918 20.009 14.3981C18.8898 12.8502 17.5721 11.4087 16.2659 10.0778C16.119 9.92807 15.918 9.84375 15.7083 9.84375H9.4583C9.24857 9.84375 9.04767 9.92807 8.90075 10.0778C5.57075 13.4705 2.16663 17.5809 2.16663 22.3437L2.16668 22.3999C2.17397 25.6715 4.87976 28.3334 8.19845 28.3334H16.9682C17.8961 28.3334 18.7759 28.1252 19.5624 27.7538C17.8691 26.1185 16.8542 23.835 16.8542 21.3715ZM15.1717 21.2018C15.0723 21.7928 14.7751 22.3412 14.3345 22.746C14.005 23.0487 13.616 23.2559 13.1881 23.3607V23.8542C13.1881 24.2138 12.8966 24.5052 12.5371 24.5052C12.1775 24.5052 11.886 24.2138 11.886 23.8542V23.4215C11.3406 23.3764 10.8918 23.2325 10.2534 22.8149C9.95253 22.6181 9.86815 22.2147 10.065 21.9137C10.2618 21.6128 10.6652 21.5285 10.9662 21.7253C11.3353 21.9668 11.571 22.0698 11.886 22.1116V19.2516C11.4541 19.0543 11.1326 18.865 10.8692 18.6584C10.319 18.2269 10.0678 17.4775 10.2138 16.7028C10.373 15.8581 10.9608 15.186 11.7479 14.9489C11.7944 14.9349 11.8404 14.9221 11.886 14.9102V14.4792C11.886 14.1196 12.1775 13.8282 12.5371 13.8282C12.8966 13.8282 13.1881 14.1196 13.1881 14.4792V14.8381C13.8412 14.9473 14.3024 15.2675 14.5249 15.5228C14.7611 15.7938 14.7329 16.2051 14.4617 16.4414C14.192 16.6764 13.7833 16.6495 13.5466 16.382C13.5311 16.3662 13.4142 16.2528 13.1881 16.1749V18.3781C13.3111 18.4239 13.4341 18.4682 13.5548 18.5109C14.7414 18.9303 15.376 19.9865 15.1717 21.2018Z" fill="#484D59"/>
                                    <path d="M28.2948 16.8702C27.234 16.6986 26.3052 16.0272 25.8162 15.0459C25.7498 14.9124 25.6116 14.8301 25.4625 14.8301H21.7876C21.6385 14.8301 21.5002 14.9124 21.4337 15.0459C20.9448 16.0272 20.016 16.6986 18.9552 16.8702C18.6462 16.9201 18.4166 17.1824 18.4166 17.4954V21.3716C18.4166 24.5121 20.4213 27.2909 23.3775 28.2926C23.5378 28.347 23.7123 28.347 23.8725 28.2926C26.8288 27.2909 28.8333 24.5121 28.8333 21.3716V17.4954C28.8333 17.1824 28.6038 16.9201 28.2948 16.8702ZM26.3871 20.6801L23.4409 23.6264C23.2884 23.779 23.0884 23.8553 22.8885 23.8553C22.6886 23.8553 22.4886 23.779 22.3361 23.6264L20.863 22.1533C20.5578 21.8482 20.5578 21.3535 20.863 21.0484C21.168 20.7434 21.6628 20.7434 21.9678 21.0484L22.8885 21.9691L25.2824 19.5753C25.5875 19.2702 26.0822 19.2702 26.3873 19.5753C26.6922 19.8805 26.6922 20.3751 26.3871 20.6801Z" fill="#484D59"/>
                                </svg>

                                <div class="name">
                                    {{ lang.trans_21 }}
                                </div>

                                <div class="number">
                                    {{ format_number(game.game.default_finance) }}  ₽
                                </div>

                            </div>

                            <div class="line grid">
                                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.8574 14.8223L22.1406 13.0547V7.75684C22.1406 7.02441 21.6865 6.37012 20.998 6.11133L16.1152 4.28027C15.7197 4.12891 15.2803 4.12891 14.8799 4.28027L9.99707 6.11133C9.30859 6.37012 8.85449 7.02441 8.85449 7.75684V13.0547L4.1377 14.8223C3.4541 15.0811 3 15.7354 3 16.4678V21.8438C3 22.5078 3.37598 23.1182 3.97168 23.416L8.85449 25.8574C9.34766 26.1064 9.93359 26.1064 10.4268 25.8574L15.5 23.3184L20.5732 25.8574C21.0664 26.1064 21.6523 26.1064 22.1455 25.8574L27.0283 23.416C27.624 23.1182 28 22.5078 28 21.8438V16.4678C28 15.7354 27.5459 15.0811 26.8574 14.8223ZM20.4805 13.0938L16.3301 14.6514V11.3213L20.4805 9.51465V13.0938ZM10.5195 7.68848L15.5 5.82324L20.4805 7.68848V7.71777L15.5 9.73926L10.5195 7.71777V7.68848ZM14.6211 21.9023L10.4707 23.9775V20.1152L14.6211 18.2207V21.9023ZM14.6211 16.4336L9.64062 18.4551L4.66016 16.4336V16.4043L9.64062 14.5391L14.6211 16.4043V16.4336ZM26.3398 21.9023L22.1895 23.9775V20.1152L26.3398 18.2207V21.9023ZM26.3398 16.4336L21.3594 18.4551L16.3789 16.4336V16.4043L21.3594 14.5391L26.3398 16.4043V16.4336Z" fill="#484D59"/>
                                </svg>

                                <div class="name">
                                    {{ lang.trans_24 }}
                                </div>

                                <div class="number">
                                    {{ format_number(game.game.default_raw) }}
                                </div>
                            </div>

                            <div class="line grid">
                                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.6091 7.52583L13.1175 6.875L9.00415 8.67249L10.4958 9.32499L14.6091 7.52583Z" fill="#484D59"/>
                                    <path d="M26.7541 6.5909L15.5 1.66675L4.25 6.5909L6.92833 7.76256L12.7833 5.20174C12.8885 5.15585 13.0019 5.13217 13.1166 5.13217C13.2314 5.13217 13.3448 5.15585 13.45 5.20174L17.0249 6.75757C17.1734 6.82254 17.2995 6.92935 17.3881 7.06491C17.4768 7.20049 17.5239 7.35893 17.5239 7.52091C17.5239 7.68287 17.4768 7.84133 17.3881 7.97689C17.2995 8.11245 17.1734 8.21926 17.0249 8.28423L12.575 10.2351L15.5 11.5151L26.7541 6.5909Z" fill="#484D59"/>
                                    <path d="M3 7.86523V22.7468L14.6666 28.6818V12.9694L11.3333 11.5077V15.7578C11.3334 15.8997 11.2971 16.0396 11.228 16.1636C11.1589 16.2878 11.0592 16.392 10.9383 16.4668C10.8175 16.5415 10.6796 16.5842 10.5377 16.5906C10.3958 16.5969 10.2546 16.5671 10.1275 16.5035L6.79415 14.8369C6.65563 14.7676 6.53913 14.6612 6.45774 14.5295C6.37634 14.3977 6.33325 14.2459 6.33333 14.0911V9.32356L3 7.86523Z" fill="#484D59"/>
                                    <path d="M8 13.5758L9.66666 14.4091V10.7817L8 10.0525V13.5758Z" fill="#484D59"/>
                                    <path d="M28 7.86523L16.3333 12.9694V28.6894L28 22.8269V7.86523ZM17.6257 20.9635L20.9166 19.3094C21.0144 19.2602 21.1209 19.2306 21.2301 19.2226C21.3392 19.2144 21.4489 19.228 21.5529 19.2624C21.6568 19.2966 21.7531 19.3511 21.836 19.4226C21.9189 19.494 21.987 19.5812 22.0362 19.6789C22.0854 19.7767 22.1149 19.8832 22.123 19.9925C22.131 20.1017 22.1176 20.2114 22.0832 20.3153C22.049 20.4193 21.9944 20.5154 21.923 20.5984C21.8515 20.6813 21.7643 20.7493 21.6666 20.7985L18.375 22.4527C18.2772 22.502 18.1706 22.5314 18.0614 22.5396C17.9522 22.5476 17.8425 22.5341 17.7386 22.4998C17.6346 22.4655 17.5385 22.411 17.4555 22.3395C17.3726 22.268 17.3046 22.1809 17.2554 22.0831C17.206 21.9854 17.1766 21.8788 17.1686 21.7696C17.1604 21.6604 17.174 21.5507 17.2082 21.4467C17.2426 21.3428 17.297 21.2466 17.3686 21.1638C17.44 21.0808 17.528 21.0127 17.6257 20.9635ZM21.7133 24.1077L18.3741 25.786C18.2763 25.8352 18.1698 25.8648 18.0606 25.8728C17.9514 25.881 17.8417 25.8674 17.7377 25.8332C17.6338 25.7988 17.5376 25.7443 17.4548 25.6728C17.3718 25.6014 17.3037 25.5143 17.2545 25.4165C17.2053 25.3187 17.1758 25.2122 17.1677 25.103C17.1597 24.9938 17.1731 24.884 17.2075 24.7801C17.2417 24.6761 17.2962 24.58 17.3677 24.497C17.4393 24.4141 17.5263 24.3461 17.6242 24.2969L20.9632 22.6185C21.0611 22.5693 21.1675 22.5398 21.2767 22.5317C21.3859 22.5237 21.4956 22.5371 21.5996 22.5715C21.7036 22.6057 21.7997 22.6602 21.8827 22.7317C21.9656 22.8033 22.0336 22.8903 22.0828 22.9882C22.1321 23.0858 22.1616 23.1924 22.1697 23.3016C22.1778 23.4108 22.1642 23.5205 22.13 23.6245C22.0956 23.7284 22.0411 23.8246 21.9696 23.9075C21.8982 23.9904 21.8111 24.0585 21.7133 24.1077Z" fill="#484D59"/>
                                </svg>

                                <div class="name">
                                    {{ lang.trans_146 }}
                                </div>

                                <div class="number">
                                    {{ format_number(game.game.default_prod) }}
                                </div>
                            </div>

                            <div class="line grid">

                                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.052 17.8647H26.3271C26.0718 18.8318 25.6872 19.7572 25.1787 20.6277L26.4013 21.8502C26.5478 21.9967 26.6301 22.1954 26.6301 22.4026C26.6301 22.6099 26.5478 22.8086 26.4013 22.9551L23.455 25.9012C23.1499 26.2063 22.6552 26.2064 22.3501 25.9012L21.1275 24.6788C20.2571 25.1872 19.3317 25.5719 18.3646 25.8272V27.5522C18.3646 27.9836 18.0148 28.3334 17.5833 28.3334H13.4166C12.9852 28.3334 12.6354 27.9836 12.6354 27.5522V25.8272C11.6682 25.5719 10.7429 25.1872 9.87241 24.6788L8.64985 25.9012C8.3448 26.2063 7.85012 26.2064 7.54502 25.9012L4.59877 22.9551C4.45225 22.8086 4.3699 22.6098 4.3699 22.4026C4.3699 22.1955 4.45225 21.9967 4.59877 21.8502L5.82132 20.6277C5.31277 19.7572 4.92818 18.8318 4.67278 17.8647H2.94788C2.51641 17.8647 2.16663 17.5149 2.16663 17.0834V12.9167C2.16663 12.4853 2.51641 12.1355 2.94788 12.1355H4.67278C4.92813 11.1683 5.31277 10.243 5.82127 9.37253L4.59871 8.15003C4.4522 8.00352 4.36985 7.80482 4.36985 7.59758C4.36985 7.3904 4.45215 7.19169 4.59871 7.04518L7.54497 4.09889C7.69147 3.95237 7.89017 3.87003 8.09736 3.87003C8.30454 3.87003 8.50324 3.95237 8.64975 4.09889L9.87231 5.32144C10.7428 4.81295 11.6681 4.42836 12.6353 4.17294V2.448C12.6353 2.01654 12.9851 1.66675 13.4165 1.66675H17.5832C18.0146 1.66675 18.3645 2.01654 18.3645 2.448V4.17289C19.3316 4.42826 20.2569 4.8129 21.1274 5.32138L22.35 4.09884C22.4965 3.95232 22.6952 3.86997 22.9024 3.86997C23.1095 3.86997 23.3082 3.95232 23.4547 4.09884L26.401 7.04514C26.5475 7.19164 26.6298 7.39035 26.6298 7.59753C26.6298 7.80477 26.5475 8.00341 26.401 8.14998L25.1784 9.37248C25.6869 10.243 26.0715 11.1683 26.327 12.1355H28.052C28.4835 12.1355 28.8333 12.4852 28.8333 12.9167V17.0834C28.8333 17.5149 28.4835 17.8647 28.052 17.8647ZM15.4999 7.70842C11.4793 7.70842 8.2083 10.9795 8.2083 15C8.2083 19.0208 11.4793 22.2918 15.4999 22.2918C19.5205 22.2918 22.7916 19.0208 22.7916 15C22.7916 10.9795 19.5207 7.70842 15.4999 7.70842Z" fill="#484D59"/>
                                    <path d="M18.625 18.9063H16.2813V17.3437C16.2813 16.9123 15.9314 16.5625 15.5 16.5625C15.0685 16.5625 14.7188 16.9123 14.7188 17.3437V18.9063H12.375C11.9435 18.9063 11.5938 18.5565 11.5938 18.125V11.875C11.5938 11.4435 11.9435 11.0938 12.375 11.0938H18.625C19.0565 11.0938 19.4063 11.4435 19.4063 11.875V18.125C19.4063 18.5565 19.0565 18.9063 18.625 18.9063Z" fill="#484D59"/>
                                </svg>

                                <div class="name">
                                    {{ lang.trans_76 }}
                                </div>

                                <div class="number">
                                    {{ format_number(game.game.default_ordinary) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bottom">
                        {{ lang.trans_408003 }}
                    </div>
                </div>

                <div class="wrap">
                    <div class="top">
                        <div class="left two">
                            F
                        </div>
                        <div class="right">
                            {{ lang.trans_409001 }}
                        </div>
                    </div>

                    <div class="center">

                        <div class="name">
                            {{ lang.trans_409002 }}
                        </div>

                        <div class="list">

                            <div class="line flex">
                                <div class="icon">
                                    ●
                                </div>
                                <div class="name">
                                    {{ lang.trans_409003 }}
                                </div>

                            </div>

                            <div class="line flex">
                                <div class="icon">
                                    ●
                                </div>
                                <div class="name">
                                    {{ lang.trans_409004 }}
                                </div>
                            </div>

                            <div class="line flex">
                                <div class="icon">
                                    ●
                                </div>
                                <div class="name">
                                    {{ lang.trans_409005 }}
                                </div>
                            </div>

                            <div class="line flex">
                                <div class="icon">
                                    ●
                                </div>
                                <div class="name">
                                    {{ lang.trans_409006 }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="wrap">
                    <div class="top">
                        <div class="left three">
                            R
                        </div>
                        <div class="right">
                            {{ lang.trans_410001 }}
                        </div>
                    </div>

                    <div class="center">

                        <div class="name">
                            {{ lang.trans_410002 }}
                        </div>

                        <div class="list">
                            <div class="line flex">
                                <div class="icon">
                                    +
                                </div>
                                <div class="name">
                                    {{ lang.trans_410003 }}
                                </div>
                            </div>

                            <div class="line flex">
                                <div class="icon">
                                    -
                                </div>
                                <div class="name">
                                    {{ lang.trans_410004 }}
                                </div>
                            </div>

                            <div class="line flex">
                                <div class="icon">
                                    +
                                </div>
                                <div class="name">
                                    {{ lang.trans_410005 }}
                                </div>
                            </div>

                            <div class="line flex">
                                <div class="icon">
                                    +
                                </div>
                                <div class="name">
                                    {{ lang.trans_410006 }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bottom">
                        {{ lang.trans_410007 }}
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted } from 'vue'
import { format_number } from "@/api/funcs";

import Procurement from "./rules/Procurement.vue";
import Production from "./rules/Production.vue";
import Sales from "./rules/Sales.vue";
import Costs from "./rules/Costs.vue";
import Investment from "./rules/Investment.vue";
import Loan from "./rules/Loan.vue";

export default {

    components: {
        Procurement,
        Production,
        Sales,
        Costs,
        Investment,
        Loan,
    },

    setup(){

        const store     = useStore()
        const domain    = computed(() => store.getters.domain)
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)


        onUnmounted(() => {
			document.querySelector('body').classList.remove('scroll_none')
		})

		onMounted(async () => {

            document.addEventListener('keydown', function (e) {
                if (e.keyCode === 27) {
                    close();
                }
            });

			document.querySelector('body').classList.add('scroll_none')

		})

        async function close() {
            store.dispatch('update_rules', false)
        }


        return {
            format_number,
            domain,
            close,
            lang,
            game,
        }
    }
}
</script>