
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {
    
    socket: null,
    socket_open: false,
    key: ``,
    count_users: 0,

  },

  getters,
  mutations,
  actions,
  modules: {},

}


