<template>

    <!-- teams desition -->
    <div class="wrap">
        <div class="new_style">

            <div class="wrap fixed">

                <div class="wrap">
                
                    <div class="name top">
                        <svg viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.5 22V20C21.5 18.9391 21.0786 17.9217 20.3284 17.1716C19.5783 16.4214 18.5609 16 17.5 16H9.5C8.43913 16 7.42172 16.4214 6.67157 17.1716C5.92143 17.9217 5.5 18.9391 5.5 20V22" fill="#0944AA"/>
                            <path d="M21.5 22V20C21.5 18.9391 21.0786 17.9217 20.3284 17.1716C19.5783 16.4214 18.5609 16 17.5 16H9.5C8.43913 16 7.42172 16.4214 6.67157 17.1716C5.92143 17.9217 5.5 18.9391 5.5 20V22H21.5Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.5 12C15.7091 12 17.5 10.2091 17.5 8C17.5 5.79086 15.7091 4 13.5 4C11.2909 4 9.5 5.79086 9.5 8C9.5 10.2091 11.2909 12 13.5 12Z" fill="#0944AA" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.5 21.9999V19.9999C27.4993 19.1136 27.2044 18.2527 26.6614 17.5522C26.1184 16.8517 25.3581 16.3515 24.5 16.1299" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20.5 4.12988C21.3604 4.35018 22.123 4.85058 22.6676 5.55219C23.2122 6.2538 23.5078 7.11671 23.5078 8.00488C23.5078 8.89305 23.2122 9.75596 22.6676 10.4576C22.123 11.1592 21.3604 11.6596 20.5 11.8799" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>

                    <div v-for="object in game.teams" :key="object.id" :class="['name', {'select': object.is_open}]">
                        {{ object.name }}
                    </div>

                </div>

            </div>

            <div class="wrap scroll">

                <div class="name top">

                    <!-- снабжение -->
                    <div v-if="tact.type == `Снабжение`" class="wrap">
                        <div class="wrap">

                            <div class="name">{{ lang.trans_96 }}</div>

                            <div class="wrap">
                                <div class="wrap">
                                    <div class="name">{{ lang.trans_423 }}</div>
                                </div>
                                <div class="wrap">
                                    <div class="name">{{ lang.trans_424 }}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- производство -->
                    <div v-if="tact.type == `Производство`" class="wrap">

                        <div class="wrap">

                            <div class="name">{{ lang.trans_449 }} </div>

                            <div class="wrap">

                                <div class="wrap">

                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_493_6775)">
                                        <path d="M2.25 21.5C2.25 22.0304 2.46071 22.5391 2.83579 22.9142C3.21086 23.2893 3.71957 23.5 4.25 23.5H20.25C20.7804 23.5 21.2891 23.2893 21.6642 22.9142C22.0393 22.5391 22.25 22.0304 22.25 21.5V9.5L15.25 14.5V9.5L8.25 14.5V5.5C8.25 4.96957 8.03929 4.46086 7.66421 4.08579C7.28914 3.71071 6.78043 3.5 6.25 3.5H4.25C3.71957 3.5 3.21086 3.71071 2.83579 4.08579C2.46071 4.46086 2.25 4.96957 2.25 5.5V21.5Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.25 19.5H18.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.25 19.5H13.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.25 19.5H8.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_493_6775">
                                        <rect width="24" height="24" fill="white" transform="translate(0.25 0.5)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>

                                    <div class="name">{{ lang.trans_426 }}</div>

                                </div>

                                <div class="wrap">

                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_493_6775)">
                                        <path d="M2.25 21.5C2.25 22.0304 2.46071 22.5391 2.83579 22.9142C3.21086 23.2893 3.71957 23.5 4.25 23.5H20.25C20.7804 23.5 21.2891 23.2893 21.6642 22.9142C22.0393 22.5391 22.25 22.0304 22.25 21.5V9.5L15.25 14.5V9.5L8.25 14.5V5.5C8.25 4.96957 8.03929 4.46086 7.66421 4.08579C7.28914 3.71071 6.78043 3.5 6.25 3.5H4.25C3.71957 3.5 3.21086 3.71071 2.83579 4.08579C2.46071 4.46086 2.25 4.96957 2.25 5.5V21.5Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.25 19.5H18.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.25 19.5H13.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.25 19.5H8.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_493_6775">
                                        <rect width="24" height="24" fill="white" transform="translate(0.25 0.5)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>

                                    <div class="name">{{ lang.trans_427 }}</div>

                                </div>

                            </div>

                        </div>

                        <div v-if="game.variant" class="wrap">

                            <div class="name">{{ lang.trans_450 }}</div>

                            <div class="wrap">

                                <div class="wrap">

                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_493_6775)">
                                        <path d="M2.25 21.5C2.25 22.0304 2.46071 22.5391 2.83579 22.9142C3.21086 23.2893 3.71957 23.5 4.25 23.5H20.25C20.7804 23.5 21.2891 23.2893 21.6642 22.9142C22.0393 22.5391 22.25 22.0304 22.25 21.5V9.5L15.25 14.5V9.5L8.25 14.5V5.5C8.25 4.96957 8.03929 4.46086 7.66421 4.08579C7.28914 3.71071 6.78043 3.5 6.25 3.5H4.25C3.71957 3.5 3.21086 3.71071 2.83579 4.08579C2.46071 4.46086 2.25 4.96957 2.25 5.5V21.5Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.25 19.5H18.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.25 19.5H13.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.25 19.5H8.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_493_6775">
                                        <rect width="24" height="24" fill="white" transform="translate(0.25 0.5)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>

                                    <div class="name">{{ lang.trans_426 }}</div>

                                </div>

                                <div class="wrap">

                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_493_6775)">
                                        <path d="M2.25 21.5C2.25 22.0304 2.46071 22.5391 2.83579 22.9142C3.21086 23.2893 3.71957 23.5 4.25 23.5H20.25C20.7804 23.5 21.2891 23.2893 21.6642 22.9142C22.0393 22.5391 22.25 22.0304 22.25 21.5V9.5L15.25 14.5V9.5L8.25 14.5V5.5C8.25 4.96957 8.03929 4.46086 7.66421 4.08579C7.28914 3.71071 6.78043 3.5 6.25 3.5H4.25C3.71957 3.5 3.21086 3.71071 2.83579 4.08579C2.46071 4.46086 2.25 4.96957 2.25 5.5V21.5Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.25 19.5H18.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.25 19.5H13.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.25 19.5H8.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_493_6775">
                                        <rect width="24" height="24" fill="white" transform="translate(0.25 0.5)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>

                                    <div class="name">{{ lang.trans_427 }}</div>

                                </div>

                            </div>
                            
                        </div>

                    </div>

                    <!-- продажи -->
                    <div v-if="tact.type == `Продажи`" class="wrap">

                        <div class="wrap">

                            <div class="name">{{ lang.trans_449 }}</div>

                            <div class="wrap">

                                <div class="wrap">

                                    <div class="name">{{ lang.trans_425 }}</div>

                                </div>

                                <div class="wrap">

                                    <div class="name">{{ lang.trans_429 }}</div>

                                </div>

                            </div>

                        </div>

                        <div v-if="game.variant" class="wrap">

                            <div class="name">{{ lang.trans_450 }}</div>

                            <div class="wrap">

                                <div class="wrap">
                                    <div class="name">{{ lang.trans_428 }}</div>
                                </div>

                                <div class="wrap">
                                    <div class="name">{{ lang.trans_430 }}</div>
                                </div>

                            </div>
                            
                        </div>

                    </div>

                    <!-- инвестиции -->
                    <div v-if="tact.type == `Инвестиции`" class="wrap">

                        <div class="wrap">

                            <div class="name">{{ lang.trans_27 }}</div>

                            <div class="wrap">

                                <div class="wrap">

                                    <svg viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 21C14.75 21.5304 14.9607 22.0391 15.3358 22.4142C15.7109 22.7893 16.2196 23 16.75 23H32.75C33.2804 23 33.7891 22.7893 34.1642 22.4142C34.5393 22.0391 34.75 21.5304 34.75 21V9L27.75 14V9L20.75 14V5C20.75 4.46957 20.5393 3.96086 20.1642 3.58579C19.7891 3.21071 19.2804 3 18.75 3H16.75C16.2196 3 15.7109 3.21071 15.3358 3.58579C14.9607 3.96086 14.75 4.46957 14.75 5V21Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M29.75 19H30.75" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M24.75 19H25.75" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M19.75 19H20.75" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2.75 13L10.75 13" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <div class="name">{{ lang.trans_426 }}</div>

                                </div>

                                <div class="wrap">

                                    <svg viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.25 21C14.25 21.5304 14.4607 22.0391 14.8358 22.4142C15.2109 22.7893 15.7196 23 16.25 23H32.25C32.7804 23 33.2891 22.7893 33.6642 22.4142C34.0393 22.0391 34.25 21.5304 34.25 21V9L27.25 14V9L20.25 14V5C20.25 4.46957 20.0393 3.96086 19.6642 3.58579C19.2891 3.21071 18.7804 3 18.25 3H16.25C15.7196 3 15.2109 3.21071 14.8358 3.58579C14.4607 3.96086 14.25 4.46957 14.25 5V21Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M29.25 19H30.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M24.25 19H25.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M19.25 19H20.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M6.25 9L6.25 17" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2.25 13L10.25 13" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <div class="name">{{ lang.trans_426 }}</div>

                                </div>

                            </div>

                        </div>

                        <div class="wrap">

                            <div class="name">{{ lang.trans_28 }}</div>

                            <div class="wrap">

                                <div class="wrap">

                                    <svg viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.75 21C14.75 21.5304 14.9607 22.0391 15.3358 22.4142C15.7109 22.7893 16.2196 23 16.75 23H32.75C33.2804 23 33.7891 22.7893 34.1642 22.4142C34.5393 22.0391 34.75 21.5304 34.75 21V9L27.75 14V9L20.75 14V5C20.75 4.46957 20.5393 3.96086 20.1642 3.58579C19.7891 3.21071 19.2804 3 18.75 3H16.75C16.2196 3 15.7109 3.21071 15.3358 3.58579C14.9607 3.96086 14.75 4.46957 14.75 5V21Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M29.75 19H30.75" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M24.75 19H25.75" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M19.75 19H20.75" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2.75 13L10.75 13" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                                    <div class="name">{{ lang.trans_427 }}</div>

                                </div>

                                <div class="wrap">

                                    <svg viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.25 21C14.25 21.5304 14.4607 22.0391 14.8358 22.4142C15.2109 22.7893 15.7196 23 16.25 23H32.25C32.7804 23 33.2891 22.7893 33.6642 22.4142C34.0393 22.0391 34.25 21.5304 34.25 21V9L27.25 14V9L20.25 14V5C20.25 4.46957 20.0393 3.96086 19.6642 3.58579C19.2891 3.21071 18.7804 3 18.25 3H16.25C15.7196 3 15.2109 3.21071 14.8358 3.58579C14.4607 3.96086 14.25 4.46957 14.25 5V21Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M29.25 19H30.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M24.25 19H25.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M19.25 19H20.25" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M6.25 9L6.25 17" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2.25 13L10.25 13" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <div class="name">{{ lang.trans_427 }}</div>

                                </div>

                            </div>
                            
                        </div>

                    </div>

                    <!-- кредит -->
                    <div v-if="tact.type == `Кредит`" class="wrap">
                        <div class="wrap">

                            <div class="name">{{ lang.trans_22 }}</div>

                            <div class="wrap">
                                <div class="wrap">
                                    +
                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.5 22.5H21.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M6.5 18.5V11.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.5 18.5V11.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.5 18.5V11.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18.5 18.5V11.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.5 2.5L20.5 7.5H4.5L12.5 2.5Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </div>

                                <div class="wrap">
                                   -
                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.5 22.5H21.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M6.5 18.5V11.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.5 18.5V11.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.5 18.5V11.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18.5 18.5V11.5" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12.5 2.5L20.5 7.5H4.5L12.5 2.5Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div v-for="object in game.teams" :key="object.id" class="wrap">

                    <!-- снабжение -->
                    <div v-if="tact.type == `Снабжение`" class="name">
                        <input type="text" :value="object.purchase_count" @input="test('purchase_count', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="tact.type == `Снабжение`" class="name">
                        <input type="text" :value="object.purchase_price" @input="test('purchase_price', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <!-- производство -->
                    <div v-if="tact.type == `Производство`" class="name">
                        <input type="text" :value="object.prod_ordinary" @input="test('prod_ordinary', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="tact.type == `Производство`" class="name">
                        <input type="text" :value="object.prod_auto" @input="test('prod_auto', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="game.variant && tact.type == `Производство`" class="name">
                        <input type="text" :value="object.prod_premium_ordinary" @input="test('prod_premium_ordinary', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="game.variant && tact.type == `Производство`" class="name">
                        <input type="text" :value="object.prod_premium_auto" @input="test('prod_premium_auto', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <!-- продажи -->
                    <div v-if="tact.type == `Продажи`" class="name">
                        <input type="text" :value="object.sales_count" @input="test('sales_count', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="tact.type == `Продажи`" class="name">
                        <input type="text" :value="object.sales_price" @input="test('sales_price', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="game.variant && tact.type == `Продажи`" class="name">
                        <input type="text" :value="object.sales_count_premium" @input="test('sales_count_premium', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="game.variant && tact.type == `Продажи`" class="name">
                        <input type="text" :value="object.sales_price_premium" @input="test('sales_price_premium', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <!-- инвестиции -->
                    <div v-if="tact.type == `Инвестиции`" class="name">
                        <input type="text" :value="object.sell_ordinary" @input="test('sell_ordinary', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="tact.type == `Инвестиции`" class="name">
                        <input type="text" :value="object.build_ordinary" @input="test('build_ordinary', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <div v-if="tact.type == `Инвестиции`" class="name">
                        <input type="text" :value="object.sell_auto" @input="test('sell_auto', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="tact.type == `Инвестиции`" class="name">
                        <input type="text" :value="object.build_auto" @input="test('build_auto', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                    <!-- кредит -->
                    <div v-if="tact.type == `Кредит`" class="name">
                        <input type="text" :value="object.loan_take" @input="test('loan_take', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>
                    <div v-if="tact.type == `Кредит`" class="name">
                        <input type="text" :value="object.loan_repay" @input="test('loan_repay', object, $event.target)" @keyup.enter="clean_focus($event.target)" />
                    </div>

                </div>

            </div>

            <div class="wrap fixed">

                <div class="wrap">
                
                    <div class="name top" @click="update">
                        <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0133 12.9769C14.1599 7.05495 22.3221 5.61573 28.2441 9.76234C30.8685 11.5999 32.612 14.2269 33.3799 17.097L29.1654 18.2264C28.6536 16.3126 27.4911 14.5614 25.7415 13.3364C21.7934 10.5718 16.3519 11.5313 13.5873 15.4795L16.0918 17.2331L8.36041 19.1403L7.5083 11.2229L10.0133 12.9769Z" fill="#0944AA"/>
                            <path d="M15.7304 27.6336C19.6786 30.3981 25.1201 29.4386 27.8846 25.4904L25.3801 23.7368L33.1115 21.8296L33.9636 29.747L31.4587 27.993C27.312 33.915 19.1498 35.3542 13.2278 31.2076C10.6035 29.37 8.8599 26.7431 8.09204 23.8729L12.3065 22.7435C12.8183 24.6574 13.9808 26.4085 15.7304 27.6336Z" fill="#0944AA"/>
                        </svg>
                    </div>

                    <div v-for="object in game.teams" :key="object.id" class="name" @click="confir(object)">
                        <div :class="[`selected`, {active: object.decision}]"></div>
                    </div>

                </div>

            </div>

        </div>


    </div>

</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

import { trun } from '@/api/funcs'

export default {

    setup(){

        const store             = useStore()
        const lang              = computed(() => store.getters.lang)
        const game              = computed(() => store.getters.game)
        const step              = computed(() => store.getters.step)

        const tacts             = computed(() => step.value.tacts.filter((o) => o.active))
        const active_tact       = computed(() => tacts.value.filter((o) => o.status == "Активен"))
        const not_active        = computed(() => tacts.value.filter((o) => o.status == "Не начат"))
        const finished          = computed(() => tacts.value.filter((o) => o.status == "Завершен"))
        

        const tact              = computed(() => {

            if(active_tact.value.length > 0){
                return active_tact.value[0]
            }else{


                if(not_active.value.length > 0){

                    return not_active.value[0]

                }else{


                    if(finished.value.length == 6){

                        return finished.value[finished.value.length-1]

                    }else{

                        const temp = tacts.value.filter((o) => o.type == "Снабжение")

                        return temp.length > 0 ? temp[0] : {}

                    }

                }
            }
        
        })

        async function update() {
            store.dispatch('global_block_window', true)
            await store.dispatch('get_game', game.value.id)
            store.dispatch('global_block_window', false)
        }


        function clean_focus(target){
            target.blur()
        }

        async function test(type, object, target){
            
            let result = parseInt(target.value, 10)
            if(isNaN(result)){
                result = 0
            }

            if(result > 2147483647){
                result = 2147483647
            }

            target.value = result

            switch(type){

                //снабжение
                case 'purchase_count':
                    object.purchase_count = result; break

                case 'purchase_price':
                    object.purchase_price = result; break

                //производство
                case 'prod_ordinary':
                    object.prod_ordinary = result; break

                case 'prod_auto':
                    object.prod_auto = result; break

                case 'prod_premium_ordinary':
                    object.prod_premium_ordinary = result; break

                case 'prod_premium_auto':
                    object.prod_premium_auto = result; break

                //продажи
                case 'sales_count':
                    object.sales_count = result; break

                case 'sales_price':
                    object.sales_price = result; break
                
                case 'sales_count_premium':
                    object.sales_count_premium = result; break
                
                case 'sales_price_premium':
                    object.sales_price_premium = result; break

                //инвестиции
                case 'sell_ordinary':
                    object.sell_ordinary = result; break
                
                case 'sell_auto':
                    object.sell_auto = result; break
                
                case 'build_ordinary':
                    object.build_ordinary = result; break
                
                case 'build_auto':
                    object.build_auto = result; break

                //кредит
                case 'loan_take':
                    object.loan_take = result; break

                case 'loan_repay':
                    object.loan_repay = result; break
                    
            }

        }

        async function confir(object){

            //отправляем на обновление, таблицу
            await store.dispatch('update_team_decisions', {
                gid: game.value.id,
                team: object,
            })

            //обновляем данные на странице с игрой, у пользователей
            await store.dispatch('socket_send', {
                command: "user_update_decision", 
                tid: object.id,
            })

            await store.dispatch('get_game', game.value.id)

        }



        
        
        return {
            lang,
            update,
            trun,
            game,
            clean_focus, 
            test,
            tact,
            confir,

        }

    }
}

</script>

<style scoped>

.new_style {
    display: grid;
    background: white;
    border-radius: 5px;
    grid-template-columns: auto 1fr auto;
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
}

@media (min-width: 550px) {
    .new_style {
        font-size: 18px;
    }
}

.new_style > .wrap {
    display: flex;
}

.new_style > .wrap.fixed {
    max-width: 300px;
    width: 100%;
    z-index: 1;
}

.new_style > .wrap.fixed:first-of-type {
    box-shadow: 3px 0px 4px rgb(0 0 0 / 10%);
}

.new_style > .wrap.fixed:last-of-type {
    box-shadow: -3px 0px 4px rgb(0 0 0 / 10%);
}

.new_style > .wrap.fixed > .wrap > .name.top {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ededed;
    padding: 1.8em 0;
}



.new_style > .wrap.fixed > .wrap > .name {

    padding: 0.625em 1em;
    border-bottom: 1px solid #ededed;
    font-weight: 400;
    font-size: 1em;
    color: #0944AA;

    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;


}

@media (min-width: 550px) {
    .new_style > .wrap.fixed > .wrap > .name {
        max-width: 100%;
    }
}

.new_style > .wrap.fixed > .wrap > .name.top > svg {
    width: 30px;
    height: 30px;
}

.new_style > .wrap.fixed:last-of-type > .wrap > .top.name {
    padding: 1.8em 0;
}

.new_style > .wrap.fixed:last-of-type > .wrap > .name {
    padding: 0.71em 1.5em;
    
}


.new_style > .wrap.fixed > .wrap > .name > .selected {
    width: 19px;
    height: 19px;
    background: #c9c9c9;
    border-radius: 25px;
}

.new_style > .wrap.fixed > .wrap > .name > .selected.active {
    background: linear-gradient(229.73deg, #00A76B -19.09%, #50FFAB 131.11%);
}



.new_style > .wrap.scroll {
    overflow-x: scroll;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.new_style > .wrap.scroll > .name.top > .wrap {
    display: grid;
    grid-auto-flow: column dense;
}

.new_style > .wrap.scroll > .name.top > .wrap > .wrap {
    font-weight: 500;
    font-size: 1em;
    text-align: center;
    color: #0944AA;
}

.new_style > .wrap.scroll > .name.top > .wrap > .wrap > .name {
    text-align: center;
    padding: 0.625em;
    border-bottom: 1px solid #ededed;
}

.new_style > .wrap.scroll > .name.top > .wrap > .wrap > .wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #ededed;
}

.new_style > .wrap.scroll > .name.top > .wrap > .wrap > .wrap > .wrap {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0.7em;
    min-width: 120px;
}

.new_style > .wrap.scroll > .name.top > .wrap > .wrap > .wrap > .wrap > svg {
    width: 24px;
    height: 24px;
}


.new_style > .wrap.scroll > .name.top > .wrap > .wrap > .wrap > .wrap > .name {
    text-align: center;
}

.new_style > .wrap.scroll > .name.top > .wrap > .wrap > .wrap > .wrap > svg {
    margin-right: 5px;
}

.new_style > .wrap.scroll > .wrap {
    display: grid;
    grid-auto-flow: column dense;
    border-bottom: 1px solid #ededed;
}

.new_style > .wrap.scroll > .wrap:hover {
    background: #f8f8f8;
}

.new_style > .wrap.scroll > .wrap > .name {
    display: flex;
    justify-content: center;
    padding: 0.67em;
    min-width: 120px;
}

.new_style > .wrap.scroll > .wrap > .name > input {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;

    font-weight: 500;
    font-size: 1em;
    text-align: center;
    color: #0944AA;
}

.new_style > .wrap.scroll > .wrap > .name > input:focus {
    border-bottom: 2px solid #ababab;
}


</style>