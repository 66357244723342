
export const cookie         = state => state.cookie
export const users          = state => state.users
export const user           = state => state.user
export const is_auth        = state => state.is_auth
export const is_operator    = state => {

    if(state.user){
        if(state.user.roles.filter((o) => o.name == 'operator').length > 0 ){
            return true
        }
    }

    return false
} 

