
<template>
    <div class="input password">
        <label>{{ name }}</label>

        <div class="wrap">
            <input type='password' :value="model" :placeholder="placeholder" @input="$emit('update:model', test($event.target))" @keyup.enter="event()"/>
            <div class="length">{{ model.length }}/{{ max }}</div>
        </div>

    </div>
</template>

<script>

export default {

    props: {
        name: {
            type: String,
            default: ''
        },
        model: {
            type: String,
            default: ''
        },
        max: {
            type: Number,
            default: 0
        },
        placeholder: {
            type: String,
            default: ''
        },
        event: {
            type: Function,
            default: () => () => {}
        }
    },


    emits: ["update:model"],
    
	setup(props){

        function test(target){
            
            let result = target.value

            if(result.length > props.max){

                result = result.substring(0, props.max)

            }

            target.value = result
            
            return result
        }

        return { 
            test,
        }
    }
}

</script>

<style scoped>

.input.password {
    position: relative;
    margin-bottom: 1.25em;
    border-radius: 5px;
}

.input.password > label {
    left: 0.8em;
    right: auto;
    position: absolute;
    background: white;
    color: #0944AA;
    font-size: .85em;
    top: -0.6em;
    padding: 0 0.35em;
}

.input.password > .wrap {
    border: 1px solid #0944AA;
    border-radius: 8px;
    padding: .8em .95em;
    display: flex;
    align-items: center;
}

.input.password > .wrap > input[type="password"] {
    max-width: 100%;
    margin: 0;
    border: none;
    outline: none;
    width: 100%;
    font-size: .9em;
}

.input.password > .wrap > .length {
    font-weight: 400;
    font-size: .9em;
    color: #0944AA;
    opacity: 0.5;
}

.input.password:last-of-type {
    margin-bottom: 0;
}

</style>
