
import { set_cookie } from "@/api/Cookie";
import { requests } from "@/api/funcs";

export const clear_user = ({ commit, dispatch }) => {

    commit('clear_user')

    //is_auth
    dispatch('update_is_auth', false)

}

export const update_cookie = ({ commit }, cookie) => {
    commit('update_cookie', cookie)
}

export const update_is_auth = ({ commit }, bool) => {
    commit('update_is_auth', bool)
}


export const delete_users = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/users/${id}`, 'DELETE', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}


export const update_user = async ({ dispatch, getters }) => {

    let [ err, code ] =  await requests(`${getters.domain}/api/users/${getters.user.id}`, 'PUT', getters.cookie, getters.user)

    if(err){
        dispatch('update_err_by_code', code); dispatch('global_block_window', false); return
    }

    //обновляем юзера
    dispatch('get_user')
}

export const auth = async ({ getters, dispatch }) => {

    const response = await fetch(`${getters.domain}/api/user/auth`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `name=${getters.user.username}&passw=${getters.user.password}`,
    })

    if(!response.ok){
        dispatch('update_err_by_code', 142); return
    }

    const body = await response.json()

    //устанавливаем куки в браузер
    set_cookie("token", body.access)

    //устанавливаем куки в хранилище
    dispatch('update_cookie', `Bearer ${body.access}`)

}

export const create_user = async ({ commit, getters }) => {

    if(getters.cookie){

        let response = await fetch(`${getters.domain}/auth/users/`, {
            method: 'POST',
            headers: {
                "Authorization": getters.cookie,
            },
        })

        if(response.ok){
        
            let body = await response.json()

            commit('get_user', body); return
        }

        //чистим куки в браузере
        set_cookie("token", "", 0)

    }

    commit('get_user', false)
 
}


export const get_user = async ({ getters, commit, dispatch }) => {

    if(getters.cookie){
    
        try {


            let response = await fetch(`${getters.domain}/api/users/me`, {
                method: 'GET',
                headers: {
                    "Authorization": getters.cookie,
                },
            })

            if(response.status != 200){
                dispatch('update_err_by_code', response.status); return
            }

            let body = await response.json()

            commit('get_user', body)

            //is_auth
            dispatch('update_is_auth', true)

            return
        
        } catch (e) {

            if(e instanceof TypeError){
                dispatch('update_err_by_code', 375)
            }else{
                console.log(e.name)
            }

        }

    }
 
}
