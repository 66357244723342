
import { requests } from "@/api/funcs";


export const clean_feedbacks = async ({ commit }) => {
    commit('clean_feedbacks')
}



export const update_feedbacks = async ({ getters, dispatch }, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/feedbacks/${object.id}/`, 'PUT', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const add_feedbacks = async ({ getters, dispatch }) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/feedbacks/`, 'POST', getters.cookie, getters.feedback)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const delete_feedbacks = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/feedbacks/${id}/`, 'DELETE', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const get_feedback = async ({ getters, commit, dispatch }, id) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/feedbacks/${id}/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_feedback', body)
}


export const get_feedbacks = async ({ getters, commit, dispatch }) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/feedbacks/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_feedbacks', body)
}

export const update_feedback_pop_up = ({ commit }, bool) => {
    commit('update_feedback_pop_up', bool)
}