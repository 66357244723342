

//табы
export const tabs               = state => state.tabs

//активный таб
export const active_tab         = getters => getters.tabs == undefined ? {} : getters.tabs.filter((o) => o.active)[0]

//колонки активного таба
export const cols               = state => state.cols

//колонки активного таба, которые видно
export const cols_filter        = (state, getters) => state.cols.slice(getters.pagination.start, getters.pagination.stop,)

//пагинация
export const pagination         = state => state.pagination

//история таба
export const tab_historys       = state => state.tab_historys

//всплывающее окошко у рейтинга
export const col_pop_up         = state => state.col_pop_up
