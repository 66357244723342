

export const clean_feedbacks = (state) => {
    state.feedback = {
        message: '',
        img: '',
        contact: '',
    }
}


export const update_feedback_pop_up = (state, bool) => {
    state.feedback_pop_up = bool
}

export const get_feedbacks = (state, feedbacks) => {
    state.feedbacks = feedbacks
}

export const get_feedback = (state, feedback) => {
    state.feedback = feedback
}
