<template>
    <div class="analytics">
        <div v-if="game.status != `Не активна`">

            <MyTable :event="update" :w="game" />

            <RaitingTable/>

            <ChartV2/>

        </div>
        <div v-else class="plug">
            <plug />
            <div class="name">
                {{ lang.trans_602004 }}
            </div>

        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex';

import { domain } from '@/global'
import { requests } from "@/api/funcs"

import MyTable from "@/components/operator_tabs/Table.vue"
import RaitingTable from "@/components/operator_analytics_tabs/Table.vue"
import ChartV2 from "@/components/game/ChartV3.vue"
import Plug from './Plug.vue'

export default {

    components: {
        MyTable,
        RaitingTable,
        ChartV2,
        Plug,
    },

    setup(){
        const store     = useStore()
        const cookie    = computed(() => store.getters.cookie)
        const lang  = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)
        const step      = computed(() => store.getters.step)


        async function update() {

            if(game.value.id){
                const [err, code, body] = await requests(`${domain}/api/games/operator/${game.value.id}/steps/${step.value.id}/tabs`, 'GET', cookie.value)
                if (err) {
                    store.dispatch('update_err_by_code', code); return;
                }

                return body
            }

            return []
        }

        return { game, update, lang }

    }
}

</script>

<style scoped>
.analytics > .plug {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5em 0;
    background: white;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px;
}

.analytics > .plug > .name {
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5E5E5E;
    opacity: 0.5;
    margin-top: 20px;
    max-width: 460px;
}
</style>