
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {

    cookie: false,

    users: [],
    
    user: {

      username:   '',
      password:   '',
      first_name: '',
      last_name:  '',
      email:      '',

      profile: {
        lang: {},
      },

      groups: [],
      
    },

    is_auth: false,

  },

  getters,
  mutations,
  actions,
  modules: {},

}


