
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {

    assistant: false,

    alerts: [],
    
  },

  getters,
  mutations,
  actions,
  modules: {},

}


