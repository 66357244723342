


//если пользователь авторизован, то устанавливаем ему нужный язык
export const set_lang = (state, id) => {


    let flag = true
    for(let lang of state.langs){
        if(lang.id == id){
            state.lang = lang; flag = false; break
        }
    }

    //если не нашлось нужного языка, то выставляем язык по дефолту
    if(flag){
        for(let lang of state.langs){
            if(lang.default){
                state.lang = lang
            }
        }
    }
    
}


export const get_lang = (state, lang) => {
    state.lang = lang
}

export const get_langs = (state, langs) => {
    state.langs = langs
}
