<template>
    <div class="pop_up">

        <div class="back"></div>

        <div class="wrap">

            <div class="name">{{ lang.trans_409 }}</div>

            <div class="form">

                <div>

                    <input-text v-model:model="game.name" :name="lang.trans_190" :placeholder="lang.trans_190" :max="40" :event="add" :is-call="false"></input-text>

                    <input-text v-model:model="game.info" :name="lang.trans_191" :placeholder="lang.trans_191" :max="100" :event="add" :is-call="false"></input-text>

                    <input-range v-model:model="game.count_commands" :name="lang.trans_410" :placeholder="lang.trans_410" :max="25" ></input-range>

                    <input-bool v-model:model="game.variant" :name="lang.trans_321"></input-bool>

                    <input-bool v-model:model="game.is_fast_credit" :name="lang.trans_150"></input-bool>

                    <input-bool v-model:model="game.is_chart" :name="lang.trans_411"></input-bool>
                </div>

            </div>

            <div class="wrap">
                <div class="btn ok" @click="add" @keyup.enter="add">{{ lang.trans_226 }}</div>
                <div class="btn close" @click="close">{{ lang.trans_368 }}</div>
            </div>
        </div>

    </div>
</template>

<script>

import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import InputBool  from '@/components/form/InputBool.vue'
import InputText  from '@/components/form/InputText.vue'
import InputRange  from '@/components/form/InputRange.vue'

import { requests } from "@/api/funcs";

export default {

    components: {
        "input-text": InputText,
        "input-range": InputRange,
        "input-bool": InputBool,
    },

    props: {
        close: {
            type: Function,
            default: () => {}
        },
    },

    setup(props){

        const router        = useRouter()
        const store         = useStore()
        const lang          = computed(() => store.getters.lang)
        const game          = computed(() => store.getters.game)
        const cookie        = computed(() => store.getters.cookie)
        const domain        = computed(() => store.getters.domain)


        onMounted(async () => await store.dispatch('clean_game'))


        async function add(){

            store.dispatch('global_block_window', true)

            const [ err, code, body ] =  await requests(`${domain.value}/api/games`, 'POST', cookie.value, game.value)

            if(err){
                store.dispatch('update_err_by_code', +code); store.dispatch('global_block_window', false); return
            }

            router.push({ name: `operator.games.update`, params: { id: body.id } })
            

            props.close()


            store.dispatch('global_block_window', false)

        }


        return { lang, game, add, }

    }
}

</script>

<style scoped>
.pop_up {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop_up > .back {
    background: black;
    opacity: .5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.pop_up > .wrap {
    background: white;
    z-index: 1;
    padding: 1.5em;
    border-radius: 15px;
    max-width: 650px;
    width: 100%;
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
}

@media (min-width: 500px){
    .pop_up > .wrap {
        font-size: 18px;
    }
}

.pop_up > .wrap > .name {
    font-weight: 500;
    font-size: 1.925em;
    letter-spacing: 0.02em;
    color: #0944AA;
    margin-bottom: 0.5em;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }
}

.pop_up > .wrap > .wrap > .btn {
    border-radius: 4px;
    color: white;
    padding: .9em;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: .9em;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap > .btn {
        width: 100%;
        margin: 0 auto;
    }
}

.pop_up > .wrap > .wrap > .btn.ok {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

.pop_up > .wrap > .wrap > .btn.close {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
}

</style>