


export const update_tact = (state, tact) => {
    state.tact = tact
}



export const get_tacts = (state, tacts) => {
    state.tacts = tacts
}

export const clean_tacts = async (state) => {
    state.tacts = []
}
