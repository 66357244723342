<template>
    <div class="operator games">

        <div class="top">

            <div class="wrap">
                <img src="@/assets/images/operator/games.svg">
                <div class="name games">{{ lang.trans_495 }}</div>
            </div>


            <div v-if="user.is_superuser" class="admin-games">
                <input-bool v-model:model="admin_view_games" :name="lang.trans_801001"></input-bool>
            </div>

        </div>

        <div v-if="filter_games && filter_games.length" class="center">

            <div class="wrap top">

                <div class="wrap start">
                    <div class="name">{{ lang.trans_15 }}</div>
                </div>

                <div class="wrap start">

                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9867 5H7.34675C6.35703 5.00023 5.40253 5.36738 4.66778 6.03046C3.93303 6.69355 3.47019 7.60549 3.36875 8.59C3.36275 8.642 3.35875 8.691 3.35175 8.742C3.27075 9.416 2.66675 14.456 2.66675 16C2.66675 16.7956 2.98282 17.5587 3.54543 18.1213C4.10804 18.6839 4.8711 19 5.66675 19C6.66675 19 7.16675 18.5 7.66675 18L9.08075 16.586C9.45573 16.2109 9.96436 16.0001 10.4947 16H14.8387C15.3691 16.0001 15.8778 16.2109 16.2527 16.586L17.6667 18C18.1667 18.5 18.6667 19 19.6667 19C20.4624 19 21.2255 18.6839 21.7881 18.1213C22.3507 17.5587 22.6667 16.7956 22.6667 16C22.6667 14.455 22.0627 9.416 21.9817 8.742C21.9747 8.692 21.9707 8.642 21.9647 8.591C21.8635 7.60631 21.4008 6.69413 20.666 6.03083C19.9312 5.36754 18.9766 5.00026 17.9867 5Z" fill="#0944AA" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.66675 11H10.6667" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.66675 9V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.6667 12H15.6767" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.6667 10H18.6767" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <div class="name hidden">{{ lang.trans_406 }}</div>

                </div>

                <div class="wrap start">

                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_120_3717)">
                        <path d="M17.3333 21V19C17.3333 17.9391 16.9118 16.9217 16.1617 16.1716C15.4115 15.4214 14.3941 15 13.3333 15H5.33325C4.27239 15 3.25497 15.4214 2.50482 16.1716C1.75468 16.9217 1.33325 17.9391 1.33325 19V21" fill="#0944AA"/>
                        <path d="M17.3333 21V19C17.3333 17.9391 16.9118 16.9217 16.1617 16.1716C15.4115 15.4214 14.3941 15 13.3333 15H5.33325C4.27239 15 3.25497 15.4214 2.50482 16.1716C1.75468 16.9217 1.33325 17.9391 1.33325 19V21H17.3333Z" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.33325 11C11.5424 11 13.3333 9.20914 13.3333 7C13.3333 4.79086 11.5424 3 9.33325 3C7.12411 3 5.33325 4.79086 5.33325 7C5.33325 9.20914 7.12411 11 9.33325 11Z" fill="#0944AA" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M23.3332 20.9999V18.9999C23.3326 18.1136 23.0376 17.2527 22.4946 16.5522C21.9516 15.8517 21.1914 15.3515 20.3333 15.1299" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.3333 3.12988C17.1937 3.35018 17.9563 3.85058 18.5009 4.55219C19.0455 5.2538 19.3411 6.11671 19.3411 7.00488C19.3411 7.89305 19.0455 8.75596 18.5009 9.45757C17.9563 10.1592 17.1937 10.6596 16.3333 10.8799" stroke="#0944AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_120_3717">
                        <rect x="0.333252" width="24" height="24" rx="4" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>

                    <div class="name hidden">{{ lang.trans_408 }}</div>

                </div>

                <div class="wrap hidden start">
                    <div class="name">{{ lang.trans_601002 }}</div>
                </div>

                <div class="wrap end">
                    
                </div>

            </div>

            <div v-for="game in filter_games" :key="game.id" class="wrap center">


                <router-link :to="{ name: `operator.tacts`, params: { id: game.id }}" class="wrap start">
                    <div class="name">{{ game.name }}</div>
                </router-link>

                <div class="wrap start">
                    <div :class="['status', get_status(game.status)]"></div>
                    <div :class="['name', 'hidden', get_status(game.status)]">{{ get_lang_status(game.status) }}</div>
                </div>

                <div class="wrap start">
                    <div class="name">{{ game.teams ? game.teams.length : 0 }}</div>
                </div>

                <div v-if="game.user && game.user.id != 0" class="wrap hidden start">

                    <router-link v-if="game.user" :to="{ name: `user`, params: { id: game.user.id }}" class="name">
                        {{ game.user.name }}
                    </router-link>

                </div>
                <div v-else class="wrap hidden start">
                    <div v-if="game.user && game.user.id != 0" class="name">{{ game.user.name }}</div>
                </div>


                <div class="wrap end">
                    <Delete :name="lang.trans_415" :desc="`${game.name} ${lang.trans_601003}`" :event="async () => await remove(game.id)"/>
                </div>

            </div>

        </div>


        <div v-if="filter_games && !filter_games.length" class="center">
            <div class="not-games">
                <svg viewBox="0 0 234 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.3">
                        <path d="M143.65 46.4667C143.821 45.2708 143.992 44.075 143.992 42.7083C143.992 41.3417 143.821 40.1458 143.65 38.95L151.338 33.4833C152.021 32.9708 152.363 31.9458 151.85 31.0917L144.333 18.45C143.821 17.5958 142.967 17.2542 142.113 17.7667L133.571 21.6958C131.521 20.1583 129.471 18.9625 127.079 17.9375L126.225 8.54165C126.054 7.68748 125.371 7.00415 124.517 7.00415H109.825C108.971 7.00415 108.117 7.68748 108.117 8.54165L107.263 17.9375C104.871 18.9625 102.65 20.1583 100.771 21.6958L92.2292 17.7667C91.375 17.425 90.35 17.7667 90.0084 18.45L82.6625 31.0917C82.15 31.9458 82.4917 32.9708 83.175 33.4833L90.8625 38.95C90.6917 40.1458 90.5209 41.3417 90.5209 42.7083C90.5209 44.075 90.6917 45.2708 90.8625 46.4667L82.8334 51.9333C82.15 52.4458 81.8084 53.4708 82.3209 54.325L89.6667 66.9667C90.1792 67.8208 91.0334 68.1625 91.8875 67.65L100.429 63.7208C102.479 65.2583 104.529 66.4542 106.921 67.4792L107.775 76.875C107.946 77.7292 108.629 78.4125 109.483 78.4125H124.175C125.029 78.4125 125.883 77.7292 125.883 76.875L126.738 67.4792C129.129 66.4542 131.35 65.2583 133.229 63.7208L141.771 67.65C142.625 67.9917 143.65 67.65 143.992 66.9667L151.338 54.325C151.85 53.4708 151.508 52.4458 150.825 51.9333L143.65 46.4667V46.4667ZM117 59.7917C107.604 59.7917 99.9167 52.1042 99.9167 42.7083C99.9167 33.3125 107.604 25.625 117 25.625C126.396 25.625 134.083 33.3125 134.083 42.7083C134.083 52.1042 126.396 59.7917 117 59.7917Z" fill="#5E5E5E"/>
                        <path d="M117 22.2084C105.725 22.2084 96.5 31.4334 96.5 42.7084C96.5 53.9834 105.725 63.2084 117 63.2084C128.275 63.2084 137.5 53.9834 137.5 42.7084C137.5 31.4334 128.275 22.2084 117 22.2084ZM117 51.25C112.217 51.25 108.458 47.4917 108.458 42.7084C108.458 37.925 112.217 34.1667 117 34.1667C121.783 34.1667 125.542 37.925 125.542 42.7084C125.542 47.4917 121.783 51.25 117 51.25Z" fill="#5E5E5E"/>
                    </g>
                </svg>
                <div class="name">{{ lang.trans_601001 }}</div>
            </div>
        </div>

        <div class="btm" @click="game_add_pop_up = true">

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2" width="20" height="20" rx="10" stroke="url(#paint0_linear_171_2131)" stroke-width="2"/>
                <path d="M12 7.75736V16.2426" stroke="url(#paint1_linear_171_2131)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.75732 12H16.2426" stroke="url(#paint2_linear_171_2131)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                <linearGradient id="paint0_linear_171_2131" x1="25.2206" y1="-2.52411" x2="-7.08562" y2="24.8397" gradientUnits="userSpaceOnUse">
                <stop stop-color="#003DA7"/>
                <stop offset="1" stop-color="#50C7FF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_171_2131" x1="11.7234" y1="6.11446" x2="10.675" y2="18.7724" gradientUnits="userSpaceOnUse">
                <stop stop-color="#003DA7"/>
                <stop offset="1" stop-color="#50C7FF"/>
                </linearGradient>
                <linearGradient id="paint2_linear_171_2131" x1="11.7234" y1="6.11446" x2="10.675" y2="18.7724" gradientUnits="userSpaceOnUse">
                <stop stop-color="#003DA7"/>
                <stop offset="1" stop-color="#50C7FF"/>
                </linearGradient>
                </defs>
            </svg>

            <div class="name">
                {{ lang.trans_187 }}
            </div>

        </div>


        <games-add-temp v-if="game_add_pop_up" :close="game_add_pop_up_close"></games-add-temp>

        <pop-up v-if="pop_up.active" :name="pop_up.name" :desc="pop_up.desc" :ok="pop_up.ok" :close="pop_up.close"></pop-up>

    </div>
</template>

<script>

import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import GamesAddTemp  from '@/components/GamesAddTemp.vue'
import InputBool  from '@/components/form/InputBool.vue'

import PopUp  from '@/components/PopUp.vue'
import Delete from '@/components/button/Delete.vue'



import { trun, get_lang_name }  from '@/api/funcs'
import { requests } from "@/api/funcs";


export default {

    components: {
        "input-bool": InputBool,
        'games-add-temp': GamesAddTemp,
        'pop-up': PopUp,
        Delete
    },

    setup(){

        const router            = useRouter()
        const store             = useStore()
        const domain            = computed(() => store.getters.domain)
        const cookie            = computed(() => store.getters.cookie)
        const user              = computed(() => store.getters.user)
        const games             = computed(() => store.getters.games)
        const lang              = computed(() => store.getters.lang)
        const is_auth           = computed(() => store.getters.is_auth)
        const template          = computed(() => store.getters.template)
        const admin_view_games  = ref(false)

        const filter_games      = computed(() => admin_view_games.value ? games.value.filter((game) => game.user.id == user.value.id) : games.value)


        async function update_langs() {

            const [err, code] = await requests(`${domain.value}/api/langs`, 'PUT', cookie.value)
            if (err) {
                store.dispatch('update_err_by_code', code); return;
            }

        }

        const game_add_pop_up = ref(false)
        function game_add_pop_up_close(){
            game_add_pop_up.value = false
        }

        //обьект для компонента
        const pop_up  = ref({
            id: 0,
            name: '',
            desc: '',
            ok: null,
            close: () => pop_up.value.active = false,
            active: false,
        })


        async function remove(id) {
            await store.dispatch('delete_games', id); await store.dispatch('get_operator_games')
        }

        onMounted(async () => {

            if(!is_auth.value){
                router.push({ name: `login`, params: { template: template.value.alt_name } }); return
            }

            await store.dispatch('get_operator_games')

        })

        function get_status(status) {
            switch(status){
                case 'Не активна':  return 'not-active'
                case 'Активна':     return 'active'
                case 'Завершена':   return 'finished'
            }
        }

        function get_lang_status(status) {
            switch(status){
                case 'Не активна':  return get_lang_name(lang.value, 412)
                case 'Активна':     return get_lang_name(lang.value, 413)
                case 'Завершена':   return get_lang_name(lang.value, 414)
            }
        }

        return { 
            admin_view_games,
            user,
            update_langs,
            get_lang_status,
            trun,
            game_add_pop_up_close, 
            game_add_pop_up, 
            get_status, 
            lang, 
            filter_games, 
            pop_up, 
            remove
        }

    }
}

</script>