<template>
    <div class="detail">

        <div class="market">
            
            <div class="top">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M0.600586 16.4686V18.1952C0.600586 18.6721 0.986898 19.0584 1.46385 19.0584H10.0965L9.9394 19.9217L9.23325 23.8064H7.17695C6.84977 23.8064 6.55108 23.9911 6.40476 24.2838C6.11772 24.8574 6.53511 25.5329 7.17695 25.5329H19.0589C19.7008 25.5329 20.1182 24.8574 19.8311 24.2838C19.6848 23.9911 19.3857 23.8064 19.0589 23.8064H17.0026L16.2965 19.9217L16.1394 19.0584H24.772C25.249 19.0584 25.6353 18.6721 25.6353 18.1952V16.4686H0.600586ZM14.4128 18.1952H11.823V17.3319H14.4128V18.1952Z" fill="currentColor"></path><path d="M22.6137 6.10944H16.5708V6.97271H22.6137V6.10944Z" fill="currentColor"></path><path d="M19.1606 4.38293H16.5708V5.2462H19.1606V4.38293Z" fill="currentColor"></path><path d="M19.1606 7.836H16.5708V8.69927H19.1606V7.836Z" fill="currentColor"></path><path d="M24.772 0.498169H1.46385C0.986898 0.498169 0.600586 0.884481 0.600586 1.36144V15.6053H25.6353V1.36144C25.6353 0.884481 25.249 0.498169 24.772 0.498169ZM7.07508 13.8788C4.45291 13.8788 2.32712 11.753 2.32712 9.13083C2.32712 6.50866 4.45291 4.38287 7.07508 4.38287V9.13083H11.823C11.823 11.753 9.69725 13.8788 7.07508 13.8788ZM8.80161 7.4043V2.65633C11.4238 2.65633 13.5496 4.78213 13.5496 7.4043H8.80161ZM23.9088 13.8788H15.2761V2.2247H23.9088V13.8788Z" fill="currentColor"></path><path d="M19.1606 11.289H16.5708V12.1523H19.1606V11.289Z" fill="currentColor"></path><path d="M22.6137 9.56238H16.5708V10.4256H22.6137V9.56238Z" fill="currentColor"></path></g><defs><clipPath id="clip0"><rect width="25.0347" height="25.0347" fill="white" transform="translate(0.600586 0.498169)"></rect></clipPath></defs></svg>
                <div class="name">{{ lang.trans_56 }}</div>
            </div>

            <div class="wrap">

                <div v-for="market in active_markets" :key="market.id" class="wrap">

                    <div class="name">
                        {{ get_lang_name(lang, market.alt_name) }}
                    </div>

                    <div class="steps">

                        <div v-for="step in get_steps()" :key="step.id" :class="[`step`, {active: step.status == 'Активен'}]">
                            <div class="name">{{ lang.trans_16 }} {{ step.step_number }}</div>

                            <div v-if="is_forecast(step, market.name)" class="desc">{{ get_params(step, market.name) }}</div>
                            <div v-else class="bottom" @click="create_forecast(market.name, step)">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="funnel-dollar" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-funnel-dollar fa-w-20 fa-2x"><path fill="currentColor" d="M433.46 165.94l101.2-111.87C554.61 34.12 540.48 0 512.26 0H31.74C3.52 0-10.61 34.12 9.34 54.07L192 256v155.92c0 12.59 5.93 24.44 16 32l79.99 60c20.86 15.64 48.47 6.97 59.22-13.57C310.8 455.38 288 406.35 288 352c0-89.79 62.05-165.17 145.46-186.06zM480 192c-88.37 0-160 71.63-160 160s71.63 160 160 160 160-71.63 160-160-71.63-160-160-160zm16 239.88V448c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V256c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.04 44.44-42.67 45.07z" class=""></path></svg>
                                <div class="name">{{ lang.trans_63 }} ({{ format_number(game.game.forecast_price) }} ₽)</div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { format_number, get_lang_name, requests } from "@/api/funcs"


export default {

    setup(){

        const route     = useRoute()
        const store     = useStore()
        const domain    = computed(() => store.getters.domain)
        const cookie    = computed(() => store.getters.cookie)
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)

        async function create_forecast(name, step){

            store.dispatch('global_block_window', true)

            const [ err, code ] =  await requests(`${domain.value}/api/forecasts/steps/${step.id}/tacts/${game.value.tact.id}`, 'PUT', cookie.value, {type: name,})

            if(err){
                store.dispatch('update_err_by_code', code); store.dispatch('global_block_window', false); return
            }

            await store.dispatch('get_me_game', route.params.id)


            store.dispatch('global_block_window', false)

        }

        const active_markets = computed(() => game.value.game.variant ? markets.value : markets.value.filter((o) => o.default))

        const markets = ref([

            { id: 1, name: 'Количество сырья для снабжение',            alt_name: 341, default: true, },
            { id: 2, name: 'Минимальная цена сырья',                  alt_name: 240, default: true, },
            { id: 3, name: 'Прогноз спроса на стандартную продукцию', alt_name: 342, default: true, },
            { id: 4, name: 'Максимальная цена стандартной продукции', alt_name: 242, default: true, },
            { id: 5, name: 'Прогноз спроса на премиум продукцию',     alt_name: 343, default: false, },
            { id: 6, name: 'Максимальная цена премиум продукции',     alt_name: 244, default: false, },

        ])


        function get_steps(){

            let temp = []

            temp.push(game.value.step)

            const next_step = game.value.steps.filter((o) => o.step_number == game.value.step.step_number + 1)
            if(next_step.length > 0){

                temp.push(next_step[0])

                const next_step_2 = game.value.steps.filter((o) => o.step_number == game.value.step.step_number + 2)
                if(next_step_2.length > 0){
                    temp.push(next_step_2[0])
                }
            }

            return temp
        }


        function is_forecast(step, name){

            switch(name){

                case "Количество сырья для снабжение":
                    return game.value.forecasts.filter((o) => o.type == "Количество сырья для снабжение" && o.step_id == step.id).length > 0 ? true : false

                case "Минимальная цена сырья":
                    return game.value.forecasts.filter((o) => o.type == "Минимальная цена сырья" && o.step_id == step.id).length > 0 ? true : false

                case 'Прогноз спроса на стандартную продукцию':
                    return game.value.forecasts.filter((o) => o.type == "Прогноз спроса на стандартную продукцию" && o.step_id == step.id).length > 0 ? true : false

                case 'Максимальная цена стандартной продукции':
                    return game.value.forecasts.filter((o) => o.type == "Максимальная цена стандартной продукции" && o.step_id == step.id).length > 0 ? true : false
                
                case 'Прогноз спроса на премиум продукцию':
                    return game.value.forecasts.filter((o) => o.type == "Прогноз спроса на премиум продукцию" && o.step_id == step.id).length > 0 ? true : false

                case 'Максимальная цена премиум продукции':
                    return game.value.forecasts.filter((o) => o.type == "Максимальная цена премиум продукции" && o.step_id == step.id).length > 0 ? true : false

                default:
                    return false

            }
        }
        

        function get_params(step, name){

            switch(name){

                case "Количество сырья для снабжение":
                    return format_number(step.raw_market)

                case "Минимальная цена сырья":
                    return format_number(step.raw_price)

                case 'Прогноз спроса на стандартную продукцию':
                    return format_number(step.production_market)

                case 'Максимальная цена стандартной продукции':
                    return format_number(step.production_price)
                
                case 'Прогноз спроса на премиум продукцию':
                    return format_number(step.count_premium_products_market)

                case 'Максимальная цена премиум продукции':
                    return format_number(step.max_premium_products_price)

                default:
                    return 0

            }

        }


        return {
            format_number,
            get_lang_name,
            lang,
            game,

            create_forecast,
            is_forecast,
            get_params,
            get_steps,
            active_markets,
        }
    }
}
</script>