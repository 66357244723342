<template>
    <div class="tacts">

        <div v-for="tact in tacts" :key="tact.id"  :class="[`tact`, get_status(tact.status)]">

            <div v-dompurify-html="tact.icon" class="icon"></div>

            <div class="name">
                {{ get_tact_name(tact.type) }}
            </div>

            <input type="time" :value="tact.end_time" step="2" @input="test(tact, $event.target)"/>

            <div v-show="tact.status == `Завершен` || tact.status == `Активен`" class="wrap" @click="open_pop_up(tact)">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_586_8106)">
                    <path d="M3.09995 2.16675V7.76675H8.69995" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.09994 12.4334C3.29812 14.3056 4.1195 16.057 5.43228 17.4064C6.74505 18.7559 8.4731 19.6253 10.3391 19.875C12.2052 20.1247 14.101 19.7403 15.7224 18.7834C17.3438 17.8266 18.5967 16.3529 19.2802 14.5986C19.9637 12.8444 20.038 10.9114 19.4913 9.10989C18.9446 7.30835 17.8085 5.7427 16.2654 4.66417C14.7223 3.58564 12.8616 3.05676 10.9819 3.16241C9.1022 3.26806 7.31248 4.0021 5.89994 5.24675L3.09994 7.76675" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_586_8106">
                    <rect width="22.4" height="22.4" fill="white" transform="matrix(-1 0 0 1 22.7 0.300049)"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>

        </div>

        <pop-up v-if="pop_up.active" :name="pop_up.name" :desc="pop_up.desc" :ok="pop_up.ok" :close="pop_up.close"></pop-up>


        <div class="time all">

            <div></div>

            <div class="name">
                {{ lang.trans_496 }}
            </div>

            <div class="time">
                {{ time_all() }}
            </div>

            <div></div>

        </div>

    </div>
</template>

<script>

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import PopUp  from '@/components/PopUp.vue'

import { get_lang_name }  from '@/api/funcs'


export default {

    components: {
        'pop-up': PopUp,
    },

    setup(){

        const route     = useRoute()
        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const err       = computed(() => store.getters.err)

        const game      = computed(() => store.getters.game)
        const step      = computed(() => store.getters.selected_step)
        const tacts     = computed(() => step.value.tacts.filter((o) => o.active))

        //обьект для компонента
        const pop_up  = ref({
            id: 0,
            name: '',
            desc: '',
            ok: null,
            close: () => pop_up.value.active = false,
            active: false,
        })

        function get_tact_name(type){
            switch(type){
                case "Снабжение":       return get_lang_name(lang.value, 96)
                case "Производство":    return get_lang_name(lang.value, 97)
                case "Продажи":         return get_lang_name(lang.value, 98)
                case "Издержки":        return get_lang_name(lang.value, 209)
                case "Инвестиции":      return get_lang_name(lang.value, 258)
                case "Кредит":          return get_lang_name(lang.value, 22)
            }
        }

        //Функция для иницыализации и открытия компонента
        async function open_pop_up(tact) {

            pop_up.value.id     = tact.id

            pop_up.value.name   = lang.value.trans_421
            pop_up.value.desc   = lang.value.trans_422

            pop_up.value.ok     = async () => {
                await tact_init(tact); pop_up.value.active = false
                await unlock()
            }

            pop_up.value.active = true

        }

        async function test(object, target){

            if(object.status == 'Завершен' || object.status == 'Активен') {
                target.value = object.end_time; return
            }

            const temp = object.end_time

            //let result = target.value

            //target.value = result

            object.end_time = target.value

            //устанавливаем время
            await store.dispatch('update_tacts', object)

            if(err.value.is){
                object.end_time = temp
            }

        }
        
        function get_status(status) {
            switch(status){
                case 'Не начат':    return 'not-active'
                case 'Активен':     return 'active'
                case 'Завершен':    return 'finished'
            }
        }

        async function unlock(){

            await store.dispatch('global_block_window', false)

            //обновляем данные на странице с игрой, у пользователей
            await store.dispatch('socket_send', {
                command: "update_game", 
                gid: game.value.id,
            })

        }

        async function tact_init(tact) {
            
            //блокируем окошко юзерам
            await store.dispatch('global_block_window', true)

            //производим вычисления
            await store.dispatch('tact_init', tact.id)

            await unlock()

            await store.dispatch('get_game', route.params.id)

            store.dispatch('global_block_window', false)

        }

        function time_all(){

            let temp_h = 0
            let temp_m = 0
            let temp_s = 0

            for(let tact of tacts.value){

                let [h, m, s] = tact.end_time.split(':')

                temp_s += parseInt(s, 10)

                if(temp_s >= 60){
                    temp_s -= 60; temp_m++
                }

                temp_m += parseInt(m, 10)
                if(temp_m >= 60){
                    temp_m -= 60; temp_h++
                }

                temp_h += parseInt(h, 10)

            }


            temp_h = temp_h < 10 ? `0${temp_h}` : `${temp_h}`
            temp_m = temp_m < 10 ? `0${temp_m}` : `${temp_m}`
            temp_s = temp_s < 10 ? `0${temp_s}` : `${temp_s}`


            return `${temp_h}:${temp_m}:${temp_s}`
        }
        
        return {
            get_tact_name,
            pop_up,
            open_pop_up,
            tact_init,
            get_status,
            test,
            lang,
            tacts,
            time_all,
        }

    }
}

</script>

<style scoped>

.tacts {
    padding: 10px 0;
}

.tacts > .time.all {
    display: grid;
    grid-template-columns: 40px 1fr auto 30px;
    grid-gap: 10px;
    font-weight: 500;
    font-size: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;
    color: #5E5E5E;
    padding: 15px 10px;
}

@media (min-width: 1250px) {
    .tacts > .time.all {
        font-size: 24px;
    }
}

.tacts > .time.all > .name {
    overflow: hidden;
}

.tacts > .time.all > .time {
    margin-right: 33px;
}


.tacts > .tact {
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 40px 1fr auto 30px;
    grid-gap: 10px;
    height: 65px;
}

.tacts > .tact.not-active {
    color: #0944AA;
}

.tacts > .tact.active {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
    color: white;
}

.tacts > .tact.finished {
    color: #5A6671;
}

.tacts > .tact > .icon {
    display: flex;
    align-items: center;
}

.tacts > .tact > .name {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 490px) {
    .tacts > .tact > .name {
       max-width: 100%;
    }
}

@media (min-width: 1250px) {
    .tacts > .tact > .name {
       font-size: 24px;
    }
}

.tacts > .tact > input {
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: inherit;
    background: none;
    font-family: 'Gilroy';
}

@media (min-width: 1250px) {
    .tacts > .tact > input {
       font-size: 20px;
    }
}

.tacts > .tact > .wrap {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
    border-radius: 4px;
    padding: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
}

</style>