<template>
    <div class="block">

        <div class="top">

            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.37519 7.93751H15.6248C15.9227 7.93751 16.2011 7.79471 16.3797 7.55626C17.7453 5.73319 18.4376 3.71664 18.4376 1.56251V0.937501C18.4376 0.419751 18.0178 0 17.5 0C15.7638 0 13.4991 1.1245 12.5 1.67163C11.5009 1.1245 9.23625 0 7.49999 0C6.98224 0 6.5625 0.419751 6.5625 0.937501V1.56251C6.5625 3.71664 7.25481 5.73319 8.62032 7.55626C8.79888 7.79471 9.07737 7.93751 9.37519 7.93751Z" fill="currentColor"/>
                <path d="M13.2258 21.7168V24.3711C13.7047 24.1126 13.9892 23.6354 14.0653 23.1828C14.1197 22.8597 14.1419 22.084 13.2258 21.7168Z" fill="currentColor"/>
                <path d="M11.192 18.333C11.1263 18.6817 11.2108 19.0067 11.4074 19.1609C11.4842 19.2212 11.5707 19.2801 11.6632 19.338V17.5735C11.3903 17.7644 11.2417 18.0699 11.192 18.333Z" fill="currentColor"/>
                <path d="M17.625 23.6459V18.9946C17.625 17.6953 18.5595 16.6013 19.847 16.3932C20.513 16.2855 21.0922 15.8702 21.4108 15.2777C20.0678 13.4202 18.4866 11.6904 16.9191 10.0934C16.7428 9.91369 16.5016 9.8125 16.25 9.8125H8.75001C8.49833 9.8125 8.25726 9.91369 8.08095 10.0933C4.08494 14.1646 0 19.0971 0 24.8125L6.21225e-05 24.8798C0.00881144 28.8059 3.25576 32.0001 7.23819 32.0001H17.7619C18.8753 32.0001 19.9311 31.7502 20.8749 31.3046C18.843 29.3422 17.625 26.602 17.625 23.6459ZM15.6061 23.4421C15.4869 24.1514 15.1302 24.8094 14.6014 25.2952C14.2061 25.6584 13.7392 25.907 13.2258 26.0328V26.625C13.2258 27.0565 12.876 27.4062 12.4445 27.4062C12.013 27.4062 11.6633 27.0565 11.6633 26.625V26.1058C11.0088 26.0517 10.4702 25.879 9.70414 25.3779C9.34308 25.1417 9.24183 24.6576 9.47802 24.2965C9.7142 23.9353 10.1983 23.8341 10.5595 24.0703C11.0025 24.3601 11.2852 24.4838 11.6633 24.5339V21.102C11.145 20.8652 10.7591 20.638 10.4431 20.3901C9.78282 19.8722 9.48139 18.973 9.65658 18.0434C9.84764 17.0298 10.553 16.2232 11.4975 15.9386C11.5533 15.9219 11.6086 15.9065 11.6633 15.8923V15.375C11.6633 14.9435 12.013 14.5938 12.4445 14.5938C12.876 14.5938 13.2258 14.9435 13.2258 15.375V15.8057C14.0095 15.9367 14.5629 16.321 14.8299 16.6273C15.1133 16.9526 15.0795 17.4461 14.7541 17.7296C14.4304 18.0117 13.9401 17.9794 13.656 17.6584C13.6373 17.6395 13.4971 17.5034 13.2258 17.4099V20.0538C13.3734 20.1087 13.521 20.1619 13.6658 20.2131C15.0897 20.7164 15.8513 21.9838 15.6061 23.4421Z" fill="currentColor"/>
                <path d="M31.3538 18.2442C30.0809 18.0384 28.9663 17.2326 28.3795 16.0551C28.2998 15.895 28.1339 15.7961 27.955 15.7961H23.5451C23.3662 15.7961 23.2003 15.895 23.1205 16.0551C22.5338 17.2326 21.4192 18.0384 20.1463 18.2442C19.7755 18.3042 19.5 18.6189 19.5 18.9945V23.646C19.5 27.4146 21.9056 30.7491 25.4531 31.9512C25.6454 32.0165 25.8548 32.0165 26.0471 31.9512C29.5946 30.7491 32 27.4146 32 23.646V18.9945C32 18.6189 31.7246 18.3042 31.3538 18.2442ZM29.0646 22.8162L25.5291 26.3518C25.3461 26.5348 25.1062 26.6264 24.8662 26.6264C24.6263 26.6264 24.3864 26.5348 24.2033 26.3518L22.4357 24.584C22.0694 24.2179 22.0694 23.6243 22.4357 23.2582C22.8016 22.8921 23.3954 22.8921 23.7615 23.2582L24.8662 24.363L27.739 21.4904C28.1051 21.1243 28.6987 21.1243 29.0648 21.4904C29.4307 21.8566 29.4307 22.4502 29.0646 22.8162Z" fill="currentColor"/>
            </svg>

            <div class="name">
                {{ lang.trans_209 }}
            </div>

        </div>

        <div class="center">

            <img :src="require(`@/assets/images/rules/${template.alt_name}/costs.svg`)">

            <div class="wrap">

                <div class="name">
                    {{ lang.trans_479 }}
                </div>

                <div class="wrap_v2">

                    <div class="name">
                        {{ lang.trans_480 }}
                    </div>

                    <div class="wrap">

                        <div class="name">
                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.5859 8.85767L15.8125 7.4436V3.20532C15.8125 2.61938 15.4492 2.09595 14.8984 1.88892L10.9922 0.424072C10.6758 0.302979 10.3242 0.302979 10.0039 0.424072L6.09766 1.88892C5.54688 2.09595 5.18359 2.61938 5.18359 3.20532V7.4436L1.41016 8.85767C0.863281 9.0647 0.5 9.58813 0.5 10.1741V14.4749C0.5 15.0061 0.800781 15.4944 1.27734 15.7327L5.18359 17.6858C5.57812 17.885 6.04688 17.885 6.44141 17.6858L10.5 15.6545L14.5586 17.6858C14.9531 17.885 15.4219 17.885 15.8164 17.6858L19.7227 15.7327C20.1992 15.4944 20.5 15.0061 20.5 14.4749V10.1741C20.5 9.58813 20.1367 9.0647 19.5859 8.85767ZM14.4844 7.47485L11.1641 8.72095V6.05688L14.4844 4.61157V7.47485ZM6.51562 3.15063L10.5 1.65845L14.4844 3.15063V3.17407L10.5 4.79126L6.51562 3.17407V3.15063ZM9.79688 14.5217L6.47656 16.1819V13.092L9.79688 11.5764V14.5217ZM9.79688 10.1467L5.8125 11.7639L1.82812 10.1467V10.1233L5.8125 8.6311L9.79688 10.1233V10.1467ZM19.1719 14.5217L15.8516 16.1819V13.092L19.1719 11.5764V14.5217ZM19.1719 10.1467L15.1875 11.7639L11.2031 10.1467V10.1233L15.1875 8.6311L19.1719 10.1233V10.1467Z" fill="currentColor"/>
                            </svg>
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.warehouse_costs_raw) }}₽
                        </div>

                        <div class="name">

                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.28733 5.02066L8.09401 4.5L4.80334 5.93799L5.99668 6.45999L9.28733 5.02066Z" fill="currentColor"/>
                                <path d="M19.0033 4.27258L10 0.333252L1 4.27258L3.14266 5.2099L7.82665 3.16124C7.91076 3.12454 8.00155 3.10559 8.09332 3.10559C8.18508 3.10559 8.27587 3.12454 8.35999 3.16124L11.2199 4.40591C11.3387 4.45789 11.4396 4.54333 11.5105 4.65178C11.5814 4.76024 11.6191 4.887 11.6191 5.01658C11.6191 5.14615 11.5814 5.27291 11.5105 5.38137C11.4396 5.48982 11.3387 5.57526 11.2199 5.62724L7.65999 7.1879L10 8.2119L19.0033 4.27258Z" fill="currentColor"/>
                                <path d="M0 5.29199V17.1972L9.33331 21.9452V9.37532L6.66665 8.20599V11.606C6.6667 11.7196 6.6377 11.8314 6.5824 11.9307C6.5271 12.03 6.44733 12.1134 6.35068 12.1733C6.25402 12.233 6.14369 12.2671 6.03015 12.2723C5.91661 12.2774 5.80364 12.2535 5.70198 12.2026L3.03532 10.8693C2.92451 10.8139 2.8313 10.7287 2.76619 10.6234C2.70107 10.518 2.6666 10.3965 2.66666 10.2727V6.45866L0 5.29199Z" fill="currentColor"/>
                                <path d="M4 9.86065L5.33333 10.5273V7.62532L4 7.04199V9.86065Z" fill="currentColor"/>
                                <path d="M20 5.29199L10.6666 9.37532V21.9513L20 17.2613V5.29199ZM11.7006 15.7706L14.3333 14.4473C14.4116 14.4079 14.4967 14.3843 14.5841 14.3779C14.6714 14.3713 14.7592 14.3822 14.8424 14.4097C14.9255 14.4371 15.0025 14.4807 15.0689 14.5379C15.1351 14.595 15.1896 14.6648 15.229 14.7429C15.2684 14.8212 15.2919 14.9064 15.2984 14.9938C15.3048 15.0812 15.2941 15.1689 15.2666 15.252C15.2392 15.3352 15.1956 15.4121 15.1384 15.4785C15.0813 15.5448 15.0115 15.5992 14.9333 15.6386L12.3 16.962C12.2218 17.0014 12.1365 17.0249 12.0491 17.0314C11.9618 17.0379 11.874 17.0271 11.7909 16.9996C11.7077 16.9722 11.6308 16.9286 11.5645 16.8714C11.4981 16.8142 11.4437 16.7445 11.4043 16.6663C11.3649 16.5881 11.3413 16.5028 11.3349 16.4155C11.3284 16.3281 11.3392 16.2404 11.3666 16.1572C11.3941 16.0741 11.4376 15.9971 11.4949 15.9308C11.5521 15.8645 11.6224 15.81 11.7006 15.7706ZM14.9707 18.286L12.2993 19.6286C12.221 19.668 12.1359 19.6917 12.0485 19.6981C11.9612 19.7046 11.8734 19.6937 11.7902 19.6663C11.7071 19.6388 11.6301 19.5952 11.5638 19.5381C11.4975 19.4809 11.443 19.4112 11.4036 19.333C11.3642 19.2547 11.3407 19.1696 11.3342 19.0822C11.3278 18.9949 11.3385 18.907 11.366 18.8239C11.3934 18.7407 11.437 18.6638 11.4942 18.5974C11.5514 18.5311 11.6211 18.4767 11.6994 18.4373L14.3706 17.0946C14.4489 17.0552 14.534 17.0317 14.6214 17.0251C14.7087 17.0187 14.7965 17.0295 14.8797 17.057C14.9629 17.0844 15.0398 17.128 15.1062 17.1852C15.1725 17.2424 15.2269 17.3121 15.2663 17.3903C15.3057 17.4685 15.3293 17.5538 15.3357 17.6411C15.3423 17.7284 15.3314 17.8162 15.304 17.8994C15.2765 17.9825 15.2329 18.0595 15.1757 18.1258C15.1186 18.1921 15.0489 18.2466 14.9707 18.286Z" fill="currentColor"/>
                            </svg>

                            <svg v-if="game.game.variant" class="left" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.369873 7.27344L9.93057 18.3131L6.74524 7.27344H0.369873Z" fill="currentColor"/>
                                <path d="M7.45508 7.27344L10.9953 19.5417L14.5357 7.27344H7.45508Z" fill="currentColor"/>
                                <path d="M3.29129 2.6272L0.333374 6.59071H6.2536L3.29129 2.6272Z" fill="currentColor"/>
                                <path d="M21.6667 6.59071L18.7087 2.6272L15.751 6.59071H21.6667Z" fill="currentColor"/>
                                <path d="M6.9317 6.35889L10.1445 2.05859H3.71924L6.9317 6.35889Z" fill="currentColor"/>
                                <path d="M18.281 2.05859H11.8513L15.0686 6.36336L18.281 2.05859Z" fill="currentColor"/>
                                <path d="M14.3858 6.59089L11.0001 2.05859L7.6145 6.59089H14.3858Z" fill="currentColor"/>
                                <path d="M12.0605 18.3178L21.6257 7.27368H15.2504L12.0605 18.3178Z" fill="currentColor"/>
                                <path d="M2.94304 2.37114L3.36092 1.43364L4.12863 1.18557L3.36092 0.93417L2.94304 0L2.52515 0.93417L1.75745 1.18557L2.52515 1.43364L2.94304 2.37114Z" fill="currentColor"/>
                                <path d="M1.86597 3.80283L2.12991 3.21593L2.61223 3.06103L2.12991 2.90184L1.86597 2.3103L1.59743 2.90184L1.11511 3.06103L1.59743 3.21593L1.86597 3.80283Z" fill="currentColor"/>
                                <path d="M5.29969 1.78113L5.50902 1.31689L5.8866 1.19405L5.50902 1.07121L5.29969 0.602539L5.09035 1.07121L4.70801 1.19405L5.09035 1.31689L5.29969 1.78113Z" fill="currentColor"/>
                            </svg>

                        </div>

                        <div class="name left">
                            {{ format_number(game.game.warehouse_costs_prod) }}₽
                        </div>

                    </div>

                </div>

                <div class="wrap_v2">

                    <div class="name">
                        {{ lang.trans_481 }}
                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_482 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.fixed_costs_ord) }}₽
                        </div>

                        <div class="name">
                            {{ lang.trans_28 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.fixed_costs_auto) }}₽
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div class="bottom">
            {{ lang.trans_483 }}
        </div>

    </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'
import { format_number } from "@/api/funcs";

export default {

    setup(){

        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)
        const template  = computed(() => store.getters.template)

        return {
            close,
            lang,
            game,
            template,
            format_number,
        }
    }
}
</script>