<template>
    <div class="count">

        <div class="name">{{ name }}</div>

        <div class="input range">

            <div class="wrap">
                
                <div class="wrap">
                    <transition name="bounce" mode="out-in">
                        <input type='text' :value="formattedValue" @input="test($event.target)" @keyup.enter="update"/>
                    </transition>

                </div>
                <input type="range" :style="`--filled-percentage: ${filledPercentage}`" :min="min" :max="max" :value="formattedValue" :step="max > 1000 ? 10 : 1" @input="test($event.target)">

            </div>

            <div class="del" @mousedown="startMin" @mouseup="stopMin" @mouseleave="stopMin">
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.50444 12.9149L2.35315 7.7636L7.50444 2.6123" stroke="currentColor" stroke-width="3.86347" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <div class="add" @mousedown="startAdd" @mouseup="stopAdd" @mouseleave="stopAdd">
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.26086 12.9149L7.41216 7.7636L2.26086 2.6123" stroke="currentColor" stroke-width="3.86347" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <div v-if="isDecision" :class="['tooltip', 'desition', {green: decision}]">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/><path fill="currentColor" d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6.414A2 2 0 0 0 19.414 5L17 2.586A2 2 0 0 0 15.586 2H6Zm10.238 8.793a1 1 0 1 0-1.414-1.414l-4.242 4.242l-1.415-1.414a1 1 0 0 0-1.414 1.414l2.05 2.051a1.1 1.1 0 0 0 1.556 0l4.88-4.879Z"/></g></svg>
                <span class="tooltiptext">Сохранено: {{ currentValue }}</span>
            </div>

        </div>

    </div>
  </template>
  
<script>

import { ref, watch, computed } from 'vue'

export default {

props: {
    name: {
        type: String,
        required: true,
    },

    model: {
        type: Number,
        required: true,
    },

    currentValue: {
        type: Number,
        required: true,
    },

    decision: {
        type: Boolean,
        required: true,
    },

    isDecision: {
        type: Boolean,
        default: true,
    },

    min: {
        type: Number,
        default: 0,
    },

    max: {
        type: Number,
        default: 100000,
    },


},

emits: ["update:model"],

setup(props, ctx){
    
    const formattedValue = ref(0)

    const model = computed(() => props.model)
    watch(model, (newValue) => {
        formattedValue.value = newValue
    })

    let minTimeout  = ref(null)
    let addTimeout  = ref(null)
    let minDelay    = ref(100)
    let addDelay    = ref(100)

    function clean(value){
        let valueWithoutSpaces = value.replace(/\s+/g, '');
        let newValue = parseInt(valueWithoutSpaces, 10);
        if(isNaN(newValue) || newValue < 0){
            newValue = 0
        }
        return value
    }

    async function test(target){
        
        let valueWithoutSpaces = target.value.replace(/\s+/g, '')

        let result = parseInt(valueWithoutSpaces, 10)

        if(isNaN(result) || props.min < 0){
            result = props.min
        }

        if(result > props.max){
            result = props.max
        }

        target.value = result

        ctx.emit("update:model", result);
    }

    function min() {

        if (props.model > 0) {

            const newValue = props.model - 1

            ctx.emit("update:model", newValue)

            updateMinDelay()

            minTimeout.value = setTimeout(min, minDelay.value)
        }

    }

    function add() {
        if(props.model < props.max){

            const newValue = props.model + 1

            ctx.emit("update:model", newValue)

            updateAddDelay()

            addTimeout.value = setTimeout(add, addDelay.value)
        }
      
    }

    function updateMinDelay() {
        minDelay.value = Math.max(minDelay.value * 0.9, 20)
    }

    function updateAddDelay() {
        addDelay.value = Math.max(addDelay.value * 0.9, 20)
    }

    function resetMinDelay() {
        minDelay.value = 100
    }

    function resetAddDelay() {
        addDelay.value = 100
    }

    function startMin() {
        min()
    }

    function stopMin() {
        clearTimeout(minTimeout.value)
        resetMinDelay()
    }

    function startAdd() {
        add()
    }

    function stopAdd() {
        clearTimeout(addTimeout.value)
        resetAddDelay()
    }

    const filledPercentage = computed(() => {
        const range = props.max - props.min;
        if (range === 0) return '0%';
        const relativeValue = props.model - props.min;
        return `${(relativeValue / range) * 100}%`;
    })

    return {
        startMin, stopMin, 
        startAdd, stopAdd,
        test,
        formattedValue,
        clean,
        filledPercentage
    }
}

};
</script>
  
<style scoped>

.input.range {
  position: relative;
}

.input.range > .wrap {
  position: relative;
  margin-right: 12px;
}

.input.range > .wrap > input[type="range"] {
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: -5px;
}

</style>
