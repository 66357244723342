<template>
    <div></div>
</template>

<script>

import { onMounted } from 'vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    setup(){

        const store     = useStore()
        const router    = useRouter()
        const template  = computed(() => store.getters.template)

        onMounted(() => {

            //редиректим на страницу логина с дефотным шаблоном
            router.push({ name: `login`, params: { template: template.value.alt_name } })

        })

        return { }
    }
}
</script>
