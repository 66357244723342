
import * as getters   from './getters'
import * as actions   from './actions'
import * as mutations from './mutations'


export default {

  state: {
    teams: [],
    team: {
      name: '',
    },
    teams_v2: [],
    decisions: [],

  },

  getters,
  mutations,
  actions,
  modules: {},

}


