
import { requests } from "@/api/funcs";



export const update_profiles = async ({ dispatch, getters }) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/profiles/${getters.profile.id}/`, 'PUT', getters.cookie, getters.profile)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const add_profiles = async ({ dispatch, getters }, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/profiles/`, 'POST', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const delete_profiles = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/profiles/${id}/`, 'DELETE', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const get_profiles = async ({ getters, commit, dispatch }) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/profiles/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_profiles', body)
}

export const get_profile = async ({ getters, commit, dispatch }, id) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/profiles/${id}/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_profile', body)
}
