

export const get_profiles = (state, profiles) => {
    state.profiles = profiles
}

export const get_profile = (state, profile) => {
    state.profile = profile
}

