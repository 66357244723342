

export const assistant_update = (state, bool) => {
    state.assistant = bool
}

export const alerts_del = (state) => {
    state.alerts.splice(0, 1,)
}

export const alerts_add = (state, alert) => {
    state.alerts.push(alert)
}
