

export const clean_operations = (state) => {
    state.operations = []
}

export const append_operations = (state, operation) => {

    state.operations.push(operation)

}

export const get_operations = (state, operations) => {
    state.operations.unshift(...operations.reverse())
}

export const get_operation = (state, operation) => {
    state.operation = operation
}