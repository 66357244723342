<template>
    <div clas="profile">

        <read></read>
        
        <edit></edit>

    </div>
</template>

<script>


import Edit from "@/components/profile/Edit.vue"
import Read from "@/components/profile/Read.vue"

export default {

    components: {
        "edit": Edit,
        "read": Read,
    },

    setup(){

        return {}
    }
}

</script>


<style scoped>

.operator.profiles > .form > div {
    background: white;
    padding: 1em;
    border-radius: 5px;
    margin-top: 10px;
}

.operator.profiles > .form > div > .btn {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
    border-radius: 4px;
    max-width: 135px;
    margin: 0 auto;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
}

.operator.profiles > .form > .btm {
    display: flex;
    background: none;
    padding: 1em 0;
}

.operator.profiles > .form > .btm > .send {
    background: linear-gradient(229.73deg, #00A76B -19.09%, #50FFAB 131.11%);
    border-radius: 8px;
    padding: 15px;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-right: 10px;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
}

.operator.profiles > .form > .btm > .cancel {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
    border-radius: 8px;
    padding: 15px;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    min-width: 300px;
    text-align: center;
    cursor: pointer;
}



</style>