
export const count_users = (state, count) => {
    state.count_users = count
}



export const clean_socket = (state) => {
    state.socket        = null
    state.socket_open   = false
    state.key           = ``
}



export const update_key = (state, key) => {
    state.key = key
}

export const socket_create = (state, object) => {
    state.socket = object.socket
    state.socket_open = object.socket_open
}

export const socket_send = (state, object) => {

    try{
        state.socket.send(object)
    }catch(err){

        console.log("1-я попытка открыть сокет...", object)

        setTimeout(() => {
            try{
                state.socket.send(object)
            }catch(err){

                console.log("2-я попытка открыть сокет...", object)

                setTimeout(() => {
                    try{
                        state.socket.send(object)
                    }catch(err){

                        console.log("3-я попытка открыть сокет...", object)
                        
                    }
                }, 2000)

            }
        }, 1000)
    }

}