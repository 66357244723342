


export const set_selected_step = (state, step) => {
    state.selected_step = step
}


export const set_step = (state, step) => {
    state.step = step
}

export const get_steps = (state, steps) => {
    state.steps = steps
}