
import { requests } from "@/api/funcs";

export const set_template = async ({ commit }, name) => {
    commit('set_template', name)
}

export const update_templates = async ({ getters, dispatch}, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/templates/${object.id}/`, 'PUT', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const add_templates = async ({ getters, dispatch}, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/templates/`, 'POST', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const delete_templates = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/templates/${id}/`, 'DELETE', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const get_template = async ({ commit, dispatch, getters }, id) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/templates/${id}/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_template', body)
}

//не важно авторизован ли юзер, делаем запрос на языки, если не авторизован то выдаем всю пачку
export const get_templates = async ({ commit, getters, dispatch }) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/templates`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_templates', body)
}
