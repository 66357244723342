<template>

    <div v-if="game.game" class="account">

        <div class="header">

            <div class="name game-name">
                <span v-dompurify-html="game.game.poster"></span>
                <h1>{{ lang.trans_15 }} {{ game.game.name }}</h1>
            </div>

            <div class="wrap_button">
                <div v-if="game.game.is_chart" class="name link">
                    <div class="button" @click="open_chart()">{{ lang.trans_451 }}</div>
                </div>

                <chart v-if="chart"></chart>

                <div class="name link">
                    <div class="button" @click="open_rules">{{ lang.trans_460 }}</div>
                </div>

                <rules v-if="is_rules"></rules>

                <div v-if="game.step.id" class="name link">
                    <router-link :to="{ name: `history`, params: {s_id: game.step.id }}" class="button">{{ lang.trans_361 }}</router-link>
                </div>

                <div v-if="game.game.is_fast_credit" class="name link">
                    <div class="button" @click="open_fast_credit()">{{ lang.trans_150 }}</div>
                </div>

                <fast-credit v-if="fast_credit"></fast-credit>
            </div>

        </div>

        <div class="stages">

            <div class="name">
                <div class="name">{{ lang.trans_16 }} № {{ game.step.step_number }}</div>

                <div v-if="game.tact" class="desc">{{ get_tact_name(game.tact) }}</div>

            </div>

            <div class="wrap">

                <div v-for="object in game.tacts" :key="object.id" :class="['stage', { 'active': object.type == 'Активен' }, { 'finished': object.status == 'Завершен' } ]">
                    
                    <div class="wrap">
                        <span v-dompurify-html="object.icon"></span>
                        <div class="name">{{ get_tact_name(object) }}</div>
                    </div>

                    <div :class="['line', { 'active': object.type == game.step.current_tact }]"></div>

                </div>

            </div>

        </div>

        <div class="status">

            <div class="left">

                <div class="top">

                    <div class="left">

                        <div class="wrap">
                            <div class="name">{{ lang.trans_17 }}</div>
                            <div class="desc">{{ game.team.name }}</div>
                        </div>

                        <div v-if="game.game.hidden_reiting" class="wrap">
                            <div class="name">{{ lang.trans_19 }}:</div>
                            <div class="desc">{{ game.step.step_number == 1 ? 1 : game.team.rating }}</div>
                        </div>

                    </div>


                </div>

                <div class="bottom">

                    <div class="panel">
                        <div class="name">{{ lang.trans_20 }}</div>

                        <div class="wrap">
                            <div class="name">{{ lang.trans_21 }}</div>
                            <div :class="['count', { 'red': game.team.bankruptcy }, { 'yellow': game.team.money < 0 },]">
                                {{ format_number(game.team.money) }}
                            </div>
                        </div>

                        <div class="wrap">
                            <div class="name">{{ lang.trans_22 }}</div>
                            <div class="count">{{ format_number(game.team.credit) }}</div>
                        </div>
                        
                        <div v-if="game.game.is_fast_credit" class="wrap">
                            <div class="name font x2">{{ lang.trans_150 }}</div>
                            <div class="count">{{ format_number(game.team.fast_credit) }}</div>
                        </div>

                        <img v-else :src="`/static/img/body_status_icon_${template.name}.png`"/>

                    </div>

                    <div class="panel">
                        <div class="name">{{ lang.trans_23 }}</div>

                        <div class="wrap">
                            <div class="name">{{ lang.trans_24 }}</div>
                            <div class="count">{{ game.team.materials }}</div>
                        </div>

                        <div class="wrap">
                            <div class="name">{{ !game.game.variant ? lang.trans_25 : lang.trans_145 }}</div>
                            <div class="count">{{ game.team.products }}</div>
                        </div>



                        <div v-if="game.game.variant" class="wrap">
                            <div class="name">{{ lang.trans_99 }}</div>
                            <div class="count">{{ game.team.premium_products }}</div>
                        </div>

                        <img v-if="!game.game.variant" :src="`/static/img/body_status_icon_2_${template.name}.png`"/>

                    </div>

                    <div class="panel">
                        <div class="name">{{ lang.trans_26 }}</div>

                        <div class="wrap">
                            <div class="name">{{ lang.trans_27 }}</div>
                            <div class="count">
                                {{ game.team.factorys }}
                            </div>
                        </div>

                        <div class="wrap">
                            <div class="name">{{ lang.trans_28 }}</div>
                            <div class="count">
                                {{ game.team.auto_factorys }}
                            </div>
                        </div>

                        <img :src="`/static/img/body_status_icon_3_${template.name}.png`"/>

                    </div>

                </div>

            </div>

            <div class="right">

                <img v-if="template.name == 'mangold-new-year' || template.name == 'mangold-new-year-v2'" :src="`/static/img/christmas_garland_left_mangold-new-year.png`" class="mishyra-1" />
                <img v-if="template.name == 'mangold-new-year' || template.name == 'mangold-new-year-v2'" :src="`/static/img/christmas_garland_right_mangold-new-year.png`" class="mishyra-2" />

                <div v-if="game.step.current_tact != 'Ход завершен'" class="top">

                    <div v-if="game.tact" class="left">

                        <div class="top">

                            <span v-dompurify-html="game.tact.icon"></span>

                            <div class="name">{{ get_tact_name(game.tact) }}</div>
                        </div>

                        <div :class="['desc', {'active': !game.team.bankruptcy },]">
                            {{ game_status_name() }}
                            <span v-if="game.tact.status == 'Активен'">
                                {{ game.game.is_pause ? game.game.time_pause_format : time }}
                            </span>
                        </div>

                    </div>

                    <div class="wrap" @click="update">
                        
                        <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0132 12.4769C14.1598 6.55491 22.322 5.11569 28.244 9.2623C30.8684 11.0999 32.6119 13.7269 33.3798 16.597L29.1653 17.7264C28.6535 15.8126 27.491 14.0614 25.7414 12.8364C21.7933 10.0718 16.3518 11.0313 13.5872 14.9795L16.0917 16.7331L8.3603 18.6403L7.50819 10.7229L10.0132 12.4769Z" fill="currentColor"/>
                            <path d="M15.7303 27.1336C19.6785 29.8981 25.12 28.9386 27.8845 24.9904L25.38 23.2368L33.1114 21.3296L33.9635 29.247L31.4586 27.493C27.3119 33.415 19.1497 34.8542 13.2277 30.7076C10.6034 28.87 8.85979 26.2431 8.09193 23.3729L12.3064 22.2435C12.8182 24.1574 13.9807 25.9085 15.7303 27.1336Z" fill="currentColor"/>
                        </svg>
                        
                        <div class="name">
                            {{ lang.trans_29 }}
                        </div>
                    </div>

                </div>

                <div v-else class="top">
                    
                    <div class="left">
                        <div class="top">
                           <div class="name">{{ lang.trans_30 }}</div>
                        </div>
                        <div :class="['desc', {'margin': !game.tact}]">
                            {{ lang.trans_31 }}
                        </div>
                    </div>

                    <div class="wrap" @click="update">

                        <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0132 12.4769C14.1598 6.55491 22.322 5.11569 28.244 9.2623C30.8684 11.0999 32.6119 13.7269 33.3798 16.597L29.1653 17.7264C28.6535 15.8126 27.491 14.0614 25.7414 12.8364C21.7933 10.0718 16.3518 11.0313 13.5872 14.9795L16.0917 16.7331L8.3603 18.6403L7.50819 10.7229L10.0132 12.4769Z" fill="currentColor"/>
                            <path d="M15.7303 27.1336C19.6785 29.8981 25.12 28.9386 27.8845 24.9904L25.38 23.2368L33.1114 21.3296L33.9635 29.247L31.4586 27.493C27.3119 33.415 19.1497 34.8542 13.2277 30.7076C10.6034 28.87 8.85979 26.2431 8.09193 23.3729L12.3064 22.2435C12.8182 24.1574 13.9807 25.9085 15.7303 27.1336Z" fill="currentColor"/>
                        </svg>

                        <div class="name">
                            {{ lang.trans_29 }}
                        </div>
                    </div>
                </div>


                <div v-if="game.tact && game.tact.type == 'Издержки'" class="top">
                    <div class="left">
                        <div class="desc">
                            {{ lang.trans_33 }}
                        </div>
                    </div>
                </div>

                <div v-if="game.tact && game.tact.type == 'Издержки'" class="special_block">
                    <div class="block">
                        <div class="name">
                            {{ lang.trans_34 }}
                        </div>
                        <div class="count">
                            <!-- Постоянные издержки на обычных фабриках --->
                            {{ format_number(game.team.factorys * game.game.fixed_costs_ord) }}
                        </div>
                    </div>

                    <div class="block">
                        <div class="name">
                            {{ lang.trans_35 }}
                        </div>
                        <!-- Постоянные издержки на модернизированных фабриках --->
                        <div class="count">
                            {{ format_number(game.team.auto_factorys * game.game.fixed_costs_auto) }}
                        </div>
                    </div>

                    <div class="block">
                        <div class="name">
                            {{ lang.trans_36 }}
                        </div>
                        <!-- Складские издержки --->
                        <div class="count">
                            {{ format_number((game.team.materials * game.game.warehouse_costs_raw) + (game.team.products * game.game.warehouse_costs_prod) + (game.team.premium_products * game.game.warehouse_costs_prod)) }}
                        </div>
                    </div>

                    <div class="block">
                        <div class="name">
                            {{ lang.trans_37 }}
                        </div>
                        <!-- Расходы на кредит --->
                        <div class="count">
                            {{ format_number(Math.floor((game.team.credit * game.game.loan_interest) / 100) + game.team.fast_credit_accrued_interest) }}
                        </div>
                    </div>

                </div>
                
                <my-form v-if="game.step.current_tact != 'Ход завершен' && (game.tact && game.tact.type != 'Издержки')"></my-form>
                
                <results v-if="test()"></results>
                
            </div>


        </div>

        <market></market>

        <history></history>

    </div>

</template>

<script>
import { computed, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { format_number } from "@/api/funcs";
import { get_lang_name } from "@/api/funcs";

import FastCredit from "@/components/game/FastCredit.vue";
import MyForm from "@/components/game/MyForm.vue";
import Results from "@/components/game/Results.vue";
import Market from "@/components/game/Market.vue";
import Chart from "@/components/game/Chart.vue";

import Rules from "@/components/game/Rules.vue";

import History from "@/components/game/History.vue";


export default {

    components: {
        "fast-credit":  FastCredit,
        "my-form":      MyForm,
        "results":      Results,
        "market":       Market,
        "chart":        Chart,
        "rules":        Rules,
        "history":      History,
    },

    setup() {

        const router        = useRouter()
        const route         = useRoute()
        const store         = useStore()

        const is_auth       = computed(() => store.getters.is_auth)
        const template      = computed(() => store.getters.template)
        const lang          = computed(() => store.getters.lang)

        const game          = computed(() => store.getters.game)
        const time          = computed(() => store.getters.time)
        const fast_credit   = computed(() => store.getters.fast_credit)
        const chart         = computed(() => store.getters.chart)
        const is_rules      = computed(() => store.getters.rules)
        const key           = computed(() => store.getters.key)


        async function update() {

            store.dispatch('global_block_window', true) 
                
            await store.dispatch('get_me_game', route.params.id)

            store.dispatch('global_block_window', false) 
            
        }

        onUnmounted(async () => {
            store.dispatch('update_where_is', '')
        })

        onMounted(async () => {

            if(!is_auth.value){
                router.push({ name: `login`, params: { template: template.value.alt_name } }); return
            }

            await store.dispatch('socket_send', {
                command: "new_user",
                gid: parseInt(route.params.id, 10),
                key: key,
            })

            store.dispatch('update_where_is', 'game')
            
            await update()

        })

        async function open_chart() {
            store.dispatch('chart', true)
        }

        async function open_rules() {
            store.dispatch('update_rules', true)
        }

        async function open_fast_credit() {
            store.dispatch('fast_credit', true)
        }

        function test(){
            

            if(game.value.tact == null){
                return false
            }

            if(game.value.step.current_tact == 'Ход завершен'){
                return false
            }

            if(game.value.tact.type == 'Издержки'){
                return false
            }

            if(game.value.game.variant){

                if(game.value.tact.type == 'Кредит'){
                    return true
                }
                return false
            }

            

            return true
        }

        function get_tact_name(tact){
            switch(tact.type){
                case "Снабжение":         return get_lang_name(lang.value, 96)
                case "Производство":    return get_lang_name(lang.value, 97)
                case "Продажи":         return get_lang_name(lang.value, 98)
                case "Издержки":        return get_lang_name(lang.value, 209)
                case "Инвестиции":      return get_lang_name(lang.value, 258)
                case "Кредит":          return get_lang_name(lang.value, 22)
            }
        }

        function game_status_name() {

            if(game.value.tact){
                if (game.value.tact.type == 'Издержки') {
                    return lang.value.trans_42
                }

                if (game.value.tact.status == 'Активен') {
                    return lang.value.trans_43
                }
            }
            
            return lang.value.trans_40
        }


        return {
            test,
            lang,
            template,
            format_number,
            update,

            game,
            get_tact_name,

            game_status_name,
            time,

            fast_credit,
            open_fast_credit,

            chart,
            open_chart,

            is_rules,
            open_rules,

        }

    }
}
</script>
