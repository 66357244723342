

export const teams = state => state.teams
export const team = state => state.team
export const teams_v2 = state => state.teams_v2
export const decisions = state => state.decisions






