
export const steps  = state => state.steps
export const step   = state => state.step





export const active_steps   = state => state.steps.filter((o) => o.status == "Завершен" || o.status == "Активен")
export const next_steps     = state => state.steps.filter((step) => step.status == 'Не начат')


export const active_step    = state => {
    if(state.steps.length > 0){

        const active = state.steps.filter((o) => o.status == "Активен")

        if(active.length > 0){
            return active[0]
        }

        const finished = state.steps.filter((o) => o.status == "Завершен")

        return finished[finished.length - 1]
    }
    
    return {}
}



export const selected_step  = state => state.selected_step