import { createRouter, createWebHistory } from 'vue-router'


import Home from '../views/Home.vue'
import Cabinet from '../views/Cabinet.vue'

//user
import Registration from '../views/users/Registration.vue'
import Login        from '../views/users/Login.vue'
import User         from '../views/users/User.vue'




import Game from '../views/Game.vue'
import History from '../views/History.vue'


//operator
import Operator from '@/views/Operator.vue'
import Games from '@/views/operator/Games.vue'
import Profiles from '@/views/operator/Profiles.vue'
import Templates from '@/views/operator/Templates.vue'



import OperatorGame   from '../views/operator/OperatorGame.vue'
import OperatorSteps  from '../views/operator/OperatorSteps.vue'
import OperatorTacts  from '../views/operator/OperatorTacts.vue'
import OperatorTeams  from '../views/operator/OperatorTeams.vue'
import GamesUpdate    from '../views/operator/GamesUpdate.vue'
import OperatorTeamsV2  from '../views/operator/OperatorTeamsV2.vue'
import Analytics  from '../views/operator/Analytics.vue'

const routes = [

  //новое
  { 

    path: '/operator', 
    name: 'operator', 
    component: Operator,
    children: [

      { 
        path: 'games', 
        name: 'operator.games', 
        component: Games,

      },

      { 
        path: 'profiles', 
        name: 'operator.profiles', 
        component: Profiles,
      },

      { 
        path: 'templates', 
        name: 'operator.templates', 
        component: Templates,
      },


    ],

  },








  { 
    path: '/operator/games/:id', 
    name: 'operator.games.detail', 
    component: OperatorGame,
    children: [

      { 
        path: 'steps', 
        name: 'operator.steps', 
        components: { default: Games, 'operator-game': OperatorSteps, } 
      },

      { 
        path: 'tacts', 
        name: 'operator.tacts', 
        components: { default: Games, 'operator-game': OperatorTacts, } 
      },

      { 
        path: 'detail', 
        name: 'operator.games.update', 
        components: { default: Games, 'operator-game': GamesUpdate, } 
      },

      { 
        path: 'teams', 
        name: 'operator.teams', 
        components: { default: Games, 'operator-game': OperatorTeams, } 
      },

      { 
        path: 'teams', 
        name: 'operator.teams.v2', 
        components: { default: Games, 'operator-game': OperatorTeamsV2, } 
      },

      { 
        path: 'analytics', 
        name: 'operator.analytics', 
        components: { default: Games, 'operator-game': Analytics, } 
      },

    ],

  },

  //user
  { path: '/cabinet', name: 'cabinet', component: Cabinet },
  { path: '/games/:id', name: 'games', component: Game },
  { path: '/games/:id/step/:s_id/history', name: 'history', component: History, },



  { path: '/:template', name: 'login', component: Login },
  { path: '/:template/registration/:id/:t_id', name: 'registration', component: Registration },
  { path: '/users/:id', name: 'user', component: User },



  //редирект всего
  { path: "/:pathMatch(.*)*", component: Home },


]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
