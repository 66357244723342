
<template>
    <div class="input bool">

        <div class="name">{{ name }}</div>

        <div :class="[`toggle`, {end: model}]" @click="test">
            <div class="back"></div>
        </div>

    </div>
</template>

<script>

export default {

    props: {
        name: {
            type: String,
            default: ''
        },
        model: {
            type: Boolean,
            default: false
        },
        event: {
            type: Function,
            default: () => {}
        }
    },

    emits: ["update:model"],

	setup(props, ctx){

        async function test(){

            ctx.emit('update:model', !props.model)

            if(props.event != undefined){

                await props.event()
            }
            
        }

        return { test }
    }
}

</script>

<style scoped>

.input.bool {
    margin-bottom: 1.25em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input.bool > .name {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #0944AA;
}

.input.bool > .toggle {
    width: 35px;
    min-width: 35px;
    height: 17px;
    background: #5A6671;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    transition: all 500ms;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.input.bool > .toggle.end {
    justify-content: flex-end;
}

.input.bool > .toggle > .back {
    height: 15px;
    width: 15px;
    border-radius: 15px;
    transition: all 500ms;
    background: #FFFFFF;
    box-shadow: 4px 2px 10px rgb(0 0 0 / 25%);
    margin-right: 1px;
    margin-bottom: 1px;
    margin-left: 1px;
    margin-top: 1px;
}

.input.bool > .toggle.end {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

</style>
