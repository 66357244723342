
export const clean_game = (state) => {
    state.game = {
        
        teams: [],
        steps: [],
        
        user_id: 0,
        name: "",
        info: "",
  
        default_finance: 25000,
        default_loan: 0,
        default_raw: 7,
        default_prod: 5,
        default_ordinary: 2,
        default_auto: 0,
  
        capacity_ord: 7,
        capacity_auto: 11,
  
        variable_costs_ord: 1150,
        variable_costs_auto: 800,
  
        constr_cost_ord: 14000,
        constr_cost_auto: 11000,
  
        money_liq_ord: 16000,
        money_liq_auto: 30000,
  
        fixed_costs_ord: 8000,
        fixed_costs_auto: 11000,
  
        warehouse_costs_raw: 150,
        warehouse_costs_prod: 230,
  
        loan_interest: 9,
        loan_limit: 30000,
  
        forecast_price: 300,
  
        // второй продукт
        variant: false,
        default_count_premium_products: 0,
        premium_variable_costs_ord: 2500,
        premium_variable_costs_auto: 1300,
  
        // быстрый кредит
        is_fast_credit: false,
        fast_credit: 0,
        fast_credit_interest: 15.00,
        fast_credit_limit: 15000,
        fast_credit_accrued_interest: 0,
  
        //chart
        is_chart: true,
        is_pause: false,
  
        raw_for_standart: 1,
        raw_for_premium: 2,
  
        count_commands: 5,
        
        auto: false,
    }
}


export const get_game = (state, game) => {
    state.game = game
}

export const get_games = (state, games) => {
    state.games = games
}


export const update_history = (state, history) => {
    state.history = history
}
