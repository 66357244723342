
export const count_users = ({ commit }, count) => {
    commit('count_users', count)
}

export const clean_socket = ({ commit }) => {
    commit('clean_socket')
}


export const global_users_block_window = ({ commit, getters }, gid) => {
    
    commit('socket_send', JSON.stringify({
        command: "global_users_block_window", 
        user: {
            uid: getters.user.id, 
            gid: parseInt(gid, 10),
        }
    }))
  
}


export const socket_send = ({ commit, getters }, object) => {
    
    object.key = getters.key
    
    commit('socket_send', JSON.stringify(object))
  
}


export const socket_create = async ({ commit, dispatch, state, getters }) => {

    if(state.socket_open) return


    const host      = window.location.hostname == "localhost" ? `ws://${window.location.hostname}/api/ws` : `wss://${window.location.hostname}/api/ws`
    let socket    = new WebSocket(host + `?auth=${getters.cookie}`)

    socket.onopen = function() {

        console.log("Соединение установлено.")

        commit('socket_create', {socket: socket, socket_open: true})
    }

    socket.onclose = function(event) {

        commit('socket_create', {socket: null, socket_open: false})

        if (event.wasClean) {
            console.log('Соединение закрыто чисто')
        } else {
            console.log('Обрыв соединения')
        }

        console.log(event)

        console.log('Код: ' + event.code + ' причина: ' + event.reason)


        setTimeout(function() {
            dispatch("socket_create")
        }, 1000)

    }

    socket.onerror = function(error) {
        console.log("Ошибка " + error.message);
    }


    socket.onmessage = async function(event){

        const response = JSON.parse(event.data)

        console.log(response)

        switch(response.command){

            case `err`:
                dispatch('update_err_by_code', response.code); break


            case `count_users`:
                
                dispatch('count_users', response.count); break


            case `update_cabinet_games`:

                if(getters.where_is == 'cabinet'){
                    await dispatch('get_games', { w_is: 'cabinet', fields: `id,name,poster` })
                }

                break

            case `update_user_team`:

                if(getters.where_is == 'game'){
                    
                    await dispatch('get_go_data_game', getters.game.id)
                }

                break

            case 'set_time':
                
                dispatch('update_time', response.time)

                break

            case 'set_key':
                
                commit('update_key', response.name)

                break

            case 'global_users_block_window':
                
                if(getters.where_is != 'operator'){
                    dispatch('global_block_window', true)
                }

                break


            case 'update_game':

                if(getters.where_is == 'operator'){
                    await dispatch('get_game', getters.game.id)
                }

                if(getters.where_is == 'game'){
                    await dispatch('get_me_game', getters.game.game.id)
                }

                if(getters.where_is == 'history'){
                    await dispatch('get_history_game', {id: getters.history.game.id, sid: getters.history.step.id})
                }

                break

            
            case 'update_operator_games':

                if(getters.is_operator){
                    await dispatch('get_games', { w_is: 'management', fields: `id,name,status,info,teams` })
                }
                
                break

        }
        

    }

}