<template>
    <div class="pop_up">

        <div class="back"></div>

        <div class="wrap">

            <div class="name">Изменить пароль</div>

            <div class="form">
                <input-text v-model:model="password" name="Пароль" placeholder="Пароль" :max="40" :event="update" :is-call="false"></input-text>
            </div>

            <div class="wrap">
                <div class="btn ok" @click="update" @keyup.enter="update">Сохранить</div>
                <div class="btn close" @click="close">{{ lang.trans_368 }}</div>
            </div>
        </div>

    </div>
</template>

<script>

import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import InputText  from '@/components/form/InputText.vue'

export default {

    components: {
        "input-text": InputText,
    },

    props: {
        currPass: {
            type: String,
            default: () => ''
        },
        event: {
            type: Function,
            default: () => { /* Действие по умолчанию, если другое не указано */ }
        },
        close: {
            type: Function,
            default: () => { /* Действие по умолчанию, если другое не указано */ }
        },
    },
    
    setup(props){


        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const password  = ref("")

        onMounted(()=>{
            password.value = props.currPass
        })

        
        async function update(){

            store.dispatch('global_block_window', true)

            await props.event(password.value)

            await props.close()

            store.dispatch('global_block_window', false)

        }

        return { lang, update, password, }

    }
}

</script>

<style scoped>
.pop_up {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.pop_up > .back {
    background: black;
    opacity: .5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.pop_up > .wrap {
    background: white;
    z-index: 1;
    padding: 1.5em;
    border-radius: 15px;
    max-width: 650px;
    width: 100%;
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
}

@media (min-width: 500px){
    .pop_up > .wrap {
        font-size: 18px;
    }
}

.pop_up > .wrap > .name {
    font-weight: 500;
    font-size: 1.925em;
    letter-spacing: 0.02em;
    color: #0944AA;
    margin-bottom: 0.5em;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        margin-top: 1em;
    }
}

.pop_up > .wrap > .wrap > .btn {
    border-radius: 4px;
    color: white;
    padding: .9em;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: .9em;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap > .btn {
        width: 100%;
        margin: 0 auto;
    }
}

.pop_up > .wrap > .wrap > .btn.ok {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

.pop_up > .wrap > .wrap > .btn.close {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
}

</style>