<template>
    <div class="button delete" @click="open">
        <img src="@/assets/images/operator/icon_close.svg">
    </div>

    <div v-if="active" class="pop_up">

        <div class="back"></div>

        <div class="wrap">

            <div class="name">{{ name }}</div>

            <div class="desc">{{ desc }}</div>

            <div class="wrap">
                <div class="btn ok" @click="event" @keyup.enter="event">{{ lang.trans_416 }}</div>
                <div class="btn close" @click="close">{{ lang.trans_368 }}</div>
            </div>
            
        </div>
    </div>


</template>

<script setup>

    import { ref, toRefs, computed } from 'vue'
    import { useStore } from 'vuex'


    const props = defineProps({
        name: {
            type: String,
            default: ''
        },
        desc: {
            type: String,
            default: ''
        },
        event: {
            type: Function,
            default: () => {}
        },
    })

    const { name, desc, event } = toRefs(props);

    const store = useStore()
    const lang = computed(() => store.getters.lang)
    const active = ref(false);

    function open(){
        active.value = true
    }

    function close(){
        active.value = false
    }

</script>


<style scoped>
.pop_up {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.pop_up > .back {
    background: black;
    opacity: .5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.pop_up > .wrap {
    background: white;
    z-index: 1;
    padding: 1.5em;
    border-radius: 15px;
    max-width: 350px;
    width: 100%;
    font-size: calc(14px + 6 * ((100vw - 320px) / 680));
    z-index: 999;
}

@media (min-width: 500px){
    .pop_up > .wrap {
        font-size: 16px;
    }
}

.pop_up > .wrap > .name {
    font-weight: 500;
    font-size: 1.925em;
    letter-spacing: 0.02em;
    color: #0944AA;
    margin-bottom: 1em;
}

.pop_up > .wrap > .desc {
    letter-spacing: 0.02em;
    color: #0944AA;
}
.pop_up > .wrap > .wrap {
    margin-top: 2em;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }
}

.pop_up > .wrap > .wrap > .btn {
    border-radius: 4px;
    color: white;
    padding: .6em;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: .9em;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap > .btn {
        width: 100%;
        margin: 0 auto;
    }
}

.pop_up > .wrap > .wrap > .btn.ok {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

.pop_up > .wrap > .wrap > .btn.close {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
}

</style>