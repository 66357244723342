
import { requests } from "@/api/funcs";


//user
export const update_tact = async ({ commit }, tact) => {
    commit('update_tact', tact)
}



export const calculate_tact = async ({ getters, dispatch }, tact) => {

    const response = await fetch(`${getters.domain}/api/admin/tacts/${tact.id}/calculate`, {
        method: 'GET',
        headers: {
            "Authorization": getters.cookie,
        },
    })

    const body = await response.json()

    if(body.err.is){
        dispatch('update_err', {is: true, name: body.err.name,}); return
    }

}


export const tact_init = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/tacts/${id}/init`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const update_tacts = async ({ getters, dispatch}, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/tacts/${object.id}`, 'PUT', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const get_tacts = async ({ commit, getters, dispatch }, object) => {

    //  /api/games/(?P<gid>.*)/steps/(?P<sid>.*)/tacts/
        

    const [ err, code, body ] =  await requests(`${getters.domain}/api/games/${object.gid}/steps/${object.sid}/tacts/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_tacts', body)
}

export const clean_tacts = ({ commit }) => {
    commit('clean_tacts')
}



export const start_tact = async ({ getters, dispatch}, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/tacts/${id}/start`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}


export const stop_tact = async ({ getters, dispatch}, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/tacts/${id}/stop`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}








