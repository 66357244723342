<template>
    <div class="operator games">

        <div class="top">
            <div class="wrap">
                <img src="@/assets/images/operator/users_v2.svg">
                <div class="name games">Список пользователей</div>
            </div>
        </div>

        <div v-if="users && users.length" class="center">

            <div class="wrap center top">

                <div class="wrap start">
                    <div class="name bold">Логин</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">Игра</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">Язык</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">Шаблон</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">Роль</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">Кем создан</div>
                </div>

                <div class="wrap start">
                    <div class="name bold">Дата</div>
                </div>

                <div class="wrap start">
                    <div class="name bold"></div>
                </div>

            </div>


            <div v-for="user in users" :key="user.id" class="wrap center">

                <div class="wrap start">
                    <router-link :to="{ name: `user`, params: { id: user.id } }" class="name">
                        {{ user.name }}
                    </router-link>
                </div>

                <div class="wrap start">
                    <div class="name">{{ user.game.name }}</div>
                </div>

                <div class="wrap start">
                    <div class="name">{{ user.lang.name }}</div>
                </div>

                <div class="wrap start">
                    <div class="name">{{ user.template.name }}</div>
                </div>

                <div class="wrap start">
                    <div class="name">{{ user.role.name }}</div>
                </div>

                <div class="wrap start">
                    <div class="name">{{ user.creator.name }}</div>
                </div>

                <div class="wrap start">
                    <div class="name">{{ user.created_at }}</div>
                </div>

                <div class="wrap end">
                    <Delete name="Удалить пользователя?" :desc="`Пользователь ${user.name} будет удален навсегда.`" :event="async () => await remove(user.id)" />
                </div>

            </div>

        </div>

        <div class="btm">

            <img src="@/assets/images/operator/icon_add.svg">

            <div class="name" @click="is_open = true">
                Создать пользователя
            </div>

        </div>

        <UsersAddV2 v-if="is_open" :close="close" />

    </div>
</template>

<script setup>

import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Delete from '@/components/button/Delete.vue'
import UsersAddV2 from '@/components/operator/teams/UsersAddV2.vue'

const store = useStore()
const domain = computed(() => store.getters.domain)
const cookie = computed(() => store.getters.cookie)
const users = ref([])

const is_open = ref(false)
async function close() {
    is_open.value = false
    users.value = await get_users()
}



const get_users = async () => {

    let response = await fetch(`${domain.value}/api/users`, {
        method: 'GET',
        headers: {
            "Authorization": cookie.value,
        },
    })

    let body = await response.json()

    return body
}

onMounted(async () => {
    users.value = await get_users()
})

async function remove(id) {

    await store.dispatch('delete_users', id)

    users.value = await get_users()
}


</script>