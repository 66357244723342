
export const clean_teams = (state) => {
    state.teams = []
}

export const clean_team = (state) => {
    state.team = {
        name: '',
    }
}


export const get_team = (state, team) => {
    state.team = team
}

export const get_teams = (state, teams) => {
    state.teams = teams
}

export const get_teams_v2 = (state, teams_v2) => {
    state.teams_v2 = teams_v2
}
