<template>
    <div class="input select">

        <label>{{ name }}</label>

        <div class="wrap" @click="is_open = !is_open">

            <div v-if="current" class="name">{{ current.name }}</div>

            <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.8955 18.6277L20.9489 13.16C21.0325 13.0431 21.0767 12.9105 21.0771 12.7755C21.0776 12.6404 21.0343 12.5077 20.9516 12.3903C20.8688 12.273 20.7495 12.1753 20.6054 12.1068C20.4614 12.0383 20.2977 12.0015 20.1305 12L12.0237 12C11.8566 12.0015 11.6929 12.0383 11.5489 12.1068C11.4048 12.1753 11.2855 12.273 11.2027 12.3903C11.12 12.5077 11.0767 12.6404 11.0772 12.7755C11.0776 12.9105 11.1218 13.0431 11.2054 13.16L15.2588 18.6277C15.3441 18.7414 15.4642 18.8353 15.6076 18.9005C15.7509 18.9657 15.9127 19 16.0771 19C16.2416 19 16.4034 18.9657 16.5467 18.9005C16.69 18.8353 16.8102 18.7414 16.8955 18.6277Z"
                    fill="#0944AA" />
            </svg>

        </div>

        <div v-if="is_open" class="list" @click="is_open = !is_open">

            <div v-for="object in list" :key="object.id" class="name" @click="select(object.id)">
                {{ object.name }}
            </div>

        </div>

    </div>
</template>

<script>

import { computed, ref } from 'vue'

export default {

    props: {
        name: {
            type: String,
            default: ''
        },
        model: {
            type: Number,
            default: 0
        },
        event: {
            type: Function,
            default: () => () => { }
        },
        list: {
            type: Array,
            default: () => []
        }
    },


    emits: ["update:model"],

    setup(props, ctx) {

        const is_open = ref(false)

        const current = computed(() => {
            return props.list.find((o) => o.id === props.model) || { name: 'Не выбранно' }
        })

        async function select(id) {

            ctx.emit('update:model', id)

            //is_open.value = false

            //await props.event()

        }

        return {
            current,
            is_open,
            select,
        }
    }
}

</script>

<style scoped>
.input.select {
    position: relative;
    margin-bottom: 1.25em;
    border-radius: 5px;
    position: relative;
}

.input.select>label {
    left: 0.8em;
    right: auto;
    position: absolute;
    background: white;
    color: #0944AA;
    font-size: .85em;
    top: -0.6em;
    padding: 0 0.35em;
}

.input.select>.wrap {
    border: 1px solid #0944AA;
    border-radius: 8px;
    padding: .5em .95em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input.select:last-of-type {
    margin-bottom: 0;
}

.input.select>.list {
    position: absolute;
    background: #fafafa;
    width: 100%;
    box-shadow: 0 0 5px #cacaca;
    border-radius: 5px;
    z-index: 999;
}

.input.select>.list>.name {
    margin: 0.5em 0.95em;
    color: #373737;
    cursor: pointer;
}
</style>
