

export const update_url = async ({ commit }, url) => {
    commit('update_url', url)
}

export const delete_image_server = async ({ getters }, name) => {

    await fetch(`${getters.domain}/api/v1/images/delete`, {
        method: 'POST',
        headers: { 
            'Content-Type':'application/x-www-form-urlencoded',
        },
        body: `name=${name}`
    })

}

export const upload_image_server = async ({ getters, dispatch }) => {

    //смотрим если есть что-то уже загруженное, то чистим
    if(getters.url != ''){
        await dispatch('delete_image_server', getters.url)
    }

    //а теперь загружаем новое
    const formData  = new FormData()

    formData.append('image', getters.upload_image.image)

    const response = await fetch(`${getters.domain}/api/v1/images/upload`, {
        method: 'POST',
        body: formData
    });

    const body = await response.json()

    await dispatch('update_url', body.url)

}

export const upload_image = async ({ commit }, object) => {
    commit('upload_image', object)
}