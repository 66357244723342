<template>

    <div class="wrap">

        <div class="back"></div>

        <div class="chart">

            <h2>
                {{ lang.trans_451 }}
            </h2>

            <div class="wrap">
                <div v-for="tab in filter_tabs" :key="tab.id" :class="['name', {active: tab.active}]" @click="select_tab(tab)">{{ get_lang_name(lang, tab.alt_name) }}</div>
            </div>

            <apexchart v-if="historys" width="100%" height="450" type="line" :options="options" :series="historys"></apexchart>

        </div>

    </div>

</template>

<script>

import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import { format_number, get_lang_name, requests } from "@/api/funcs"

export default {

    setup() {

        const store         = useStore()
        const route         = useRoute()
        const domain        = computed(() => store.getters.domain)
        const cookie        = computed(() => store.getters.cookie)
        const game          = computed(() => store.getters.history)
        const lang          = computed(() => store.getters.lang)
        const historys      = ref([])

        watch(game, async () => {

            for(let tab of tabs.value){
                if(tab.active){
                    if(tab.id < 6){
                        await SeriesGetByStep(tab.id)
                    }else{
                        await SeriesGetByGame(tab.id)
                    }
                }
            }

        })


        const options = ref({

            stroke: {
                curve: 'smooth',
            },

            chart: {

                id: 'chart',

                toolbar: {
                    show: true,
                },

                animations: {
                    enabled: false,
                },

            },

            dataLabels: {
                enabled: false
            },

            yaxis: {
                labels: {
                    formatter: function(val) {
                        return format_number(val);
                    },
                },
            },

        })

        const tabs = ref([

            // { id: 1, name: 'Cчет',              alt_name: 254,  active: true,   variant: false, hidden_reiting: false},
            // { id: 2, name: 'Кредит',            alt_name: 22,   active: false,  variant: false, hidden_reiting: false},
            // { id: 3, name: 'Сырье',             alt_name: 255,  active: false,  variant: false, hidden_reiting: false},
            // { id: 4, name: 'Продукция',         alt_name: 25,   active: false,  variant: false, hidden_reiting: false},
            // { id: 5, name: 'Премиум продукция', alt_name: 256,  active: false,  variant: true, hidden_reiting: false},
            // { id: 6, name: 'Фабрики',           alt_name: 76,   active: false,  variant: false, hidden_reiting: false},
            // { id: 7, name: 'Авто фабрики',      alt_name: 257,  active: false,  variant: false, hidden_reiting: false},
            { id: 8, name: 'Рейтинг',           alt_name: 78,   active: true,  variant: false, hidden_reiting: true},

        ])

        async function select_tab(c_tab) {

            for(let tab of tabs.value){
                tab.active = false
            }

            c_tab.active = true

            if(c_tab.id < 6){
                await SeriesGetByStep(c_tab.id)
            }else{
                await SeriesGetByGame(c_tab.id)
            }
            
        }

        const filter_tabs = computed(() => {
            return tabs.value
        })

        async function SeriesGetByGame(id){
            const [ err, code, body ] = await requests(`${domain.value}/api/chart/games/${route.params.id}/chart/${id}`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            historys.value = body
        }

        async function SeriesGetByStep(id){
            const [ err, code, body ] = await requests(`${domain.value}/api/chart/steps/${route.params.id}/chart/${id}`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            historys.value = body
        }

        onMounted(async () => {
            await select_tab(tabs.value[0])
        })


        return {
            lang,
            options,
            filter_tabs,
            select_tab,
            get_lang_name,
            historys,
        }

    }
}
</script>
