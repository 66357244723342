<template>
    <div class="block">

        <div class="top">

            <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.2448 6.56215C13.104 5.87895 10.6654 6.93119 10.6258 6.94829L9.81736 5.28245C9.96386 5.21304 11.6576 4.44412 14.5681 4.44412C16.627 4.44412 19.3018 4.84467 22.4616 6.14141C23.8858 6.72578 25.6396 6.25734 26.3395 4.90338C26.3442 4.89427 26.7729 4.05015 26.7729 4.05015L22.5808 1.98584C19.5696 0.503881 16.9722 0.000922793 14.7182 0C11.9332 0 9.67093 0.768001 7.7923 1.40535C6.12692 1.97144 5.06931 2.41587 4.19312 1.9859L3.3545 1.57304L0 8.17789C0.523999 8.40676 2.45718 9.68479 5.86693 10.4829C10.1029 11.4799 14.5022 9.93839 17.2448 6.56215Z" fill="currentColor"/>
                <path d="M28.0236 5.71686C26.8595 7.96639 24.07 8.80337 21.7404 7.84517C20.9136 7.50621 20.0988 7.22227 19.2959 6.99328C18.8612 7.4947 18.4964 8.10282 17.2411 9.23063H32V5.53833H28.1142L28.0236 5.71686Z" fill="currentColor"/>
                <path d="M8.43956 12.6281C8.14694 12.6281 7.85488 12.5967 7.5625 12.577V22.1536H32V11.0767H14.6038C12.7252 12.0744 10.6236 12.6281 8.43956 12.6281ZM22.5625 17.5382H28.1874V19.3844H22.5625V17.5382Z" fill="currentColor"/>
            </svg>

            <div class="name">
                {{ lang.trans_22 }}
            </div>

        </div>

        <div class="center">

            <img :src="require(`@/assets/images/rules/${template.alt_name}/loan.svg`)">

            <div class="wrap">

                <div class="name">
                    {{ lang.trans_490 }}
                </div>

                <div class="wrap_v2">

                    <div class="name">
                        {{ lang.trans_491 }}
                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_492 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.loan_interest) }}%
                        </div>

                        <div class="name">
                            {{ lang.trans_493 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.loan_limit) }}₽
                        </div>

                    </div>

                </div>

                <div v-if="game.game.is_fast_credit" class="wrap_v2">

                    <div class="name">
                        {{ lang.trans_150 }}
                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_492 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.fast_credit_interest) }}%
                        </div>

                        <div class="name">
                            {{ lang.trans_493 }}
                        </div>

                        <div class="name left">
                            {{ format_number(game.game.fast_credit_limit) }} ₽
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div class="bottom">
            {{ lang.trans_494 }}
        </div>

    </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'
import { format_number } from "@/api/funcs";

export default {

    setup(){

        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)
        const template  = computed(() => store.getters.template)

        return {
            close,
            lang,
            template,
            game,
            format_number,
        }
    }
}
</script>