
import { requests } from "@/api/funcs";





//новое api
export const clean_teams = async ({ commit }) => {
    commit('clean_teams')
}


export const add_me_team = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/teams/${id}/add/me`, 'PUT', getters.cookie)

    if(err){
        await dispatch('update_err_by_code', code); return
    }
}



//используется для обновления результатов команд
export const update_team_decisions = async ({ getters, dispatch}, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/teams/games/${object.gid}/decision`, 'PUT', getters.cookie, object.team)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

}

//используется для создания имени команд
export const create_team = async ({ getters, dispatch}, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/teams/games/${object.id}`, 'POST', getters.cookie, object.team)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

}

//используется для обновления имени команд
export const operator_update_team = async ({ getters, dispatch}, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/teams/${object.id}`, 'PUT', getters.cookie, object)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

}

//используется у оператора, для отображения списка команд
export const get_operator_teams = async ({ commit, getters, dispatch }, id) => {

    const [ err, code, body ] = await requests(`${getters.domain}/api/teams/games/${id}`, 'GET', getters.cookie)

    if(err){
        await dispatch('update_err_by_code', code); return
    }


    commit('get_teams', body)
}

//используется у оператора, для отображения списка команд
export const get_operator_team = async ({ commit, getters, dispatch }, id) => {

    const [ err, code, body ] = await requests(`${getters.domain}/api/teams/${id}`, 'GET', getters.cookie)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

    commit('get_team', body)
}

export const delete_teams = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/teams/${id}`, 'DELETE', getters.cookie)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

}










export const clean_team = async ({ commit }) => {
    commit('clean_team')
}



export const update_game_teams = async ({ getters, dispatch}, object) => {

    // params object{ game_id: game.id, step_id: step.id, tact_id: tact.id, team: object }

    const [ err, code ] =  await requests(`${getters.domain}/api/games/play/${object.game_id}/steps/${object.step_id}/tacts/${object.tact_id}/teams/${object.team.id}/`, 'PUT', getters.cookie, object.team)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

}


export const update_teams = async ({ getters, dispatch}, object) => {

    // params object{ game_id: game.id, step_id: step.id, tact_id: tact.id, team: object }

    const [ err, code ] =  await requests(`${getters.domain}/api/games/${object.game_id}/steps/${object.step_id}/tacts/${object.tact_id}/teams/${object.team.id}/`, 'PUT', getters.cookie, object.team)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

}

export const add_teams = async ({ getters, dispatch}, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/games/${object.id}/steps/0/tacts/0/teams/`, 'POST', getters.cookie, object.team)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

}

export const get_team = async ({ commit, getters, dispatch }, id) => {

    const [ err, code, body ] = await requests(`${getters.domain}/api/teams/${id}`, 'GET', getters.cookie)

    if(err){
        await dispatch('update_err_by_code', code); return
    }

    commit('get_team', body)
}