
import { requests } from "@/api/funcs";



export const set_lang = async ({ commit }, id) => {
    commit('set_lang', id)
}

export const update_langs = async ({ getters, dispatch}, object) => {


    const [ err, code ] =  await requests(`${getters.domain}/api/langs/${object.id}/`, 'PUT', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }


}

export const add_langs = async ({ getters, dispatch}, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/langs/`, 'POST', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const delete_langs = async ({ getters, dispatch }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/langs/${id}/`, 'DELETE', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const get_lang = async ({ commit, dispatch, getters }, id) => {


    const [ err, code, body ] =  await requests(`${getters.domain}/api/langs/${id}/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_lang', body)
}

//не важно авторизован ли юзер, делаем запрос на языки, если не авторизован то выдаем всю пачку
export const get_langs = async ({ getters, commit, dispatch }) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/langs`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_langs', body)

}
