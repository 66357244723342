
import { requests } from "@/api/funcs";



export const get_current_step_historys = async ({ commit, getters, dispatch }, id) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/steps/${id}/historys/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_current_step_historys', body)

}

export const get_game_historys = async ({ commit, getters, dispatch }, id) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/games/${id}/historys/`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_game_historys', body)

}